<template>
  <div>
    <div class="col-md-12">
            &nbsp;
    </div>
    <div class="container register">
                <div class="row">
                    <div class="col-md-3 register-left">
                        <img src="/img/trikaya-main-logo.png" alt="" />
                        <br/>
                        <h3>Selamat Datang</h3>
                        <p>Pada Website <br/> <small>Manajemen Data</small></p>
                        <br/>
                        <a href="/" target="_blank">Website</a><br/>
                        <div class="form-group">
                            <div class="col-md-12">
                            &nbsp;
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 register-right">
                        <div class="form-group">
                            <div class="col-md-6 offset-md-4">
                            &nbsp;
                            </div>
                        </div>
                      
                        <!-- <div v-if="error" class="alert bg-danger text-white ">
                          {{ error }}
                        </div> -->
                        <form @submit.prevent="loginForm" class="form-horizontal">
                            <h3 class="register-heading">Login {{appname}}</h3>
                            
                            <div class="form-group">
                                <div class="col-md-8 offset-md-2">
                                    <div v-if="error" class="alert bg-danger text-white" >
                                        {{error}}
                                    </div>
                                </div>  
                            </div>
                            
                            <div class="form-group">
                                <div class="col-md-8 offset-md-2">
                                <p align="justify">Masukkan kombinasi username dan password yang telah terdaftar untuk masuk ke sistem. Jika belum memiliki akun, silahkan melakukan pendaftaran akun terlebih dahulu. </p>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="username" class="col-md-4 col-form-label text-md-right">Username</label>

                                <div class="col-md-6">
                                    <input id="username" type="text" class="form-control " name="username" v-model="username" required autocomplete="username" autofocus>
                                    
                                    <span class="invalid-feedback" role="alert">
                                        <strong v-if="message">{{ message }}</strong>
                                    </span>
                                    
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-md-4 col-form-label text-md-right">Password </label>

                                <div class="col-md-6">
                                    <input id="password" type="password" class="form-control" v-model="password" name="password" required autocomplete="current-password">

                                    <span class="invalid-feedback" role="alert">
                                        <strong v-if="message">{{ message }}</strong>
                                    </span>
                                </div>
                            </div>

                            <!-- <div class="form-group">
                                <div class="col-md-6 col-md-offset-4">
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" name="remember" {{ old('remember') ? 'checked' : '' }}> Remember Me
                                        </label>
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-group">
                                <div class="col-md-6 offset-md-4">
                                &nbsp;
                                </div>
                            </div>
                            <br/>
                            <div class="form-group">
                                <div class="col-md-6 offset-md-4">

                                    <button type="submit" class="btnRegister">
                                        Login
                                    </button>

                                    <!-- <a class="btn btn-link" href="{{ route('password.request') }}">
                                        Lupa Password?
                                    </a> -->
                                </div>
                            </div>
                            <br/>
                            <div class="form-group">
                                <div class="col-md-6 offset-md-4">
                                &nbsp;
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-6 offset-md-4">
                                &nbsp;
                                </div>
                            </div>
                        </form>
                    </div> <!-- register right -->
                </div>

    </div>
  </div>

</template>
 
<script>
export default {
  data() {
      return {
        appname: '',
        username: '',
        password: '',
        error: null,
        message: '',
      }
    },
    beforeMount(){
      if(localStorage.getItem('token') != null){
          this.$router.push('home')
      }
    },
    created() {
      if (this.$route.params.message !== undefined) {
        this.message = this.$route.params.message + ' Please login!'
      }
      this.setAppname()
    },
    methods: {
      loginForm() {
        axios.post('api/login', {
            username: this.username,
            password: this.password
          })
          .then(response => {
            localStorage.setItem('user', JSON.stringify(response.data.user))
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('navigation-menu', JSON.stringify(response.data.user.navigation))

            let loginType = response.data.user.roles[0].name
            this.$router.push('home')

            this.$emit('loggedIn')

          })
          .catch(error => {
            this.error = error.response.data.error;
          });
      },
      setAppname(){
        this.appname = localStorage.getItem('appname')
      }
    }
}
</script>
<style type="text/css">
        .register{
            background: -webkit-linear-gradient(left, #05611b, #6afc8e);
            /*margin-top: 1%;*/
            /*padding: 3%;*/
        }
        .register-left{
            text-align: center;
            color: #fff;
            margin-top: 4%;
        }
        .register-left a{
            border: none;
            border-radius: 1.5rem;
            padding: 2%;
            width: 60%;
            background: #f8f9fa;
            font-weight: bold;
            color: #383d41;
            margin-top: 5%;
            margin-bottom: 3%;
            cursor: pointer;
            text-decoration: none;
            padding-top: 3%;
            padding-bottom: 3%;
            padding-left: 25%;
            padding-right: 25%;
        }
        .register-right{
            background: #f8f9fa;
            /*padding-top: 2%;*/
            /*border-top-left-radius: 10% 50%;*/
            /*border-bottom-left-radius: 10% 50%;*/
            /*border-radius: 1%;*/
        }
        .register-left img{
            margin-top: 15%;
            margin-bottom: 5%;
            width: 50%;
            /*-webkit-animation: mover 2s infinite  alternate;*/
            /*animation: mover 1s infinite  alternate;*/
        }
        @-webkit-keyframes mover {
            0% { transform: translateY(0); }
            100% { transform: translateY(-20px); }
        }
        @keyframes mover {
            0% { transform: translateY(0); }
            100% { transform: translateY(-20px); }
        }
        .register-left p{
            font-weight: lighter;
            padding: 12%;
            margin-top: -9%;
        }
        .register .register-form{
            padding: 10%;
            margin-top: 10%;
        }
        .btnRegister{
            float: right;
            border: none;
            border-radius: 1.5rem;
            padding: 2%;
            background: #038722;
            color: #fff;
            font-weight: 600;
            width: 50%;
            cursor: pointer;
            margin-bottom: 2%;
        }
        .register-heading{
            text-align: center;
            /*margin-top: 8%;*/
            /*margin-bottom: -15%;*/
            /*padding-bottom: 1%;*/
            margin-top: 0 !important;
            color: #495057;
        }
    </style>
