<template>
    <button class="btn btn-success" @click="editData">
        <slot><i class="fas fa-pen"></i></slot>
    </button>
</template>

<script>
    import { EventBus } from '../eventBus.js';

    export default{
        //extends: ExampleComponent,
        props: ['id', 'name'],
        methods: {
            editData() {
                // alert(`I am ${this.name}!`)
                EventBus.$emit('editValue', this.id,this.name) 
            }
        },
    }
</script>