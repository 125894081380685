<template>
<div class="col-md-12">
    <!-- <span v-for="data in formdata" :key="data.id_siswa">
        {{data.id_siswa}} <br/>
    </span> -->
    <div class="col-md-12">
        <!-- <p>Breakfast</p> -->
        <div class="row">
            <div class="form-group col-md-2">
                <label>Breakfast Jam </label>
                  <date-picker v-model="form.breakfast_jam" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="breakfast_jam"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Breakfast Menu </label>
                  <input v-model="form.breakfast_menu" type="text" name="breakfast_menu"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('breakfast_menu') }" placeholder="Contoh : Roti dan Susu" >
                  <has-error :form="form" field="breakfast_menu"></has-error>
            </div>
            <div class="form-group col-md-6">
                <label>Breakfast Keterangan </label>
                    <div class="row">
                      <div class="form-check radio-inline" v-for="listoutput in listoutputkegiatan" >
                        <fieldset :id="'breakfast_ket'" class="col-md-2">
                          <label class="form-check-label badge badge-default" style="font-size:14px;">
                          <input class="form-check-input" type="radio" :name="'breakfast_ket'" :id="listoutput.id" :class="[{ 'is-invalid': form.errors.has('breakfast_ket')},listoutput.classname]" v-model="form.breakfast_ket" v-bind:value="listoutput.id" :checked="listoutput.id == form.breakfast_ket">
                          {{listoutput.text}}</label>
                        </fieldset>
                      </div>
                    </div>
                  <has-error :form="form" field="breakfast_ket"></has-error>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <p>Lunch</p>
        <div class="row">
            <div class="form-group col-md-2">
                <label>Lunch Jam </label>
                  <date-picker v-model="form.lunch_jam" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="lunch_jam"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Lunch Menu </label>
                  <input v-model="form.lunch_menu" type="text" name="lunch_menu"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('lunch_menu') }" placeholder="Contoh : Nasi Goreng" >
                  <has-error :form="form" field="lunch_menu"></has-error>
            </div>
            <div class="form-group col-md-6">
                <label>Lunch Keterangan </label>
                    <div class="row">
                      <div class="form-check radio-inline" v-for="listoutput in listoutputkegiatan" >
                        <fieldset :id="'lunch_ket'" class="col-md-2">
                          <label class="form-check-label badge badge-default" style="font-size:14px;">
                          <input class="form-check-input" type="radio" :name="'lunch_ket'" :id="listoutput.id" :class="[{ 'is-invalid': form.errors.has('lunch_ket')},listoutput.classname]" v-model="form.lunch_ket" v-bind:value="listoutput.id" :checked="listoutput.id == form.lunch_ket">
                          {{listoutput.text}}</label>
                        </fieldset>
                      </div>
                    </div>
                  <has-error :form="form" field="lunch_ket"></has-error>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <p>Snack</p>
        <div class="row">
            <div class="form-group col-md-2">
                <label>Snack Jam </label>
                  <date-picker v-model="form.snack_jam" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="snack_jam"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Snack Menu </label>
                  <input v-model="form.snack_menu" type="text" name="snack_menu"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('snack_menu') }" placeholder="Contoh : Donat dan Susu" >
                  <has-error :form="form" field="snack_menu"></has-error>
            </div>
            <div class="form-group col-md-6">
                <label>Snack Keterangan </label>
                    <div class="row">
                      <div class="form-check radio-inline" v-for="listoutput in listoutputkegiatan" >
                        <fieldset :id="'snack_ket'" class="col-md-2">
                          <label class="form-check-label badge badge-default" style="font-size:14px;">
                          <input class="form-check-input" type="radio" :name="'snack_ket'" :id="listoutput.id" :class="[{ 'is-invalid': form.errors.has('snack_ket')},listoutput.classname]" v-model="form.snack_ket" v-bind:value="listoutput.id" :checked="listoutput.id == form.snack_ket">
                          {{listoutput.text}}</label>
                        </fieldset>
                      </div>
                    </div>
                  <has-error :form="form" field="snack_ket"></has-error>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <p>Dinner</p>
        <div class="row">
            <div class="form-group col-md-2">
                <label>Dinner Jam </label>
                  <date-picker v-model="form.dinner_jam" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="dinner_jam"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Dinner Menu </label>
                  <input v-model="form.dinner_menu" type="text" name="dinner_menu"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('dinner_menu') }" placeholder="Contoh : Ayam Goreng" >
                  <has-error :form="form" field="dinner_menu"></has-error>
            </div>
            <div class="form-group col-md-6">
                <label>Dinner Keterangan </label>
                    <div class="row">
                      <div class="form-check radio-inline" v-for="listoutput in listoutputkegiatan" >
                        <fieldset :id="'dinner_ket'" class="col-md-2">
                          <label class="form-check-label badge badge-default" style="font-size:14px;">
                          <input class="form-check-input" type="radio" :name="'dinner_ket'" :id="listoutput.id" :class="[{ 'is-invalid': form.errors.has('dinner_ket')},listoutput.classname]" v-model="form.dinner_ket" v-bind:value="listoutput.id" :checked="listoutput.id == form.dinner_ket">
                          {{listoutput.text}}</label>
                        </fieldset>
                      </div>
                    </div>
                  <has-error :form="form" field="dinner_ket"></has-error>
            </div>
        </div>
    </div>
    <div class="col-md-12" align="right">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-success" @click.prevent="Save()">Simpan</button>
    </div>
</div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    export default {
        components: {
          DatePicker,
        },
        name:'kegiatan',
        props: ['SiswaId','DateKegiatan'],
        async mounted() {
            // console.log('Component mounted.'+this.SiswaId)
            this.$Progress.start();
            await this.loadData()
            await this.loadNilai()
            this.user = JSON.parse(localStorage.getItem('user'))
            this.$Progress.finish();
        },
        data() {
            return {
              user:'',
              formdata : {},
              expression1:'',
              expression2:'',
              expression3:'',
              expression4:'',
              listoutputkegiatan: [],
              form: new Form({
                    id : '',
                    id_siswa : '',
                    tgl_daily_makmin : '',
                    breakfast_jam: '',
                    breakfast_menu: '',
                    breakfast_ket: '',
                    lunch_jam: '',
                    lunch_menu: '',
                    lunch_ket: '',
                    dinner_jam: '',
                    dinner_menu: '',
                    dinner_ket: '',
                    snack_jam: '',
                    snack_menu: '',
                    snack_ket: '',
                    type:'makmin',
              }),
            };
        },
        methods: {
          loadData(){
            axios.get("api/kegiatan-siswa/"+this.SiswaId,{params:{type:'makmin',date:this.DateKegiatan}}).then(( data ) => {
              this.formdata = data.data.data[0]
              // console.log(this.formdata);
              if(data.data.data.length > 0){
                  this.form.id = this.formdata.id;
                  this.form.breakfast_jam = this.formdata.breakfast_jam;
                  this.form.breakfast_menu = this.formdata.breakfast_menu;
                  this.form.breakfast_ket = this.formdata.breakfast_ket;
                  this.form.lunch_jam = this.formdata.lunch_jam;
                  this.form.lunch_menu = this.formdata.lunch_menu;
                  this.form.lunch_ket = this.formdata.lunch_ket;
                  this.form.dinner_jam = this.formdata.dinner_jam;
                  this.form.dinner_menu = this.formdata.dinner_menu;
                  this.form.dinner_ket = this.formdata.dinner_ket;
                  this.form.snack_jam = this.formdata.snack_jam;
                  this.form.snack_menu = this.formdata.snack_menu;
                  this.form.snack_ket = this.formdata.snack_ket;
              }
              this.form.id_siswa = this.SiswaId
              this.form.tgl_daily_makmin = this.DateKegiatan
            })
            .catch((data)=>{
                  setTimeout(() => {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                  }, 300);

                  this.form.id_siswa = this.SiswaId
              });
          },
          loadNilai(){
            axios.get("api/list/nilai-output-makan").then(({ data }) => (this.listoutputkegiatan = data.data));
          },
          Save(){
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            let id = this.form.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.form.put('api/kegiatan-siswa/'+id)
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        $('#pageloader').css('display','none');
                        console.log(data.data);
                        this.form.id = data.data.data.id;
                        this.$Progress.finish();
                        // this.$router.push('/siswa')
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        this.$Progress.failed();
                        return false;
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          }
        }
    }
</script>
<style type="text/css">
    .select2{
        width: 100% !important;
    }

</style>
