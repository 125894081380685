<template>
  <section class="content">
    <div class="container-fluid">

        <div class="row">
          <div class="col-12">

                  <step-wizard
                      ref="wizard"
                      :steps="steps"
                      :onNext="nextClicked"
                      :onBack="backClicked"
                      :onCancel="cancelClicked"
                      :finalStepLabel="finalStepLabel">
                      <div slot="page1">
                        <!-- <h4>Step 1</h4> -->
                        <form @submit.prevent="simpanSiswa()" class="form-horizontal" id="formsiswa" enctype="multipart/form-data">
                        <input v-model="formData.id" type="hidden" name="id" class="form-control" >
                        <div class="col-md-12">
                              <div class="row">
                                  <div class="col-md-2">
                                    <div class="form-group">
                                      <img :src="previewImage" class="uploading-image" /> <br/><br/>
                                      <span class="text-danger"> Upload Foto Siswa disini </span> <br/>
                                      <input type="file" accept="image/jpeg" @change="uploadImage"> <br/><br/>
                                      <has-error :form="formData" field="foto"></has-error>
                                    </div>
                                  </div>
                                  <div class="col-md-10">
                                    <div class="row">
                                      <div class="form-group col-md-4">
                                          <label>No. Pendaftaran <small class="text-danger">(Otomatis Terisi)</small></label>
                                            <input v-model="formData.no_pendaftaran" type="text" name="no_pendaftaran"
                                              class="form-control" :class="{ 'is-invalid': formData.errors.has('no_pendaftaran') }" placeholder="Contoh : 220200001" readonly="readonly">
                                            <has-error :form="formData" field="no_pendaftaran"></has-error>
                                      </div>
                                      <div class="form-group col-md-4">
                                          <label>Nama <small class="text-danger">(Lengkap)</small> Siswa</label>
                                            <input v-model="formData.nama_lengkap" type="text" name="nama_lengkap"
                                              class="form-control" :class="{ 'is-invalid': formData.errors.has('nama_lengkap') }" placeholder="Contoh : Dewa Ayu Radha">
                                            <has-error :form="formData" field="nama_lengkap"></has-error>
                                      </div>
                                      <div class="form-group col-md-4">
                                          <label>Nama <small class="text-danger">(Panggilan)</small> Siswa</label>
                                            <input v-model="formData.nama_panggilan" type="text" name="nama_panggilan"
                                              class="form-control" :class="{ 'is-invalid': formData.errors.has('nama_panggilan') }" placeholder="Contoh : Geg Radha">
                                            <has-error :form="formData" field="nama_panggilan"></has-error>
                                      </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-4">
                                            <label>Tempat Lahir</label>
                                              <input v-model="formData.tempat_lahir" type="text" name="tempat_lahir"
                                                  class="form-control" :class="{ 'is-invalid': formData.errors.has('tempat_lahir') }" placeholder="Contoh : Denpasar">
                                              <has-error :form="formData" field="tempat_lahir"></has-error>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label>Tgl. Lahir</label>
        <!--                                       <input v-model="formData.tgl_lahir" type="text" name="tgl_lahir"
                                                  class="form-control" :class="{ 'is-invalid': formData.errors.has('tgl_lahir') }" placeholder="Contoh : 31-01-2000"> -->
                                              <date-picker v-model="formData.tgl_lahir" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                                              <has-error :form="formData" field="tgl_lahir"></has-error>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label>Agama</label>
                                              <Select2 v-model="formData.agama" :options="listagama" @change="myChangeEvent($event)" @select="mySelectEvent($event)" placeholder="Pilih Agama"/>
                                              <has-error :form="formData" field="agama"></has-error>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                              
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Golongan Darah</label>
                                      <Select2 v-model="formData.golongan_darah" :options="listgolongandarah" @change="myChangeEvent($event)" @select="mySelectEvent($event)" placeholder="Pilih Golongan Darah"/>
                                      <has-error :form="formData" field="golongan_darah"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Jumlah Saudara</label>
                                      <input v-model="formData.jml_saudara" type="text" name="jml_saudara"
                                          class="form-control" :class="{ 'is-invalid': formData.errors.has('jml_saudara') }" placeholder="Contoh : 3">
                                      <has-error :form="formData" field="jml_saudara"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Anak Ke.</label>
                                      <input v-model="formData.anak_ke" type="text" name="anak_ke"
                                          class="form-control" :class="{ 'is-invalid': formData.errors.has('anak_ke') }" placeholder="Contoh : 2">
                                      <has-error :form="formData" field="anak_ke"></has-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Berat Badan Saat Lahir (Kg)</label>
                                      <input v-model="formData.berat_lahir" type="text" name="berat_lahir"
                                        class="form-control" :class="{ 'is-invalid': formData.errors.has('berat_lahir') }" placeholder="Contoh : 3.6">
                                      <has-error :form="formData" field="berat_lahir"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Berat Badan Saat Ini (Kg)</label>
                                      <input v-model="formData.berat_badan" type="text" name="berat_badan"
                                        class="form-control" :class="{ 'is-invalid': formData.errors.has('berat_badan') }" placeholder="Contoh : 12.1">
                                      <has-error :form="formData" field="berat_badan"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Tinggi Badan (Cm)</label>
                                      <input v-model="formData.tinggi_badan" type="text" name="tinggi_badan"
                                          class="form-control" :class="{ 'is-invalid': formData.errors.has('tinggi_badan') }" placeholder="Contoh : 90">
                                      <has-error :form="formData" field="tinggi_badan"></has-error>
                                </div>
                            </div>
                        </div>
                        </form>
                      </div>
                      <div slot="page2">
                        <form @submit.prevent="simpanAyah()" class="form-horizontal" id="formsiswa">
                        <input v-model="formAyah.id" type="hidden" name="id" class="form-control" >
                        <input v-model="formData.id" type="hidden" name="id_siswa" class="form-control" >
                        <div class="col-md-12">
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Nama Ayah </label>
                                      <input v-model="formAyah.nama_ayah" type="text" name="nama_ayah"
                                        class="form-control" :class="{ 'is-invalid': formAyah.errors.has('nama_ayah') }" placeholder="Contoh : Dewa Made Adi Basakra Joni" >
                                      <has-error :form="formAyah" field="nama_ayah"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>No. Handphone Ayah </label>
                                      <input v-model="formAyah.no_hp_ayah" type="text" name="no_hp_ayah"
                                        class="form-control" :class="{ 'is-invalid': formAyah.errors.has('no_hp_ayah') }" placeholder="Contoh : 08123456789" >
                                      <has-error :form="formAyah" field="no_hp_ayah"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Email Ayah</label>
                                      <input v-model="formAyah.email_ayah" type="text" name="email_ayah"
                                        class="form-control" :class="{ 'is-invalid': formAyah.errors.has('email_ayah') }" placeholder="Contoh : dewaadi414@gmail.com" >
                                      <has-error :form="formAyah" field="email_ayah"></has-error>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Pendidikan Terakhir Ayah</label>
                                      <input v-model="formAyah.pendidikan_terakhir_ayah" type="text" name="pendidikan_terakhir_ayah"
                                        class="form-control" :class="{ 'is-invalid': formAyah.errors.has('pendidikan_terakhir_ayah') }" placeholder="Contoh : S1" >
                                      <has-error :form="formAyah" field="pendidikan_terakhir_ayah"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>No. Telp. Kantor Ayah</label>
                                      <input v-model="formAyah.telp_kantor_ayah" type="text" name="telp_kantor_ayah"
                                        class="form-control" :class="{ 'is-invalid': formAyah.errors.has('telp_kantor_ayah') }" placeholder="Contoh : 0361 123123" >
                                      <has-error :form="formAyah" field="telp_kantor_ayah"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Akun Sosial Media Ayah</label>
                                      <input v-model="formAyah.akun_sosmed_ayah" type="text" name="akun_sosmed_ayah"
                                        class="form-control" :class="{ 'is-invalid': formAyah.errors.has('akun_sosmed_ayah') }" placeholder="Contoh : ig: @dewadi" >
                                      <has-error :form="formAyah" field="akun_sosmed_ayah"></has-error>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Alamat Tinggal Ayah </label>
                                      <textarea v-model="formAyah.alamat_ayah" type="text" name="alamat_ayah"
                                                    class="form-control" :class="{ 'is-invalid': formAyah.errors.has('alamat_ayah') }" rows="2" placeholder="Contoh : Perumahan Puri Candra Asri No. 1" >
                                      </textarea>
                                      <has-error :form="formAyah" field="alamat_ayah"></has-error>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Alamat Kantor Ayah </label>
                                      <textarea v-model="formAyah.alamat_kantor_ayah" type="text" name="alamat_kantor_ayah"
                                                    class="form-control" :class="{ 'is-invalid': formAyah.errors.has('alamat_kantor_ayah') }" rows="2" placeholder="Contoh : Jl. Ida Bagus Mantra No. 100" >
                                      </textarea>
                                      <has-error :form="formAyah" field="alamat_kantor_ayah"></has-error>
                                </div>
                              </div>

                        </div>
                        </form>
                      </div>
                      <div slot="page3">
                        <form @submit.prevent="simpanIbu()" class="form-horizontal" id="formsiswa">
                        <input v-model="formIbu.id" type="hidden" name="id" class="form-control" >
                        <input v-model="formData.id" type="hidden" name="id_siswa" class="form-control" >
                        <div class="col-md-12">
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Nama Bunda </label>
                                      <input v-model="formIbu.nama_bunda" type="text" name="nama_bunda"
                                        class="form-control" :class="{ 'is-invalid': formIbu.errors.has('nama_bunda') }" placeholder="Contoh : Ida Ayu Lely" >
                                      <has-error :form="formIbu" field="nama_bunda"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>No. Handphone Bunda </label>
                                      <input v-model="formIbu.no_hp_bunda" type="text" name="no_hp_bunda"
                                        class="form-control" :class="{ 'is-invalid': formIbu.errors.has('no_hp_bunda') }" placeholder="Contoh : 08123456789" >
                                      <has-error :form="formIbu" field="no_hp_bunda"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Email Bunda</label>
                                      <input v-model="formIbu.email_bunda" type="text" name="email_bunda"
                                        class="form-control" :class="{ 'is-invalid': formIbu.errors.has('email_bunda') }" placeholder="Contoh : dayulely@gmail.com" >
                                      <has-error :form="formIbu" field="email_bunda"></has-error>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Pendidikan Terakhir Bunda</label>
                                      <input v-model="formIbu.pendidikan_terakhir_bunda" type="text" name="pendidikan_terakhir_bunda"
                                        class="form-control" :class="{ 'is-invalid': formIbu.errors.has('pendidikan_terakhir_bunda') }" placeholder="Contoh : ig : @bunda_cantik" >
                                      <has-error :form="formIbu" field="pendidikan_terakhir_bunda"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>No. Telp. Kantor Bunda</label>
                                      <input v-model="formIbu.telp_kantor_bunda" type="text" name="telp_kantor_bunda"
                                        class="form-control" :class="{ 'is-invalid': formIbu.errors.has('telp_kantor_bunda') }" placeholder="Contoh : 0361 123123" >
                                      <has-error :form="formIbu" field="telp_kantor_bunda"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Akun Sosial Media Bunda</label>
                                      <input v-model="formIbu.akun_sosmed_bunda" type="text" name="akun_sosmed_bunda"
                                        class="form-control" :class="{ 'is-invalid': formIbu.errors.has('akun_sosmed_bunda') }" placeholder="Contoh : ig : @bunda_cantik" >
                                      <has-error :form="formIbu" field="akun_sosmed_bunda"></has-error>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Alamat Tinggal Bunda </label>
                                      <textarea v-model="formIbu.alamat_bunda" type="text" name="alamat_bunda"
                                                    class="form-control" :class="{ 'is-invalid': formIbu.errors.has('alamat_bunda') }" rows="2" placeholder="Contoh : Perumahan Puri Candra Asri No. 1" >
                                      </textarea>
                                      <has-error :form="formIbu" field="alamat_bunda"></has-error>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Alamat Kantor Bunda </label>
                                      <textarea v-model="formIbu.alamat_kantor_bunda" type="text" name="alamat_kantor_bunda"
                                                    class="form-control" :class="{ 'is-invalid': formIbu.errors.has('alamat_kantor_bunda') }" rows="2" placeholder="Contoh : Jl. Ida Bagus Mantra No. 100" >
                                      </textarea>
                                      <has-error :form="formIbu" field="alamat_kantor_bunda"></has-error>
                                </div>
                              </div>
                              
                        </div>
                        </form>
                      </div>
                      <div slot="page4">
                        <form @submit.prevent="simpanKesehatan()" class="form-horizontal" id="formsiswa">
                        <input v-model="formKesehatan.id" type="hidden" name="id" class="form-control" >
                        <input v-model="formData.id" type="hidden" name="id_siswa" class="form-control" >
                        <div class="col-md-12">
                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label>Proses Kelahiran Siswa </label>
                                      <input v-model="formKesehatan.proses_kelahiran" type="text" name="proses_kelahiran"
                                        class="form-control" :class="{ 'is-invalid': formKesehatan.errors.has('proses_kelahiran') }" placeholder="Contoh : Normal/Caesar" >
                                      <has-error :form="formKesehatan" field="proses_kelahiran"></has-error>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Pernah Sakit Keras </label>
                                    <div>
                                      <div class="form-check form-check-inline" v-for="pilihan in pilihans">
                                        <input class="form-check-input" :id="pilihan.id" type="radio" name="pernah_sakit_keras" :class="{ 'is-invalid': formKesehatan.errors.has('pernah_sakit_keras') }" v-model="formKesehatan.pernah_sakit_keras" v-bind:value="pilihan.id" :checked="pilihan.id == formKesehatan.pernah_sakit_keras">
                                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                                      </div>
                                      <has-error :form="formKesehatan" field="pernah_sakit_keras"></has-error>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Jenis Sakit Keras</label>
                                      <input v-model="formKesehatan.sakit_keras" type="text" name="sakit_keras"
                                        class="form-control" :class="{ 'is-invalid': formKesehatan.errors.has('sakit_keras') }" placeholder="Contoh : Demam Berdarah" >
                                      <has-error :form="formKesehatan" field="sakit_keras"></has-error>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Tgl Sakit Keras</label>
<!--                                       <input v-model="formKesehatan.tgl_sakit_keras" type="text" name="tgl_sakit_keras"
                                        class="form-control" :class="{ 'is-invalid': formKesehatan.errors.has('tgl_sakit_keras') }" placeholder="Contoh : 22-02-2022" > -->
                                      <date-picker v-model="formKesehatan.tgl_sakit_keras" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                                      <has-error :form="formKesehatan" field="tgl_sakit_keras"></has-error>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Tahap Perkembangan </label>
                                      <textarea v-model="formKesehatan.tahap_perkembangan" type="text" name="tahap_perkembangan"
                                        class="form-control" :class="{ 'is-invalid': formKesehatan.errors.has('tahap_perkembangan') }" placeholder="Contoh : Sudah Sehat Dari Demam" > </textarea>
                                      <has-error :form="formKesehatan" field="tahap_perkembangan"></has-error>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Kebiasaan Khusus </label>
                                      <textarea v-model="formKesehatan.kebiasaan_khusus" type="text" name="kebiasaan_khusus"
                                        class="form-control" :class="{ 'is-invalid': formKesehatan.errors.has('kebiasaan_khusus') }" placeholder="Contoh : Sering Merebut Mainan Orang" > </textarea>
                                      <has-error :form="formKesehatan" field="kebiasaan_khusus"></has-error>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-3">
                                    <label>Alergi Makanan</label>
                                    <div>
                                      <div class="form-check form-check-inline" v-for="pilihan in pilihans">
                                        <input class="form-check-input" :id="pilihan.id" type="radio" name="alergi_makanan" :class="{ 'is-invalid': formKesehatan.errors.has('alergi_makanan') }" v-model="formKesehatan.alergi_makanan" v-bind:value="pilihan.id" :checked="pilihan.id == formKesehatan.alergi_makanan">
                                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                                      </div>
                                      <has-error :form="formKesehatan" field="alergi_makanan"></has-error>
                                    </div>
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Alergi Obat</label>
                                    <div>
                                      <div class="form-check form-check-inline" v-for="pilihan in pilihans">
                                        <input class="form-check-input" :id="pilihan.id" type="radio" name="alergi_obat" :class="{ 'is-invalid': formKesehatan.errors.has('alergi_obat') }" v-model="formKesehatan.alergi_obat" v-bind:value="pilihan.id" :checked="pilihan.id == formKesehatan.alergi_obat">
                                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                                      </div>
                                      <has-error :form="formKesehatan" field="alergi_obat"></has-error>
                                    </div>
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Alergi Kacang</label>
                                    <div>
                                      <div class="form-check form-check-inline" v-for="pilihan in pilihans">
                                        <input class="form-check-input" :id="pilihan.id" type="radio" name="alergi_kacang" :class="{ 'is-invalid': formKesehatan.errors.has('alergi_kacang') }" v-model="formKesehatan.alergi_kacang" v-bind:value="pilihan.id" :checked="pilihan.id == formKesehatan.alergi_kacang">
                                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                                      </div>
                                      <has-error :form="formKesehatan" field="alergi_kacang"></has-error>
                                    </div>
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Alergi Glutten</label>
                                    <div>
                                      <div class="form-check form-check-inline" v-for="pilihan in pilihans">
                                        <input class="form-check-input" :id="pilihan.id" type="radio" name="alergi_glutten" :class="{ 'is-invalid': formKesehatan.errors.has('alergi_glutten') }" v-model="formKesehatan.alergi_glutten" v-bind:value="pilihan.id" :checked="pilihan.id == formKesehatan.alergi_glutten">
                                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                                      </div>
                                      <has-error :form="formKesehatan" field="alergi_glutten"></has-error>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Alergi Lainnya</label>
                                    <div>
                                      <div class="form-check form-check-inline" v-for="pilihan in pilihans">
                                        <input class="form-check-input" :id="pilihan.id" type="radio" name="alergi_lainnya" :class="{ 'is-invalid': formKesehatan.errors.has('alergi_lainnya') }" v-model="formKesehatan.alergi_lainnya" v-bind:value="pilihan.id" :checked="pilihan.id == formKesehatan.alergi_lainnya">
                                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                                      </div>
                                      <has-error :form="formKesehatan" field="alergi_lainnya"></has-error>
                                    </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Deskripsi Alergi</label>
                                      <textarea v-model="formKesehatan.alergi_lainnya_ket" type="text" name="alergi_lainnya_ket"
                                        class="form-control" :class="{ 'is-invalid': formKesehatan.errors.has('alergi_lainnya_ket') }" placeholder="Contoh : Alergi Makanan Seperti Daging Ayam" > </textarea>
                                      <has-error :form="formKesehatan" field="alergi_lainnya_ket"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Reaksi Alergi</label>
                                      <textarea v-model="formKesehatan.reaksi_alergi" type="text" name="reaksi_alergi"
                                        class="form-control" :class="{ 'is-invalid': formKesehatan.errors.has('reaksi_alergi') }" placeholder="Contoh : Gatal-Gatal Terhadap Imunopresant" > </textarea>
                                      <has-error :form="formKesehatan" field="reaksi_alergi"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Pengobatan Terapi</label>
                                      <textarea v-model="formKesehatan.pengobatan_terapi" type="text" name="pengobatan_terapi"
                                        class="form-control" :class="{ 'is-invalid': formKesehatan.errors.has('pengobatan_terapi') }" placeholder="Contoh : Sedang Terapi Bicara" > </textarea>
                                      <has-error :form="formKesehatan" field="pengobatan_terapi"></has-error>
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col-md-6">
                                    <label>Konsumsi Obat</label>
                                      <textarea v-model="formKesehatan.konsumsi_obat" type="text" name="konsumsi_obat"
                                        class="form-control" :class="{ 'is-invalid': formKesehatan.errors.has('konsumsi_obat') }" placeholder="Contoh : Paracetamol" > </textarea>
                                      <has-error :form="formKesehatan" field="konsumsi_obat"></has-error>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Aspek Sosial Akademik</label>
                                      <textarea v-model="formKesehatan.aspek_sosial_akademis" type="text" name="aspek_sosial_akademis"
                                        class="form-control" :class="{ 'is-invalid': formKesehatan.errors.has('aspek_sosial_akademis') }" placeholder="Contoh : Sudah Bisa Membaca" > </textarea>
                                      <has-error :form="formKesehatan" field="aspek_sosial_akademis"></has-error>
                                </div>
                              </div>
                        </div>
                        </form>
                      </div>
                      <div slot="page5">
                        <form @submit.prevent="simpanImunisasi()" class="form-horizontal" id="formsiswa">
                        <input v-model="formImunisasi.id" type="hidden" name="id" class="form-control" >
                        <input v-model="formData.id" type="hidden" name="id_siswa" class="form-control" >
                        <div class="col-md-12">
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-success">HB</span> Pada Usia </label>
                                      <input v-model="formImunisasi.hb_usia" type="text" name="hb_usia"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('hb_usia') }" placeholder="Contoh : 3" >
                                      <has-error :form="formImunisasi" field="hb_usia"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-success">HB</span> Pada Tanggal </label>
                                      <!-- <input v-model="formImunisasi.hb_tgl" type="text" name="hb_tgl"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('hb_tgl') }" placeholder="Contoh : 22-01-2022" > -->
                                      <date-picker v-model="formImunisasi.hb_tgl" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                                      <has-error :form="formImunisasi" field="hb_tgl"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-success">HB</span> Pada Faskes</label>
                                      <input v-model="formImunisasi.hb_faskes" type="text" name="hb_faskes"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('hb_faskes') }" placeholder="Contoh : PUSKESMAS BATU BULAN" >
                                      <has-error :form="formImunisasi" field="hb_faskes"></has-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-primary">BCG</span> Pada Usia </label>
                                      <input v-model="formImunisasi.bcg_usia" type="text" name="bcg_usia"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('bcg_usia') }" placeholder="Contoh : 3" >
                                      <has-error :form="formImunisasi" field="bcg_usia"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-primary">BCG</span> Pada Tanggal </label>
                                      <!-- <input v-model="formImunisasi.bcg_tgl" type="text" name="bcg_tgl"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('bcg_tgl') }" placeholder="Contoh : 22-01-2022" > -->
                                      <date-picker v-model="formImunisasi.bcg_tgl" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                                      <has-error :form="formImunisasi" field="bcg_tgl"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-primary">BCG</span> Pada Faskes</label>
                                      <input v-model="formImunisasi.bcg_faskes" type="text" name="bcg_faskes"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('bcg_faskes') }" placeholder="Contoh : PUSKESMAS BATU BULAN" >
                                      <has-error :form="formImunisasi" field="bcg_faskes"></has-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-danger">POLIO</span> Pada Usia </label>
                                      <input v-model="formImunisasi.polio_usia" type="text" name="polio_usia"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('polio_usia') }" placeholder="Contoh : 3" >
                                      <has-error :form="formImunisasi" field="polio_usia"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-danger">POLIO</span> Pada Tanggal </label>
                                      <!-- <input v-model="formImunisasi.polio_tgl" type="text" name="polio_tgl"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('polio_tgl') }" placeholder="Contoh : 22-01-2022" > -->
                                      <date-picker v-model="formImunisasi.polio_tgl" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                                      <has-error :form="formImunisasi" field="polio_tgl"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-danger">POLIO</span> Pada Faskes</label>
                                      <input v-model="formImunisasi.polio_faskes" type="text" name="polio_faskes"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('polio_faskes') }" placeholder="Contoh : PUSKESMAS BATU BULAN" >
                                      <has-error :form="formImunisasi" field="polio_faskes"></has-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-success">DPT</span> Pada Usia </label>
                                      <input v-model="formImunisasi.dpt_usia" type="text" name="dpt_usia"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('dpt_usia') }" placeholder="Contoh : 3" >
                                      <has-error :form="formImunisasi" field="dpt_usia"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-success">DPT</span> Pada Tanggal </label>
                                      <!-- <input v-model="formImunisasi.dpt_tgl" type="text" name="dpt_tgl"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('dpt_tgl') }" placeholder="Contoh : 22-01-2022" > -->
                                      <date-picker v-model="formImunisasi.dpt_tgl" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                                      <has-error :form="formImunisasi" field="dpt_tgl"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-success">DPT</span> Pada Faskes</label>
                                      <input v-model="formImunisasi.dpt_faskes" type="text" name="dpt_faskes"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('dpt_faskes') }" placeholder="Contoh : PUSKESMAS BATU BULAN" >
                                      <has-error :form="formImunisasi" field="dpt_faskes"></has-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-info">HIB</span> Pada Usia </label>
                                      <input v-model="formImunisasi.hib_usia" type="text" name="hib_usia"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('hib_usia') }" placeholder="Contoh : 3" >
                                      <has-error :form="formImunisasi" field="hib_usia"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-info">HIB</span> Pada Tanggal </label>
                                      <!-- <input v-model="formImunisasi.hib_tgl" type="text" name="hib_tgl"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('hib_tgl') }" placeholder="Contoh : 22-01-2022" > -->
                                      <date-picker v-model="formImunisasi.hib_tgl" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                                      <has-error :form="formImunisasi" field="hib_tgl"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-info">HIB</span> Pada Faskes</label>
                                      <input v-model="formImunisasi.hib_faskes" type="text" name="hib_faskes"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('hib_faskes') }" placeholder="Contoh : PUSKESMAS BATU BULAN" >
                                      <has-error :form="formImunisasi" field="hib_faskes"></has-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-danger">CAMPAK</span> Pada Usia </label>
                                      <input v-model="formImunisasi.campak_usia" type="text" name="campak_usia"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('campak_usia') }" placeholder="Contoh : 3" >
                                      <has-error :form="formImunisasi" field="campak_usia"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-danger">CAMPAK</span> Pada Tanggal </label>
                                      <!-- <input v-model="formImunisasi.campak_tgl" type="text" name="campak_tgl"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('campak_tgl') }" placeholder="Contoh : 22-01-2022" > -->
                                      <date-picker v-model="formImunisasi.campak_tgl" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                                      <has-error :form="formImunisasi" field="campak_tgl"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-danger">CAMPAK</span> Pada Faskes</label>
                                      <input v-model="formImunisasi.campak_faskes" type="text" name="campak_faskes"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('campak_faskes') }" placeholder="Contoh : PUSKESMAS BATU BULAN" >
                                      <has-error :form="formImunisasi" field="campak_faskes"></has-error>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-success">OTHER</span> Pada Usia </label>
                                      <input v-model="formImunisasi.other_usia" type="text" name="other_usia"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('other_usia') }"  >
                                      <has-error :form="formImunisasi" field="other_usia"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-success">OTHER</span> Pada Tanggal </label>
                                      <!-- <input v-model="formImunisasi.other_tgl" type="text" name="other_tgl"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('other_tgl') }" > -->
                                      <date-picker v-model="formImunisasi.other_tgl" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                                      <has-error :form="formImunisasi" field="other_tgl"></has-error>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Imunisasi <span class="text-success">OTHER</span> Pada Faskes</label>
                                      <input v-model="formImunisasi.other_faskes" type="text" name="other_faskes"
                                        class="form-control" :class="{ 'is-invalid': formImunisasi.errors.has('other_faskes') }"  >
                                      <has-error :form="formImunisasi" field="other_faskes"></has-error>
                                </div>
                            </div>
                        </div>
                        </form>
                      </div>
                    </step-wizard>
          </div>
        </div>


    </div>
  </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import StepWizard from '../../../components/Wizard.vue';
    import 'vue2-datepicker/index.css';
    import { EventBus } from '../../../eventBus.js';
    import Select2 from '../../../components/Select2.vue';

    export default {
      name:'FormSiswa',
      components: {
          DatePicker,
          StepWizard,
          Select2,
        },
        data () {
            return {
                isActiveLoading:false,
                statuswizard:false,
                finalStepLabel:'Simpan',
                listagama: [
                             {id: 'Hindu', text: 'Hindu'},
                             {id: 'Islam', text: 'Islam'},
                             {id: 'Kristen Protestan', text: 'Kristen Protestan'},
                             {id: 'Kristen Khatolik', text: 'Kristen Khatolik'},
                             {id: 'Budha', text: 'Budha'},
                             {id: 'Konghucu', text: 'Konghucu'},
                             {id: 'Lainnya', text: 'Lainnya'},
                          ],
                listgolongandarah: [
                             {id: 'A', text: 'A'},
                             {id: 'B', text: 'B'},
                             {id: 'AB', text: 'AB'},
                             {id: 'O', text: 'O'},
                             {id: 'Lainnya', text: 'Lainnya'},
                          ],
                pilihans:[
                          {name: 'Tidak', id: 2},
                          {name: 'Ya', id: 1}
                        ],
                previewImage:'/img/user.jpg',
                formData: new Form({
                    id : '',
                    foto : '',
                    no_pendaftaran : '',
                    nama_lengkap : '',
                    nama_panggilan: '',
                    tempat_lahir: '',
                    tgl_lahir: '',
                    agama: '',
                    golongan_darah: '',
                    anak_ke: '',
                    jml_saudara: '',
                    berat_lahir: '',
                    tinggi_badan: '',
                    berat_badan: '',
                }),
                formAyah: new Form({
                    id : '',
                    id_siswa : '',
                    nama_ayah : '',
                    alamat_ayah : '',
                    no_hp_ayah: '',
                    pendidikan_terakhir_ayah: '',
                    alamat_kantor_ayah: '',
                    telp_kantor_ayah: '',
                    email_ayah: '',
                    akun_sosmed_ayah: '',
                }),
                formIbu: new Form({
                    id : '',
                    id_siswa : '',
                    nama_bunda : '',
                    alamat_bunda : '',
                    no_hp_bunda: '',
                    pendidikan_terakhir_bunda: '',
                    alamat_kantor_bunda: '',
                    telp_kantor_bunda: '',
                    email_bunda: '',
                    akun_sosmed_bunda: '',
                }),
                formKesehatan: new Form({
                    id : '',
                    id_siswa : '',
                    proses_kelahiran : '',
                    pernah_sakit_keras : 2,
                    sakit_keras : '',
                    tgl_sakit_keras: '',
                    tahap_perkembangan: '',
                    kebiasaan_khusus: '',
                    alergi_makanan: 2,
                    alergi_obat: 2,
                    alergi_kacang: 2,
                    alergi_glutten: 2,
                    alergi_lainnya: 2,
                    alergi_lainnya_ket: '',
                    reaksi_alergi: '',
                    pengobatan_terapi: '',
                    konsumsi_obat: '',
                    aspek_sosial_akademis: '',
                }),
                formImunisasi: new Form({
                    id : '',
                    id_siswa : '',
                    hb_usia : '',
                    hb_tgl : '',
                    hb_faskes: '',
                    bcg_usia: '',
                    bcg_tgl: '',
                    bcg_faskes: '',
                    polio_usia: '',
                    polio_tgl: '',
                    polio_faskes: '',
                    dpt_usia: '',
                    dpt_tgl: '',
                    dpt_faskes: '',
                    hib_usia: '',
                    hib_tgl: '',
                    hib_faskes: '',
                    campak_usia: '',
                    campak_tgl: '',
                    campak_faskes: '',
                    other_usia: '',
                    other_tgl: '',
                    other_faskes: '',
                }),
                steps: [
                    {
                      label: 'Biodata Siswa',
                      slot: 'page1',
                    },
                    {
                      label: 'Data Ayah',
                      slot: 'page2',
                    },
                    {
                      label: 'Data Ibu',
                      slot: 'page3',
                    },
                    {
                      label: 'Informasi Kesehatan',
                      slot: 'page4',
                    },
                    {
                      label: 'Imunisasi',
                      slot: 'page5',
                    },
                  ],
            }
        },
        props: ['siswaId','editmode'],
        methods: {
          nextClicked(currentPage) {
            if(currentPage == 0){
              this.simpanSiswa(this)
            }else if(currentPage == 1){
              this.simpanAyah(this)
            }else if(currentPage == 2){
              this.simpanIbu(this)
            }else if(currentPage == 3){
              this.simpanKesehatan(this)
            }else if(currentPage == 4){
              this.simpanImunisasi(this)
            }else{
              console.log('Nothing to do');
            }
            // return true;
          },
          backClicked(currentPage) {
            // if(currentPage == 0){
              // console.log('back clicked', currentPage);
            // }
            return true; //return false if you want to prevent moving to previous page
          },
          cancelClicked(currentPage) {
            // this.$router.push('/siswa')
            this.$emit('cancelForm')
          },
          loadData(){
            if(this.editmode){
              axios.get("api/siswa/"+this.siswaId).then(({ data }) => {
                // this.formData = data.data
                this.formData.reset();
                if(data.data){
                  this.formData.fill(data.data);
                  this.previewImage = data.data.foto
                }
              });
              axios.get("api/ayah/"+this.siswaId).then(({ data }) => {
                // this.formData = data.data
                this.formAyah.reset();
                if(data.data){
                  this.formAyah.fill(data.data);
                }
              });
              axios.get("api/ibu/"+this.siswaId).then(({ data }) => {
                // this.formData = data.data
                this.formIbu.reset();
                if(data.data){
                  this.formIbu.fill(data.data);
                }
              });
              axios.get("api/kesehatan/"+this.siswaId).then(({ data }) => {
                // this.formData = data.data
                this.formKesehatan.reset();
                if(data.data){
                  this.formKesehatan.fill(data.data);
                }
              });
              axios.get("api/imunisasi/"+this.siswaId).then(({ data }) => {
                console.log(data.data);
                // this.formData = data.data
                this.formImunisasi.reset();
                if(data.data){
                  this.formImunisasi.fill(data.data);
                }
              });
            }else{
              
            }
          },
          simpanSiswa(wz){
            // return false;
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            this.statuswizard = false;
            let id = this.formData.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            // console.log(this.formData);
            this.formData.put('api/siswa/'+id)
              .then((data)=>{
                if(data.data.success){
                  // console.log(data.data.data.id,id);
                  this.formData.id = data.data.data.id;
                  setTimeout(() => {
                        $("input[name='id_siswa']").val(data.data.data.id);
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        this.formAyah.id_siswa = data.data.data.id;
                        this.formIbu.id_siswa = data.data.data.id;
                        this.formKesehatan.id_siswa = data.data.data.id;
                        this.formImunisasi.id_siswa = data.data.data.id;
                        $('#pageloader').css('display','none');
                        wz.$refs.wizard.goNext(true);
                        this.$Progress.finish();
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        return false;
                        this.$Progress.failed();
                  }, 300);

                }
              })
              .catch((data)=>{
                  console.log(data);
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          },
          simpanAyah(wz){
            console.log('simpan ayah');
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            let id = this.formAyah.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.formAyah.put('api/ayah/'+id)
              .then((data)=>{
                if(data.data.success){
                  this.formAyah.id = data.data.data.id;
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        $('#pageloader').css('display','none');
                        wz.$refs.wizard.goNext(true);
                        this.$Progress.finish();
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        return false;
                        this.$Progress.failed();
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          },
          simpanIbu(wz){
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            let id = this.formIbu.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.formIbu.put('api/ibu/'+id)
              .then((data)=>{
                if(data.data.success){
                  this.formIbu.id = data.data.data.id;
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        $('#pageloader').css('display','none');
                        wz.$refs.wizard.goNext(true);
                        this.$Progress.finish();
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        return false;
                        this.$Progress.failed();
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          },
          simpanKesehatan(wz){
            console.log('simpan Kesehatan');
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            let id = this.formKesehatan.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.formKesehatan.put('api/kesehatan/'+id)
              .then((data)=>{
                if(data.data.success){
                  this.formKesehatan.id = data.data.data.id;
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        $('#pageloader').css('display','none');
                        wz.$refs.wizard.goNext(true);
                        this.$Progress.finish();
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        return false;
                        this.$Progress.failed();
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          },
          simpanImunisasi(wz){
            console.log('simpan Imunisasi');
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            let id = this.formImunisasi.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.formImunisasi.put('api/imunisasi/'+id)
              .then((data)=>{
                if(data.data.success){
                  this.formImunisasi.id = data.data.data.id;
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        // wz.$refs.wizard.goNext(true);
                        $('#pageloader').css('display','none');
                        this.$Progress.finish();
                        this.$emit('cancelForm')
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        return false;
                        this.$Progress.failed();
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          },
          myChangeEvent(event){

          },
          mySelectEvent(event){

          },
          uploadImage(e){
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e =>{
                    this.previewImage = e.target.result;
                    // this.formData.foto = image;
                    this.formData.foto = this.previewImage;
                    // console.log(image);
                };

            }

        },
        beforeMount() {
          // if(this.$gate.isAdmin()){
            this.isActiveLoading = true;
          // }else{
            // this.$router.push({ path: '/dashboard' });
          // }
        },
        mounted() {
            // console.log('coba');
            setTimeout(() => {
                    this.isActiveLoading = false;
              }, 500);
        },
        created() {
            this.$Progress.start();
            this.isActiveLoading = true;
            this.loadData()
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        watch: {

        },
        computed: {

        }
    }
</script>

<style type="text/css">
  .uploading-image{
     /*display:flex;*/
     width:150px !important; 
     height:150px !important
   }
</style>