require('./bootstrap');

import Vue from 'vue';
import router from "./router";
import moment from 'moment';
import { Form, HasError, AlertError } from 'vform';
import Swal from 'sweetalert2';
import VueProgressBar from 'vue-progressbar'

import App from './components/App.vue'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueExcelXlsx from "vue-excel-xlsx";

// import 'select2/dist/css/select2.min.css';
// import 'select2';

window.Vue = Vue

window.Form = Form;
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
window.Swal = Swal;
window.Toast = Toast;

Vue.use(VueProgressBar, {
    color: 'rgb(237, 178, 2)',
    failedColor: 'red',
    height: '3px'
  });

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component(
    'not-found',
    require('./components/404.vue').default
);
Vue.component(VueQrcode.name, VueQrcode);
Vue.use(VueExcelXlsx);

Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));

new Vue({
  el: '#app',
  components: { App },
  router,
});
