<template>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- <div class="col-lg-3 col-6">

          <div class="small-box bg-info">
          <div class="inner">
          <h3>150</h3>
          <p>New Orders</p>
          </div>
          <div class="icon">
          <i class="ion ion-bag"></i>
          </div>
          <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
          </div>

          <div class="col-lg-3 col-6">

          <div class="small-box bg-success">
          <div class="inner">
          <h3>53<sup style="font-size: 20px">%</sup></h3>
          <p>Bounce Rate</p>
          </div>
          <div class="icon">
          <i class="ion ion-stats-bars"></i>
          </div>
          <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
          </div>

          <div class="col-lg-3 col-6">

          <div class="small-box bg-warning">
          <div class="inner">
          <h3>44</h3>
          <p>User Registrations</p>
          </div>
          <div class="icon">
          <i class="ion ion-person-add"></i>
          </div>
          <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
          </div>

          <div class="col-lg-3 col-6">

          <div class="small-box bg-danger">
          <div class="inner">
          <h3>65</h3>
          
          <p>Unique Visitors</p>
          </div>
          <div class="icon">
          <i class="ion ion-pie-graph"></i>
          </div>
          <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
          </div> -->

        </div>

      </div>
    </section>
</template>

<script>
export default {
  components:{
    
  },
  data() {
      return {
        user: '',
        loginType: '',
        search: '',
      }
    },
    props:{
      title:'',
    },
    beforeMount(){
      if(localStorage.getItem('token') == null){
          this.$router.push('login')
      }
    },
    methods: {
      
    },
    created() {     
      this.$Progress.start();
      // console.log(this.navigation);
      this.$emit('setAxios')
      this.user = JSON.parse(localStorage.getItem('user'))
      this.loginType = this.user.roles[0].name
      this.$Progress.finish();
      // axios.get(`/api/user`)
      // .then(response => {
      //   this.user = response.data
      //   this.loginType = response.data.roles[0].name
      // })
      // .catch(error => {
      //   if (error.response.status === 401) {
      //     localStorage.clear();
      //     this.$router.push('/login')
      //   }
      //   console.error(error);
      // })
    }
}
</script>
