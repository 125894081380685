<template>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
		    <div class="col-md-12">
		    &nbsp;
		    </div>
		    <div class="col-md-12">
		        <!-- Widget: user widget style 2 -->
		        <div class="card card-widget widget-user">
		          <!-- Add the bg color to the header using any of the bg-* classes -->
		          <div class="widget-user-header bg-success">
		            <!-- /.widget-user-image -->
		            <h3 class="widget-user-username">{{formdata.nama_lengkap}}</h3>
		            <h5 class="widget-user-desc">{{formdata.nama_panggilan}}</h5>
		          </div>
              <div class="widget-user-image">
                  <img class="img-circle elevation-2" :src="'/'+formdata.foto" alt="User Avatar">
              </div>
		          <div class="card-footer ">
                <div class="row">
                  
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">{{formdata.usia}}</h5>
                      <span class="description-text">USIA</span>
                    </div>
                  </div>

                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">{{formdata.tgl_pendaftaran}}</h5>
                      <span class="description-text">DAFTAR</span>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">{{formdata.tgl_jatuh_tempo}}</h5>
                      <span class="description-text">SELESAI</span>
                    </div>
                  </div>

                </div>
		          </div>
		        </div>
		        <!-- /.widget-user -->

            <div class="callout callout-success">
              <h5>Rekap Laporan Siswa Trikaya Educare Periode ({{filterbulancaptionawal}} - {{filterbulancaptionakhir}})</h5>
              <p>Laporan ini secara otomatis menampilkan kegiatan 3 bulan terakhir, Anda dapat Memfilter bulan yang sesuai anda inginkan dan Anda dapat melihat laporan detail perkembangan anak anda dengan klik tombol lihat laporan detail</p>
              <div class="row">
                  <div class="form-group col-md-6"> 
                      <label>Filter Bulan</label>
                      <date-picker
                        v-model="filterbulan"
                        type="month"
                        valueType="format"
                        placeholder="Pilih Bulan"
                        range
                        :show-time-panel="showTimeRangePanel"
                        @close="handleRangeClose"
                        @change="filtermonth($event)"
                      >
                        <!-- <template v-slot:footer>
                          <button class="mx-btn mx-btn-text" @click="toggleTimeRangePanel">
                            {{ showTimeRangePanel ? 'select date' : 'select time' }}
                          </button>
                        </template> -->
                      </date-picker>
                  </div>
                  <div class="form-group col-md-6" align="right">
                      <router-link :to="link" class="btn btn-success text-white" style="text-decoration:none;"> Lihat Laporan Detail </router-link> 
                      <button type="button" class="btn btn-secondary btn-danger" @click="generateReport" >Print PDF</button>
                  </div>
              </div>
            </div>
		     </div>
		     <div class="col-md-6 card">
           <h6 align="center"> Kuantitas Kegiatan </h6>
           <Pie
            :chart-options="chartOptionsPie"
            :chart-data="chartDataPie"
            :chart-id="chartIdPie"
            :dataset-id-key="datasetIdKeyPie"
            :plugins="pluginsPie"
            :css-classes="cssClassesPie"
            :styles="stylesPie"
            :width="widthPie"
            :height="200"
          /></Pie>
         </div>
         <div class="col-md-6 card">
           <h6 align="center"> Rata-Rata Nilai Kegiatan </h6>
            <Bar
          :chart-options="chartOptionsBar"
          :chart-data="chartDataBar"
          :chart-id="chartIdBar"
          :dataset-id-key="datasetIdKeyBar"
          :plugins="pluginsBar"
          :css-classes="cssClassesBar"
          :styles="stylesBar"
          :width="widthBar"
          :height="250"
          /></Bar>
         </div>
         <div class="col-md-6 card">
           <h6 align="center"> Perkembangan Rata-Rata Nilai Kegiatan </h6>
              <LineChartGenerator
            :chart-options="chartOptionsLine"
            :chart-data="chartDataLine"
            :chart-id="chartIdLine"
            :dataset-id-key="datasetIdKeyLine"
            :plugins="pluginsLine"
            :css-classes="cssClassesLine"
            :styles="stylesLine"
            :width="widthLine"
            :height="350"
          /></LineChartGenerator>
       </div>
       <div class="col-md-6 card">
           <h6 align="center"> Pemeriksaan Kesehatan </h6>
              <LineChartGenerator
            :chart-options="chartOptionsLine2"
            :chart-data="chartDataLine2"
            :chart-id="chartIdLine2"
            :dataset-id-key="datasetIdKeyLine2"
            :plugins="pluginsLine2"
            :css-classes="cssClassesLine2"
            :styles="stylesLine2"
            :width="widthLine2"
            :height="350"
          /></LineChartGenerator>
       </div>
		</div>
	  </div>
    
    <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="'Export Report '+formdata.nama_lengkap"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            ref="html2Pdf"
        >
        <section slot="pdf-content" style="padding:0px !important; margin: 0px 0px 0px 0px !important; background:#fff!important">
          <!-- <section class="pdf-item"> -->
              <!-- <img src="/img/bg-report.jpg" width="100%" height="100%"> -->

          <!-- </section> -->
          <section class="pdf-item" style="padding:0px !important; margin: 10px 0px 0px 25px !important; background:#fff!important">
              <table style="border: 3px #ddd dashed; padding:7px; font-size:16px; color:#000; margin:0px 0px 0px 0px;">
                    <tr>
                        <td width="100px" style="border: 1px #ddd solid">
                            <img v-if="formdata.foto" class="img-profile" v-bind:src="'/'+formdata.foto" width="132" height="145"/>
                            <img v-if="!formdata.foto" class="img-profile" src="/img/user.png" />
                        </td>
                        <td width="605" style="border: 1px #ddd solid;">
                            <table>
                                <tr>
                                    <td align="center">
                                        <img src="/img/trikaya-main-logo.png" alt="avatar" width="200" height="70">
                                        <!-- <h1 align="center" class="blue-head" style="font-size:28px; margin:10px 0px 0px 0px !important;"> TRIKAYA EDUCARE</h1> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td width="495" align="center" style="font-size:16px; border-top: 1px #ddd solid; " class="blue-head">
                                        <p style="margin:0px 0px 0px 60px !important; padding:0px;">Laporan Rekapan Kegiatan Anak</p>
                                        <!-- <p style="margin:0px 0px 0px 60px !important; padding:0px;">Penting Memperhatikan Tumbuh Kembang Anak</p> -->
                                        <p style="margin:0px 0px 0px 60px !important; padding:0px;">Periode ({{filterbulancaptionawal}} - {{filterbulancaptionakhir}})</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>  
                    <tr >
                        <td colspan="2" style="border: 1px #ddd solid; ">
                        <p class="text-primary"> <strong>BIODATA SISWA</strong></p>
                            <table >
                                <tr>
                                    <td width="150px">Nomor Registrasi</td>
                                    <td>: {{formdata.no_pendaftaran}} </td>
                                </tr>
                                <tr>
                                    <td width="180" class="blue">Nama Siswa</td>
                                    <td>: {{formdata.nama_lengkap}}</td>
                                </tr>
                                <tr>
                                    <td width="180" class="blue">Nama Panggilan</td>
                                    <td>: {{formdata.nama_panggilan}}</td>
                                </tr>
                                <tr>
                                    <td width="180" class="blue">Tempat Lahir</td>
                                    <td>: {{formdata.tempat_lahir}}</td>
                                </tr>
                                <tr>
                                    <td width="180" class="blue">Tgl Lahir</td>
                                    <td>: {{formdata.tgl_lahir}}</td>
                                </tr>
                                <tr>
                                    <td width="180" class="blue">Usia</td>
                                    <td>: {{formdata.usia}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="border: 1px #ddd solid">
                            <table style="font-size:12px;">
                                <tr>
                                    <td width="540">
                                        <p class="text-primary"> <strong>KETERANGAN NILAI</strong></p>
                                        <p>
                                        0. Stimulasi belum terlaksana<br/>
                                        <span v-for="(val, index) in listoutputkegiatan" :key="index">
                                          {{index+1}}. {{val.text}} <br/>
                                        </span>
                                        </p>
                                        <p><i>*)Informasi dan keterangan dapat dijelaskan oleh pihak <a href="https://trikaya.id">https://trikaya.id</a></i></p>
                                        
                                    </td>
                                    <td width="100" align="right">
                                        <qrcode :value="linkhome" :options="{ width: 200 }"></qrcode>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>      
            </table>
        </section>
        <div class="html2pdf__page-break"/>
        <section class="pdf-item" style="padding:0px !important; margin: 10px 0px 0px 25px !important; background:#fff!important">
          <table style="border: 3px #ddd dashed; padding:7px; font-size:16px; color:#000; margin:0px 0px 0px 0px;">
                  <tr>
                        <td width="740" style="border: 1px #ddd solid">
                        <p class="text-primary"> <strong>DATA KEGIATAN</strong></p>
                            <table class="table table-border table-striped">
                                <tr>
                                    <th>No.</th>
                                    <th>Tgl. Kegiatan</th>
                                    <th>Aspek</th>
                                    <th>Kegiatan</th>
                                    <th>Indikator</th>
                                    <th>Output</th>
                                    <th>Nilai</th>
                                </tr>
                                <tr v-for="(inputdata,index) in kegiatan" :key="'kegiatan'+index" class="pdf-item">
                                    <td class="pdf-item">{{index+1}}</td>
                                    <td class="pdf-item">{{inputdata.tgl_kegiatan}}</td>
                                    <td class="pdf-item">{{inputdata.nama_aspek}}</td>
                                    <td class="pdf-item">{{inputdata.nama_kegiatan}}</td>
                                    <td class="pdf-item">{{inputdata.nama_indikator}}</td>
                                    <td class="pdf-item">{{inputdata.nilai_kualitatif}}</td>
                                    <td class="pdf-item">{{inputdata.nilai_angka}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
            </table>
          </section>
          <section class="pdf-item" style="padding:0px !important; margin: 10px 0px 0px 25px !important; background:#fff!important">
              <table style="border: 3px #ddd dashed; padding:7px; font-size:16px; color:#000; margin:0px 0px 0px 0px;">
                    <tr>
                        <td width="740" style="border: 1px #ddd solid">
                        <p class="text-primary"> <strong>DATA REKAPAN</strong></p>
                            <table class="table table-border table-striped">
                                <tr>
                                    <th>No.</th>
                                    <th>Tahun-Bulan</th>
                                    <th>Catatan</th>
                                    <th>Aspek</th>
                                    <th>Narasi</th>
                                </tr>
                                <tr v-for="(inputdata,index) in rekapan" :key="'rekapan'+index" class="pdf-item">
                                    <td class="pdf-item">{{index+1}}</td>
                                    <td class="pdf-item">{{inputdata.bulan_mr}}</td>
                                    <td class="pdf-item">{{inputdata.catatan}}</td>
                                    <td class="pdf-item">{{inputdata.aspek}}</td>
                                    <td class="pdf-item">{{inputdata.narasi}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
          </table>
        </section>
        <div class="html2pdf__page-break"/>
        <section class="pdf-item" style="padding:0px !important; margin: 10px 0px 0px 25px !important; background:#fff!important">
            <table style="border: 3px #ddd dashed; padding:7px; font-size:16px; color:#000; margin:0px 0px 0px 0px;">
            <tr>
            <td>
            <div class="row">
                              <div class="col-md-6 card">
                                 <h6 align="center"> Kuantitas Kegiatan </h6>
                                 <Pie
                                  :chart-options="chartOptionsPie"
                                  :chart-data="chartDataPie"
                                  :chart-id="chartIdPie"
                                  :dataset-id-key="datasetIdKeyPie"
                                  :plugins="pluginsPie"
                                  :css-classes="cssClassesPie"
                                  :styles="stylesPie"
                                  :width="widthPie"
                                  :height="200"
                                /></Pie>
                               </div>
                               <div class="col-md-6 card">
                                 <h6 align="center"> Rata-Rata Nilai Kegiatan </h6>
                                  <Bar
                                :chart-options="chartOptionsBar"
                                :chart-data="chartDataBar"
                                :chart-id="chartIdBar"
                                :dataset-id-key="datasetIdKeyBar"
                                :plugins="pluginsBar"
                                :css-classes="cssClassesBar"
                                :styles="stylesBar"
                                :width="widthBar"
                                :height="250"
                                /></Bar>
                               </div>
                               <div class="col-md-6 card">
                                 <h6 align="center"> Perkembangan Rata-Rata Nilai Kegiatan </h6>
                                    <LineChartGenerator
                                  :chart-options="chartOptionsLine"
                                  :chart-data="chartDataLine"
                                  :chart-id="chartIdLine"
                                  :dataset-id-key="datasetIdKeyLine"
                                  :plugins="pluginsLine"
                                  :css-classes="cssClassesLine"
                                  :styles="stylesLine"
                                  :width="widthLine"
                                  :height="350"
                                /></LineChartGenerator>
                             </div>
                             <div class="col-md-6 card">
                                 <h6 align="center"> Pemeriksaan Kesehatan </h6>
                                    <LineChartGenerator
                                  :chart-options="chartOptionsLine2"
                                  :chart-data="chartDataLine2"
                                  :chart-id="chartIdLine2"
                                  :dataset-id-key="datasetIdKeyLine2"
                                  :plugins="pluginsLine2"
                                  :css-classes="cssClassesLine2"
                                  :styles="stylesLine2"
                                  :width="widthLine2"
                                  :height="350"
                                /></LineChartGenerator>
                             </div>
                            </div>
              </td>
              </tr>
          </table>
        </section>
        </section>
    </VueHtml2pdf>
	</section>


</template>

<script>
import { Bar,Pie,Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement,PointElement,LineElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement,PointElement,LineElement)

import DatePicker from 'vue2-datepicker';
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';

export default {
  name: 'BarChart',
  components: { Bar,Pie,LineChartGenerator,DatePicker,VueHtml2pdf, },
  props: {
    chartIdBar: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKeyBar: {
      type: String,
      default: 'label'
    },
    widthBar: {
      type: Number,
      default: 400
    },
    heightBar: {
      type: Number,
      default: 400
    },
    cssClassesBar: {
      default: '',
      type: String
    },
    stylesBar: {
      type: Object,
      default: () => {}
    },
    pluginsBar: {
      type: Array,
      default: () => {}
    },
    chartIdPie: {
      type: String,
      default: 'pie-chart'
    },
    datasetIdKeyPie: {
      type: String,
      default: 'label'
    },
    widthPie: {
      type: Number,
      default: 400
    },
    heightPie: {
      type: Number,
      default: 400
    },
    cssClassesPie: {
      default: '',
      type: String
    },
    stylesPie: {
      type: Object,
      default: () => {}
    },
    pluginsPie: {
      type: Array,
      default: () => {}
    },
    chartIdLine: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKeyLine: {
      type: String,
      default: 'label'
    },
    widthLine: {
      type: Number,
      default: 400
    },
    heightLine: {
      type: Number,
      default: 400
    },
    cssClassesLine: {
      default: '',
      type: String
    },
    stylesLine: {
      type: Object,
      default: () => {}
    },
    pluginsLine: {
      type: Array,
      default: () => {}
    },
    chartIdLine2: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKeyLine2: {
      type: String,
      default: 'label'
    },
    widthLine2: {
      type: Number,
      default: 400
    },
    heightLine2: {
      type: Number,
      default: 400
    },
    cssClassesLine2: {
      default: '',
      type: String
    },
    stylesLine2: {
      type: Object,
      default: () => {}
    },
    pluginsLine2: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      link:'/detail/'+this.$route.params.id,
      linkhome: window.location.origin +'/report/'+this.$route.params.id,
      filterbulan: [],
      filterbulancaptionawal: '',
      filterbulancaptionakhir: '',
      listoutputkegiatan:{},
      kegiatan: {},
      rekapan: {},
      showTimePanel: false,
      showTimeRangePanel: false,
      formdata: {
        id:'',
        nama_lengkap:'',
        nama_panggilan:'',
        tgl_lahir:'',
        tempat_lahir:'',
        no_pendaftaran:'',
        tgl_pendaftaran:'',
        usia:'',
        foto:'',
      },
      chartDataBar: {
        labels: [],
        datasets: []
      },
      chartOptionsBar: {
        responsive: true,
        maintainAspectRatio: false,
        plugins:{
          legend:{
            display:false
          },
        }
      },
      chartDataPie: {
        labels: [],
        datasets: []
      },
      chartOptionsPie: {
        responsive: true,
        maintainAspectRatio: false
      },
      chartDataLine: {
        labels: [],
        datasets: []
      },
      chartOptionsLine: {
        responsive: true,
        maintainAspectRatio: false
      },
      chartDataLine2: {
        labels: [],
        datasets: []
      },
      chartOptionsLine2: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  methods: {
      generateReport(){
              this.$refs.html2Pdf.generatePdf();
      },
      toggleTimePanel() {
        this.showTimePanel = !this.showTimePanel;
      },
      toggleTimeRangePanel() {
        this.showTimeRangePanel = !this.showTimeRangePanel;
      },
      handleOpenChange() {
        this.showTimePanel = false;
      },
      handleRangeClose() {
        this.showTimeRangePanel = false;
      },
      loadNilai(){
        axios.get("/api/report/nilai-output-kegiatan").then(({ data }) => (this.listoutputkegiatan = data.data));
      },
      getKegiatan(id){
        axios.get("/api/report/kegiatan/"+id,{params:{filter:this.filterbulan}}).then(({ data }) => {
                this.kegiatan = data.data; 
                // console.log(this.kegiatan);
                setTimeout(() => {

                }, 50);
            })
        .catch((data)=>{
              setTimeout(() => {                      
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });  
              }, 300);
        });
      },
      getRekapan(id){
        axios.get("/api/report/rekapan/"+id,{params:{filter:this.filterbulan}}).then(({ data }) => {
                this.rekapan = data.data; 
                setTimeout(() => {

                }, 50);
            })
        .catch((data)=>{
              setTimeout(() => {                      
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });  
              }, 300);
        });
      },
      setDataSiswa(id){
        axios.get("/api/report/siswa/"+id,{params:{filter:this.filterbulan}}).then(({ data }) => {
                this.formdata = data.data; 
                setTimeout(() => {

                }, 50);
            })
        .catch((data)=>{
              setTimeout(() => {                      
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });  
              }, 300);
        });
      },
      setBarChart(id){
        axios.get("/api/report/barchart/"+id,{params:{filter:this.filterbulan}}).then(({ data }) => {
                let respons = JSON.parse(data.data);
                this.chartDataBar = respons;
                // console.log(this.chartDataBar);
            })
        .catch((data)=>{
              setTimeout(() => {                      
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again bar'
                  });  
              }, 300);
        });
      },
      setPieChart(id){
        axios.get("/api/report/piechart/"+id,{params:{filter:this.filterbulan}}).then(({ data }) => {
                let respons = JSON.parse(data.data);
                this.chartDataPie = respons;
                // console.log(this.chartDataPie);
            })
        .catch((data)=>{
              setTimeout(() => {                      
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again bar'
                  });  
              }, 300);
        });
      },
      setLineChartKesehatan(id){
        axios.get("/api/report/linechart-kesehatan/"+id,{params:{filter:this.filterbulan}}).then(({ data }) => {
                let respons = JSON.parse(data.data);
                this.chartDataLine2 = respons;
            })
        .catch((data)=>{
              setTimeout(() => {                      
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again bar'
                  });  
              }, 300);
        });
      },
      setLineChartAspek(id){
        axios.get("/api/report/linechart-aspek/"+id,{params:{filter:this.filterbulan}}).then(({ data }) => {
                let respons = JSON.parse(data.data);
                this.chartDataLine = respons;
            })
        .catch((data)=>{
              setTimeout(() => {                      
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again bar'
                  });  
              }, 300);
        });
      },
      async filtermonth(val){
        // console.log(val);
        await this.setDataSiswa(this.$route.params.id);
        await this.setBarChart(this.$route.params.id);
        await this.setPieChart(this.$route.params.id);
        await this.setLineChartKesehatan(this.$route.params.id);
        await this.setLineChartAspek(this.$route.params.id);
        
        this.filterbulancaptionawal = moment(val[0]+'-01').lang("id").format("MMM YYYY");
        this.filterbulancaptionakhir = moment(val[1]+'-31').lang("id").format("MMM YYYY");

      },
      getMonth: function() {
                const today = new Date();
                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date +' '+ time;
                this.filterbulancaptionawal = moment(date).subtract(3, 'months').lang("id").format("MMM YYYY");
                this.filterbulancaptionakhir = moment(date).lang("id").format("MMM YYYY");
            }
  },
  async created() { 
    await this.setDataSiswa(this.$route.params.id);
    await this.getKegiatan(this.$route.params.id);
    await this.getRekapan(this.$route.params.id);
    await this.setBarChart(this.$route.params.id);
    await this.setPieChart(this.$route.params.id);
    await this.setLineChartKesehatan(this.$route.params.id);
    await this.setLineChartAspek(this.$route.params.id);
    await this.getMonth();
    await this.loadNilai();
  },
  mounted() { 
    // console.log(this.chartDataBar);
  },
}
</script>