<template>
  <li class="nav-item" :class="isTree">
    <router-link :to="link" class="nav-link">
      <i class="nav-icon" :class="icon"></i>
      <p>
        {{ label }}
        <i v-show="isTree" class="right fas fa-angle-left"></i>
      </p>
    </router-link>
    <ul class="nav nav-treeview" v-if="node">
      <node v-for="child in node" :node="child.children" :key="child.label" :label="child.label" :icon="child.icon" :link="child.link"></node>
    </ul>
  </li>
</template>

<script>
export default {
  name: "node",
  props: {
    node: Object,
    label:'',
    icon:'',
    link:'',
  },
  computed: {
    // a computed getter
    isTree: function () {
      // `this` points to the vm instance
      if(this.node){
        return 'has-treeview'
      }else{
        return ''
      }
    }
  },
  mounted(){
    // console.log(this.node);
  }
};
</script>