<template>
<div class="col-md-12">
    <!-- <p>Catatan Khusus</p> -->
    <!-- <span v-for="data in formdata" :key="data.id_siswa">
        {{data.id_siswa}} <br/>
    </span> -->
    <div class="col-md-12">
        <div class="row" >
            <div class="form-group col-md-4">
                <label>Awal Laporan</label>
                <date-picker v-model="form.awal_mr" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                <has-error :form="form" field="awal_mr"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Akhir Laporan</label>
                <date-picker v-model="form.akhir_mr" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                <has-error :form="form" field="akhir_mr"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Bulan Laporan</label>
                <date-picker v-model="form.bulan_mr" type="month" valueType="format" input-class="form-control" style="width: 100%;"></date-picker>
                <has-error :form="form" field="bulan_mr"></has-error>
            </div>
            <div class="form-group col-md-12">
                <label>Catatan</label>
                  <textarea v-model="form.catatan" type="text" name="catatan"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('catatan') }" rows="2" placeholder="Contoh : Mengulang Proses" >
                  </textarea>
                  <has-error :form="form" field="catatan"></has-error>
            </div>

        </div>
    </div>

    <div v-for="(inputdata, index) in formlaporan" :key="index">
        <div class="card mb-4 py-3 border-left-primary">
          <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Data Laporan Aspek Perkembangan {{index+1}}</h6>
          </div>
          <div class="card-body">  
            <div class="row">
                  <div class="form-group col-md-12">
                      <label>Aspek </label>
                        <Select2 name="id_aspek[]" v-model="form.id_aspek[index]" :options="listaspek" @change="myChangeEvent($event)" @select="mySelectEvent($event)" /></Select2>
                        <has-error :form="form" field="id_aspek"></has-error>
                  </div>
                  <div class="form-group col-md-12">
                      <label>Narasi</label>
                        <textarea v-model="form.narasi[index]" type="text" name="narasi"
                                      class="form-control" :class="{ 'is-invalid': form.errors.has('narasi') }" rows="2" placeholder="Contoh : Mengulang Proses" >
                        </textarea>
                        <has-error :form="form" field="narasi"></has-error>
                  </div>
                  <div class="col-md-12 " align="left">
                      <button class="btn btn-danger" @click.prevent="deleteRow(index,inputdata)">Hapus Laporan {{index+1}}</button>
                  </div>
            </div>
          </div>
      </div>
    </div>

    <div class="col-md-12">
        <div class="row" >
              <div class="col-md-3">
                  <button class="btn btn-primary" @click.prevent="addRow">Tambah Laporan</button>
              </div>
              <div class="col-md-6">
                  &nbsp;
              </div>
              <div class="col-md-3" align="right">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" class="btn btn-success" @click.prevent="Save()">Simpan</button>
              </div>
          </div>
    </div>
</div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import Select2 from '../../../components/Select2.vue';

    export default {
        components: {
          DatePicker,
          Select2,
        },
        name:'laporanbulanan',
        props: ['SiswaId','DateKegiatan'],
        async mounted() {
            // console.log('Component mounted.'+this.SiswaId)
            this.$Progress.start();
            await this.loadData()
            await this.loadAspek()
            this.user = JSON.parse(localStorage.getItem('user'))
            this.$Progress.finish();
        },
        data() {
            return {
              user : '',
              formlaporan : [0],
              formdata : {},
              listaspek : [],
              form: new Form({
                    id : '',
                    id_siswa : '',
                    id_guru : '',
                    bulan_mr: '',
                    tgl_mr:'',
                    awal_mr: '',
                    akhir_mr: '',
                    catatan: '',
                    id_aspek: [],
                    narasi: [],
                    type:'laporanbulanan',
              }),
            };
        },
        methods: {
          loadData(){
            axios.get("api/kegiatan-siswa/"+this.SiswaId,{params:{type:'laporanbulanan',date:this.DateKegiatan}}).then(( data ) => {
              this.formdata = data.data.data[0]

              if(data.data.data.length > 0){
                  this.form.id = this.formdata.id;
                  this.form.id_guru = this.user.id;
                  this.form.bulan_mr = this.formdata.bulan_mr;
                  this.form.tgl_mr = this.formdata.tgl_mr;
                  this.form.awal_mr = this.formdata.awal_mr;
                  this.form.akhir_mr = this.formdata.akhir_mr;
                  this.form.catatan = this.formdata.catatan;

                  axios.get("api/kegiatan-siswa/detail-bulanan/"+this.form.id).then(( detail ) => {
                      this.formlaporan = detail.data.data;
                      if(this.formlaporan.length < 1){
                          this.formlaporan.push([]);
                      }else{
                          for(let index in this.formlaporan){
                              this.form.id_aspek[index] = this.formlaporan[index].id_aspek
                              this.form.narasi[index] = this.formlaporan[index].narasi
                          }
                      }
                  });
              }

              this.form.id_siswa = this.SiswaId
              this.form.tgl_catatan_khusus = this.DateKegiatan

            })
            .catch((data)=>{
                  setTimeout(() => {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                  }, 300);
              });
          },
          loadAspek(){
            axios.get("api/list/aspek").then(({ data }) => (this.listaspek = data.data));
          },
          Save(){
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            let id = this.form.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.form.id_guru = this.user.id
            this.form.put('api/kegiatan-siswa/'+id)
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        $('#pageloader').css('display','none');
                        this.$Progress.finish();
                        this.form.id = data.data.data.id;
                        // this.$router.push('/siswa')
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        this.$Progress.failed();
                        return false;
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          },
          addRow() {
            this.formlaporan.push([]);
          },
          deleteRow(index,val) {
            if(this.formlaporan.length > 1){
              this.formlaporan.splice(index,1);
            }else{
              this.deleteFailed(index,val);
            }
          },
          deleteFailed(index,val) {
            Toast.fire({
                icon: 'error',
                title: 'Gagal Hapus, Minimal 1 Kegiatan'
            });
          },
          mySelectEvent(event){

          },
          myChangeEvent(event){

          },
        }
    }
</script>
