<template>
<section class="content">
    <div class="error-page">
        <h2 class="headline text-warning"> 404</h2>
        <div class="error-content">
            <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>
            <p>
                We could not find the page you were looking for.
                Meanwhile, you may <a href="/home">return to dashboard</a> or try using the navigation bar.
            </p>
            <p>
                Maaf, Halaman ini tidak tersedia dalam list website, hubungi administrator website anda. <br/>
                Silahkan coba akses halaman ini kembali apabila anda telah menghubungi administrator. <a href="/home">kembali ke halaman utama</a>
            </p>
            <!-- <form class="search-form">
                <div class="input-group">
                    <input type="text" name="search" class="form-control" placeholder="Search">
                    <div class="input-group-append">
                        <button type="submit" name="submit" class="btn btn-warning"><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </form> -->
        </div>

    </div>

</section>

</template>

<script>
    export default {
        mounted() {
           
        }
    }
</script>