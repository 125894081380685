<template>
    <button class="btn btn-primary" @click="QRData">
        <slot><i class="fas fa-qrcode"></i></slot>
    </button>
</template>

<script>
    import { EventBus } from '../eventBus.js';

    export default{
        //extends: ExampleComponent,
        props: ['id', 'name', 'qrcode'],
        methods: {
            QRData() {
                // alert(`I am ${this.name}!`)
                EventBus.$emit('QRValue', this.id,this.name,this.qrcode) 
            }
        },
    }
</script>