<template>
    <table style="width:100% !important">
        <thead>
        <tr>
            <th v-for="column in parameters.columns" v-html="title(column)"></th>
        </tr>
        </thead>
        <tfoot v-if="footer">
        <tr>
            <th v-for="column in parameters.columns" v-html="column.footer"></th>
        </tr>
        </tfoot>
    </table>
</template>

<script>
    import { EventBus } from '../eventBus.js';
    window.$ = window.jQuery = require('jquery');
    // require('datatables.net');
    // require('datatables.net-bs4');
    // require('datatables.net-buttons');
    // require('datatables.net-buttons-bs4');

    // import "datatables.net-dt/js/dataTables.dataTables"
    // import "datatables.net-dt/css/jquery.dataTables.min.css"

    import "datatables.net-bs4/js/dataTables.bootstrap4"
    import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"

    export default{
        data() {
            return {
                dataTable: {},
            }
        },
        methods: {
            title(column) {
                return column.title || this.titleCase(column.data);
            },
            titleCase(str) {
                return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            }
        },
        computed: {
            parameters() {
                const vm = this;
                return window.$.extend({
                        serverSide: true,
                        processing: true,
                        lengthMenu:[ [10, 25, 50, -1], ["10", "25", "50", "All"] ],
                        info: true,
                        search: {
                            return: true
                        },
                        // "language": {
                        //     processing: '<div class="overlay"><div class="load-bar"><div class="bar"></div><div class="bar"></div><div class="bar"></div></div></div>'
                        // },
                   }, {
                   ajax: this.ajax,
                   columns: this.columns,
                   // createdRow(...args) {
                   //    vm.$emit('created-row', ...args);
                   // },
                   // drawCallback(...args) {
                   //    vm.$emit('draw', ...args);
                   // },
                   // footerCallback(...args) {
                   //    vm.$emit('footer', ...args);
                   // },
                   // formatNumber(...args) {
                   //    vm.$emit('format', ...args);
                   // },
                   // headerCallback(...args) {
                   //    vm.$emit('header', ...args);
                   // },
                   // infoCallback(...args) {
                   //    vm.$emit('info', ...args);
                   // },
                   // initComplete(...args) {
                   //     vm.$emit('init', ...args);
                   // },
                   // preDrawCallback(...args) {
                   //    vm.$emit('pre-draw', ...args);
                   // },
                   // rowCallback(...args) {
                   //    vm.$emit('draw-row', ...args);
                   // },
                   // stateLoadCallback(...args) {
                   //    vm.$emit('state-load', ...args);
                   // },
                   // stateLoaded(...args) {
                   //    vm.$emit('state-loaded', ...args);
                   // },
                   // stateLoadParams(...args) {
                   //    vm.$emit('state-load-params', ...args);
                   // },
                   // stateSaveCallback(...args) {
                   //    vm.$emit('state-save', ...args);
                   // },
                   // stateSaveParams(...args) {
                   //    vm.$emit('state-save-params', ...args);
                   // },
                  rowCallback: function( row, data, iDisplayIndex ) {
                     var api = this.api();
                     var info = api.page.info();
                     var page = info.page;
                     var length = info.length;
                     var index = (page * length + (iDisplayIndex +1));
                     $('td:eq(0)', row).html(index);
                  },
                  preDrawCallback:function(){
                    $('#cardloader').css('display','block');
                  },
                  drawCallback:function(){
                    $('#cardloader').css('display','none');
                  },
                }, this.options);
            }
        },
        props: {
            footer: { default: false },
            columns: { type: Array },
            ajax: { default: '' },
            options: { }
        },
        mounted() {
           this.$Progress.start();
           this.dataTable = window.$(this.$el).DataTable(this.parameters);
           this.$Progress.finish();
        },
        destroyed() {
            this.dataTable.destroy();
        },
        created(){
            EventBus.$on('dataTableReload', (id) => {
              // console.log('triggered ')
              this.dataTable.draw();
            })
        }
    }
</script>