<template>
  <section class="content">
    <div class="container-fluid">  
            <div class="card">
            <div class="overlay" id="cardloader"><div class="load-bar"><div class="bar"></div><div class="bar"></div><div class="bar"></div></div></div>
              <div class="card-header">
                <h3 class="card-title">List Data {{title}}</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>

                  <vue-excel-xlsx
                      :data="exportdata"
                      :columns="exportcolumn"
                      :file-name="'Export-Master-Data-Produk'"
                      :file-type="'xlsx'"
                      :sheet-name="'Sheet1'"
                      class="btn btn-sm btn-warning"
                      >
                      <i class="fa fa-file-excel"></i> Download
                  </vue-excel-xlsx>
                </div>
              </div>
              <!-- /.card-header -->
              <!-- <div class="card-body table-responsive p-0"> -->
              <div class="card-body">
                <data-table :columns="columns" class="display table table-bordered table-hover" :ajax="ajax" ></data-table>
              </div>
              <!-- /.card-body -->
            </div>
      </div>
            <!-- /.card -->
            <!-- Modal -->
        <div class="modal hide fade in" id="addNew" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New {{title}}</h5>
                    <h5 class="modal-title" v-show="editmode">Edit {{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateData() : createData()" class="form-horizontal">
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-md-2">Nama Produk</label>
                            <div class="col-md-10">
                              <input v-model="form.nama_produk" type="text" name="nama_produk"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('nama_produk') }">
                              <has-error :form="form" field="nama_produk"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2">Keterangan Produk</label>
                            <div class="col-md-10">
                              <input v-model="form.ket_produk" type="text" name="ket_produk"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('ket_produk') }">
                              <has-error :form="form" field="ket_produk"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2">Harga Pokok</label>
                            <div class="col-md-10">
                              <input v-model="form.harga_pokok" type="text" name="harga_pokok"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('harga_pokok') }">
                              <has-error :form="form" field="harga_pokok"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2">Harga Jual</label>
                            <div class="col-md-10">
                              <input v-model="form.harga_jual" type="text" name="harga_jual"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('harga_jual') }">
                              <has-error :form="form" field="harga_jual"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2">Potongan Produk</label>
                            <div class="col-md-10">
                              <input v-model="form.potongan_produk" type="text" name="potongan_produk"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('potongan_produk') }">
                              <has-error :form="form" field="potongan_produk"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2">Status</label>
                            <div class="col-md-10">
                              <Select2 v-model="form.status_produk" :options="liststatus" @change="myChangeEvent($event)" @select="mySelectEvent($event)" placeholder="Status Produk"/>
                              <has-error :form="form" field="status_produk"></has-error>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>

  </section>
</template>

<script>
    import DataTable from '../../../components/DataTable.vue';
    import Delete from '../../../components/DeleteButton.vue';
    import Edit from '../../../components/EditButton.vue';
    import { EventBus } from '../../../eventBus.js';
    import Select2 from '../../../components/Select2.vue';

    export default {
      components: {
          DataTable,
          Select2,
        },
        props:{
          title:'',
        },
        data () {
            return {
                editmode: false,
                user:'',
                loginType:'',
                formdata : {},
                form: new Form({
                    id : '',
                    nama_produk : '',
                    ket_produk: '',
                    harga_jual: '',
                    harga_pokok: '',
                    potongan_produk: '',
                    status_produk: '',
                }),              
                tag:  '',
                autocompleteItems: [],
                contents: 0,
                liststatus:[
                          {text: 'Aktif', id: 1},
                          {text: 'Tidak Aktif', id: 0}
                        ],
                columns: [
                    {title: 'No.',data: 'no', name: 'no',width:"2%"},
                    {title: 'Nama',data: 'name', name:'a.nama_produk'},
                    {title: 'Harga Pokok',data: 'harga_pokok', name:'a.harga_pokok'},
                    {title: 'Harga Jual',data: 'harga_jual', name:'a.harga_jual'},
                    {title: 'Potongan Produk',data: 'potongan_produk', name:'a.potongan_produk'},
                    {title: 'Status',data: 'status_produk', name:'a.status_produk'},
                    {
                        data: 'action',
                        orderable: false,
                        searchable: false,
                        createdCell(cell, cellData, rowData) {
                            let DeleteButton = Vue.extend(Delete);
                            let instance = new DeleteButton({
                                propsData: rowData
                            });
                            instance.$mount();

                            let EditButton = Vue.extend(Edit);
                            let instanceEdit = new EditButton({
                                propsData: rowData
                            });
                            instanceEdit.$mount();
                            $(cell).empty().append(instanceEdit.$el).append(" ").append(instance.$el);
                        }
                    },

                ],
                ajax: {
                  url : '/api/produk',
                  beforeSend: function (xhr){ 
                
                  },
                  error: data => {
                    // console.log(data);
                    if(data.status == 401){
                        this.$emit('logout')
                    }
                  },
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + localStorage.getItem('token')
                  },
                },
                exportcolumn:[
                          {label: '#',field: 'no', },
                          {label: 'Nama',field: 'name', },
                          {label: 'Harga Pokok',field: 'harga_pokok', },
                          {label: 'Harga Jual',field: 'harga_jual', },
                          {label: 'Potongan Produk',field: 'potongan_produk', },
                          {label: 'Status',field: 'status', },
                        ],
                exportdata:[],
            }
        },
        methods: {
          loadDataExport(){
            axios.get("/api/export/produk").then(({ data }) => {
                this.exportdata = data.data
              });
          },
          editModal(id){
              this.$Progress.start();  
              $('#pageloader').css('display','block');            
              this.editmode = true;
              axios.get("/api/produk/"+id).then(({ data }) => {
                  this.formdata = data.data
                  this.editmode = true
                  this.form.reset();
                  this.form.fill(this.formdata);
                  setTimeout(() => {         
                    $('#pageloader').css('display','none');               
                  }, 300);
                  $('#addNew').modal('show');
                  this.$Progress.finish();
              });

          },
          newModal(){
              this.editmode = false;
              $('#pageloader').css('display','block');
              this.form.reset();
              $('#addNew').modal('show');
              setTimeout(() => {                      
                $('#modalform').modal('show');
                $('#pageloader').css('display','none');    
            }, 300);
          },
          createData(){
              this.$Progress.start();              
              $('#pageloader').css('display','block');
              this.form.post('/api/produk')
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {                        
                        $('#addNew').modal('hide');
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        EventBus.$emit('dataTableReload', null) 
                        $('#pageloader').css('display','none');    
                        this.$Progress.finish();
                  }, 300);

                } else {
                  setTimeout(() => {                        
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');    
                        this.$Progress.failed();
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {                      
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');    
                  }, 300);
              })
          },
          updateData(){
              this.$Progress.start();             
              $('#pageloader').css('display','block'); 
              this.form.put('/api/produk/'+this.form.id)
              .then((response) => {
                  // success
                  setTimeout(() => {
                  $('#addNew').modal('hide');                      
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      EventBus.$emit('dataTableReload', null) 
                      $('#pageloader').css('display','none');   
                      this.$Progress.finish();
                  }, 300);
                      //  Fire.$emit('AfterCreate');
              })
              .catch((data) => {
                  setTimeout(() => {                      
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');   
                      this.$Progress.fail();
                  }, 300);
              });

          },
          deleteData(id,name){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this "+name+"!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                      // Send request to the server
                      this.$Progress.start();  
                      $('#pageloader').css('display','block');                     
                      if (result.value) {
                            this.form.delete('/api/produk/'+id).then(()=>{
                                setTimeout(() => {                                    
                                    Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                    );
                                    EventBus.$emit('dataTableReload', null) 
                                    $('#pageloader').css('display','none');   
                                    this.$Progress.finish();
                                }, 300);
                                // Fire.$emit('AfterCreate');
                            }).catch((data)=> {
                                setTimeout(() => {                                    
                                    Swal.fire("Failed!", data.message, "warning");
                                    $('#pageloader').css('display','none');   
                                    if(data.response.status == 401){
                                        this.$emit('logout')
                                    }
                                    this.$Progress.fail();
                                }, 300);
                            });
                      }else{
                          setTimeout(() => {                                
                                $('#pageloader').css('display','none');   
                                this.$Progress.fail();
                            }, 300);
                      }
                  })
          },
          myChangeEvent(event){

          },
          mySelectEvent(event){

          },

        },
        beforeMount() {
          // if(this.$gate.isAdmin()){            
          // }else{
            // this.$router.push({ path: '/dashboard' });
          // }
        },
        mounted() {
            EventBus.$on('deleteValue', (id,name) => {
              // console.log('triggered '+ id + ' contents ' + name)
              this.deleteData(id,name);
            })
            EventBus.$on('editValue', (id,name) => {
              // console.log('triggered '+ id + ' contents ' + name)
              this.editModal(id);
            })
        },
        async created() {
            this.$Progress.start();
            this.$emit('setAxios')            
            this.user = JSON.parse(localStorage.getItem('user'))
            this.loginType = this.user.roles[0].name
            await this.loadDataExport();
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          
        },
        beforeDestroy(){
          EventBus.$off('editValue', this.listener)
          EventBus.$off('deleteValue', this.listener)
        }
    }
</script>
