<template>
<div class="col-md-12">
    <!-- <span v-for="data in formdata" :key="data.id_siswa">
        {{data.id_siswa}} <br/>
    </span> -->
    <div class="col-md-12">
        <div class="row" >
            <div class="form-group col-md-4">
                <label>Obat 1 <small>(Nama)</small></label>
                  <input v-model="form.obat_nama1" type="text"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('obat_nama') }" placeholder="Contoh : Vitamin C" >
                  <has-error :form="form" field="obat_nama"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Obat 1 <small>(Waktu)</small></label>
                  <date-picker v-model="form.obat_waktu1" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="obat_waktu"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Obat 1 <small>(Dosis)</small></label>
                  <input v-model="form.obat_dosis1" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('obat_dosis') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="obat_dosis"></has-error>
            </div>
        </div>
        <div class="row" >
            <div class="form-group col-md-4">
                <label>Obat 2 <small>(Nama)</small></label>
                  <input v-model="form.obat_nama2" type="text"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('obat_nama') }" placeholder="Contoh : Vitamin C" >
                  <has-error :form="form" field="obat_nama"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Obat 2 <small>(Waktu)</small></label>
                  <date-picker v-model="form.obat_waktu2" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="obat_waktu"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Obat 2 <small>(Dosis)</small></label>
                  <input v-model="form.obat_dosis2" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('obat_dosis') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="obat_dosis"></has-error>
            </div>
        </div>
        <div class="row" >
            <div class="form-group col-md-4">
                <label>Obat 3 <small>(Nama)</small></label>
                  <input v-model="form.obat_nama3" type="text"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('obat_nama') }" placeholder="Contoh : Vitamin C" >
                  <has-error :form="form" field="obat_nama"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Obat 3 <small>(Waktu)</small></label>
                  <date-picker v-model="form.obat_waktu3" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="obat_waktu"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Obat 3 <small>(Dosis)</small></label>
                  <input v-model="form.obat_dosis3" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('obat_dosis') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="obat_dosis"></has-error>
            </div>
        </div>
        <div class="row" >
            <div class="form-group col-md-4">
                <label>Obat 4 <small>(Nama)</small></label>
                  <input v-model="form.obat_nama4" type="text"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('obat_nama') }" placeholder="Contoh : Vitamin C" >
                  <has-error :form="form" field="obat_nama"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Obat 4 <small>(Waktu)</small></label>
                  <date-picker v-model="form.obat_waktu4" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="obat_waktu"></has-error>
            </div>
            <div class="form-group col-md-4">
                <label>Obat 4 <small>(Dosis)</small></label>
                  <input v-model="form.obat_dosis4" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('obat_dosis') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="obat_dosis"></has-error>
            </div>
        </div>
    </div>
    <div class="col-md-12" align="right">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-success" @click.prevent="Save()">Simpan</button>
    </div>
</div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    export default {
        components: {
          DatePicker,
        },
        name:'obatvit',
        props: ['SiswaId','DateKegiatan'],
        async mounted() {
            // console.log('Component mounted.'+this.SiswaId)
            this.$Progress.start();
            await this.loadData()
            this.user = JSON.parse(localStorage.getItem('user'))
            this.$Progress.finish();
        },
        data() {
            return {
              user:'',
              formdata : {},
              form: new Form({
                    id : '',
                    id_siswa : '',
                    id_guru : '',
                    tgl_daily_obat_vit: '',
                    obat_nama1: '',
                    obat_waktu1: '',
                    obat_dosis1: '',
                    obat_nama2: '',
                    obat_waktu2: '',
                    obat_dosis2: '',
                    obat_nama3: '',
                    obat_waktu3: '',
                    obat_dosis3: '',
                    obat_nama4: '',
                    obat_waktu4: '',
                    obat_dosis4: '',
                    type:'obatvit',
              }),
            };
        },
        methods: {
          loadData(){
            axios.get("api/kegiatan-siswa/"+this.SiswaId,{params:{type:'obatvit',date:this.DateKegiatan}}).then(( data ) => {
              this.formdata = data.data.data[0]

              if(data.data.data.length > 0){
                  this.form.id = this.formdata.id;
                  this.form.obat_waktu1 = this.formdata.obat_waktu1;
                  this.form.obat_nama1 = this.formdata.obat_nama1;
                  this.form.obat_dosis1 = this.formdata.obat_dosis1;
                  this.form.obat_waktu2 = this.formdata.obat_waktu2;
                  this.form.obat_nama2 = this.formdata.obat_nama2;
                  this.form.obat_dosis2 = this.formdata.obat_dosis2;
                  this.form.obat_waktu3 = this.formdata.obat_waktu3;
                  this.form.obat_nama3 = this.formdata.obat_nama3;
                  this.form.obat_dosis3 = this.formdata.obat_dosis3;
                  this.form.obat_waktu4 = this.formdata.obat_waktu4;
                  this.form.obat_nama4 = this.formdata.obat_nama4;
                  this.form.obat_dosis4 = this.formdata.obat_dosis4;
              }

              this.form.id_siswa = this.SiswaId
              this.form.tgl_daily_obat_vit = this.DateKegiatan
            })
            .catch((data)=>{
                  setTimeout(() => {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                  }, 300);
              });
          },
          Save(){
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            let id = this.form.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.form.put('api/kegiatan-siswa/'+id)
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        $('#pageloader').css('display','none');
                        this.$Progress.finish();
                        this.form.id = data.data.data.id;
                        // this.$router.push('/siswa')
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        this.$Progress.failed();
                        return false;
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          }
        }
    }
</script>
