<template>
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" v-if="treeData">
      <node-tree v-for="tree in treeData" :node="tree.children" :label="tree.label" :icon="tree.icon" :link="tree.link" :key="tree.label"></node-tree>
    </ul>
</template>

<script>
import NodeTree from "./NavigationChild";

export default {
  props: {
    treeData: Object
  },
  components: {
    NodeTree
  },
  mounted(){
    // console.log(this.treeData)
  }
};
</script>

<style>
.tree-list ul {
  padding-left: 16px;
  margin: 6px 0;
}
</style>