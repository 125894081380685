<template>
    <section class="content">
      <div class="container-fluid">
        <div class="card">
            <div class="overlay" id="cardloader"><div class="load-bar"><div class="bar"></div><div class="bar"></div><div class="bar"></div></div></div>
            
            <div class="card-header">
              <h3 class="card-title">List Data {{title}}</h3>
              <div class="card-tools">
                <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                    <i class="fa fa-plus-square"></i>
                    Add New
                </button>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <data-table :columns="columns" class="display table table-bordered table-hover" :ajax="ajax" ></data-table>
            </div>
        </div>

      </div>
      <!-- Modal -->
        <div class="modal fade" id="modalform" role="dialog" aria-labelledby="modalform" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create {{title}}</h5>
                    <h5 class="modal-title" v-show="editmode">Edit {{title}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateData() : createData()" class="form-horizontal">
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-md-2">Nama </label>
                            <div class="col-md-10">
                              <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                              <has-error :form="form" field="name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2">Email</label>
                            <div class="col-md-10">
                              <input v-model="form.email" type="text" name="email"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('email') }" readonly="readonly" v-if="editmode">
                              <input v-model="form.email" type="text" name="email"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('email') }" v-if="!editmode">
                              <has-error :form="form" field="email"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2">Username</label>
                            <div class="col-md-10">
                              <input v-model="form.username" type="text" name="username"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('username') }" readonly="readonly" v-if="editmode">
                              <input v-model="form.username" type="text" name="username"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('username') }" v-if="!editmode">
                              <has-error :form="form" field="username"></has-error>
                            </div>
                        </div>
                        <div class="form-group row" v-if="editmode">
                            <label class="col-md-2">Ubah Password</label>
                            <div class="col-md-10">
                              <Select2 v-model="form.ubahpassword" :options="listubahpassword" @change="myChangeEvent($event)" @select="mySelectEvent($event)" placeholder="Ubah Password"/>
                              <has-error :form="form" field="ubahpassword"></has-error>
                            </div>
                        </div>
                        <div class="form-group row" v-if="(editmode && form.ubahpassword == 1) || (!editmode)">
                            <label class="col-md-2">Password</label>
                            <div class="col-md-10">
                              <input v-model="form.password" type="password" name="password"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('password') }">
                              <has-error :form="form" field="password"></has-error>
                            </div>
                        </div>
                        <div class="form-group row" v-if="(editmode && form.ubahpassword == 1) || (!editmode)">
                            <label class="col-md-2">Password Confirmation</label>
                            <div class="col-md-10">
                              <input v-model="form.password_confirmation" type="password" name="password_confirmation"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('password_confirmation') }">
                              <has-error :form="form" field="password_confirmation"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2">Jenis User</label>
                            <div class="col-md-10">
                              <Select2 v-model="form.jenisuser" :options="listjenisuser" @change="myChangeEvent($event)" @select="mySelectEvent($event)" placeholder="Jenis User"/>
                              <has-error :form="form" field="jenisuser"></has-error>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DataTable from '../../../components/DataTable.vue';
import Delete from '../../../components/DeleteButton.vue';
import Edit from '../../../components/EditButton.vue';
import { EventBus } from '../../../eventBus.js';
import Select2 from '../../../components/Select2.vue';

export default {
  components:{
    DataTable,
    Delete,
    Edit,
    Select2,
  },
  props:{
    title:'',
  },
  data() {
      return {
        editmode: false,
        user: '',
        loginType: '',
        search: '',
        formdata : {},
        listjenisuser:[
                          {text: 'User', id: 1},
                          {text: 'Guru', id: 2},
                          {text: 'Owner', id: 3}
                        ],
        listubahpassword:[
                          {text: 'Tidak Ubah Password', id: 0},
                          {text: 'Ubah Password', id: 1},
                        ],
        form: new Form({
            id : '',
            name : '',
            username: '',
            email: '',
            ubahpassword: '0',
            password: '',
            password_confirmation: '',
            jenisuser: '',
        }),
        columns: [
                    {title: 'No.',data: 'no', name: 'no',width:"2%"},
                    {title: 'Username',data: 'username', name:'a.username'},
                    {title: 'Nama',data: 'name', name:'a.name'},
                    {title: 'Email',data: 'email', name:'a.email'},
                    {title: 'Jenis User',data: 'jenisuser', name:'c.name'},
                    {
                        data: 'action',
                        orderable: false,
                        searchable: false,
                        width:"10%",
                        createdCell(cell, cellData, rowData) {
                            let DeleteButton = Vue.extend(Delete);
                            let instance = new DeleteButton({
                                propsData: rowData
                            });
                            instance.$mount();

                            let EditButton = Vue.extend(Edit);
                            let instanceEdit = new EditButton({
                                propsData: rowData
                            });
                            instanceEdit.$mount();
                            $(cell).empty().append(instanceEdit.$el).append(" ").append(instance.$el);
                        }
                    },

                ],
          ajax: {
            url : 'api/user',
            beforeSend: function (xhr){ 
                
            },
            error: data => {
              // console.log(data);
              if(data.status == 401){
                  this.$emit('logout')
              }
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
          },
      }
    },
    beforeMount(){
      if(localStorage.getItem('token') == null){
          this.$router.push('login')
      }
    },
    methods: {
        editModal(id){
            this.$Progress.start();        
            $('#pageloader').css('display','block');    
            this.editmode = true;
            axios.get("/api/user/"+id).then(({ data }) => {
                this.formdata = data.data
                this.editmode = true
                this.form.reset();
                this.form.fill(this.formdata);
                setTimeout(() => {                      
                }, 300);
                $('#modalform').modal('show');
                this.$Progress.finish();
                $('#pageloader').css('display','none');    
            })
            .catch((data)=>{
                  setTimeout(() => {                      
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');    
                  }, 300);
            })

        },
        newModal(){
            this.editmode = false;
            $('#pageloader').css('display','block');   
            this.form.reset();
            setTimeout(() => {                      
                $('#modalform').modal('show');
                $('#pageloader').css('display','none');    
            }, 300);
        },
        createData(){
              this.$Progress.start();     
              $('#pageloader').css('display','block');          
              this.form.post('/api/user')
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {                        
                        $('#modalform').modal('hide');
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message+" User Berhasil di Simpan"
                          });
                        $('#pageloader').css('display','none');    
                        EventBus.$emit('dataTableReload', null) 
                        this.$Progress.finish();
                  }, 300);

                } else {
                  setTimeout(() => {                        
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none'); 
                        this.$Progress.failed();
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {                      
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none'); 
                  }, 300);
              })
          },
          updateData(){
              this.$Progress.start();              
              $('#pageloader').css('display','block'); 
              this.form.put('/api/user/'+this.form.id)
              .then((response) => {
                  // success
                  setTimeout(() => {
                  $('#modalform').modal('hide');                      
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message+" User Berhasil di Simpan"
                      });
                      EventBus.$emit('dataTableReload', null) 
                      $('#pageloader').css('display','none'); 
                      this.$Progress.finish();
                  }, 300);
                      //  Fire.$emit('AfterCreate');
              })
              .catch((data) => {
                  setTimeout(() => {                      
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none'); 
                      this.$Progress.fail();
                  }, 300);
              });

          },
          deleteData(id,name){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this "+name+"!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                      // Send request to the server
                      this.$Progress.start();    
                      $('#pageloader').css('display','block');                   
                      if (result.value) {
                            this.form.delete('/api/user/'+id).then(()=>{
                                setTimeout(() => {                                    
                                    Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                    );
                                    EventBus.$emit('dataTableReload', null) 
                                    this.$Progress.finish();
                                }, 300);
                                // Fire.$emit('AfterCreate');
                            }).catch((data)=> {
                                setTimeout(() => {                                    
                                    Swal.fire("Failed!", data.message, "warning");
                                    $('#pageloader').css('display','none'); 
                                    if(data.response.status == 401){
                                        this.$emit('logout')
                                    }
                                    this.$Progress.fail();
                                }, 300);
                            });
                      }else{
                          setTimeout(() => {       
                                $('#pageloader').css('display','none');                          
                                this.$Progress.fail();
                            }, 300);
                      }
                  })
          },
    },
    mounted(){
      EventBus.$on('deleteValue', (id,name) => {
        // console.log('triggered '+ id + ' contents ' + name)
        this.deleteData(id,name);
      })
      EventBus.$on('editValue', (id,name) => {
        // console.log('triggered '+ id + ' contents ' + name)
        this.editModal(id);
        this.editmode = true;
      })
    },
    created() {    
      // console.log(this.navigation);
      this.$Progress.start();

      this.$emit('setAxios')
      this.user = JSON.parse(localStorage.getItem('user'))
      this.loginType = this.user.roles[0].name
      
      
      this.$Progress.finish();
    },
    beforeDestroy(){
      EventBus.$off('editValue', this.listener)
      EventBus.$off('deleteValue', this.listener)
    }
}
</script>
