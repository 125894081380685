<template>
  <div>
    <select class="form-control" :id="id" :name="name" :disabled="disabled" :required="required" style="width:100%"></select>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.min.css'

export default {
  name: 'Select2Ajax',
  data() {
    return {
      select2: null
    };
  },
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    ajaxurl: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => ({})
    },
    value: null,
    minimumInputLength: {
      type: Number,
      default: 3 // nilai default panjang input minimum
    }
  },
  watch: {
    options(val) {
      this.setOption(val);
    },
    value(val) {
      this.setValue(val);
    }
  },
  methods: {
    setOption(val = []) {
      if (this.select2) {
        this.select2.select2('destroy');
      }
      this.$nextTick(() => {
        this.select2 = $(this.$el)
          .find('select')
          .select2({
            placeholder: this.placeholder,
            ...this.settings,
            minimumInputLength: this.minimumInputLength,
            data: val,
            ajax: {
              transport: (params, success, failure) => {
                this.loadData(params.data).then(success).catch(failure);
              },
              processResults: (data) => {
                return {
                  results: data.results
                };
              },
              cache: true
            }
          })
          .on('select2:select select2:unselect', ev => {
            this.$emit('change', this.select2.val());
            this.$emit('select', ev.params.data);
          })
          .on('select2:closing', ev => {
            this.$emit('closing', ev);
          })
          .on('select2:close', ev => {
            this.$emit('close', ev);
          })
          .on('select2:opening', ev => {
            this.$emit('opening', ev);
          })
          .on('select2:open', ev => {
            this.$emit('open', ev);
          })
          .on('select2:clearing', ev => {
            this.$emit('clearing', ev);
          })
          .on('select2:clear', ev => {
            this.$emit('clear', ev);
          });
        this.setValue(this.value);
      });
    },
    setValue(val) {
      if (this.select2) {
        if (val instanceof Array) {
          this.select2.val([...val]);
        } else {
          this.select2.val([val]);
        }
        this.select2.trigger('change');
      }
    },
    loadData(params) {
      return axios.get(this.ajaxurl, {
        params: {
          q: params.term, // term pencarian
          ...params.additionalParams // parameter tambahan jika diperlukan
        }
      }).then(response => {
        return {
          results: response.data.data // menyesuaikan struktur data
        };
      }).catch(error => {
        console.error('Error loading data', error);
        return { results: [] };
      });
    }
  },
  mounted() {
    // Menginisialisasi Select2 dengan data awal dan pengaturan AJAX
    this.setOption(this.options);
  },
  beforeDestroy() {
    if (this.select2) {
      this.select2.select2('destroy');
    }
  }
};
</script>
