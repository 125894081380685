<template>
<div class="col-md-12">
    <p>Kegiatan</p>
    <data-table :columns="columns" class="display table table-bordered table-hover" :ajax="ajax" ></data-table>
</div>
</template>

<script>
    import DataTable from '../../components/DataTable.vue';
    import { EventBus } from '../../eventBus.js';
    export default {
        name:'kegiatan',
        components: {
          DataTable,
        },
        props: ['SiswaId','DateKegiatan','FilterMonth'],
        beforeMount() {

        },
        mounted() {
            // console.log('Component mounted.'+this.SiswaId)
        },
        created(){
            this.$Progress.start();
            // this.loadData()
            this.$Progress.finish();
        },
        data() {
            return {
              formkegiatan : [0],
              formdata : {},
              columns: [
                    {title: 'No.',data: 'no', name: 'no',width:"2%"},
                    {title: 'Tgl. Kegiatan',data: 'tgl_kegiatan', name:'a.tgl_kegiatan'},
                    {title: 'Aspek',data: 'nama_aspek', name:'d.nama_aspek'},
                    {title: 'Kegiatan',data: 'nama_kegiatan', name:'e.nama_kegiatan'},
                    {title: 'Indikator',data: 'nama_indikator', name:'c.nama_indikator'},
                    {title: 'Output',data: 'nilai_kualitatif', name:'f.nilai_kualitatif'},
                    {title: 'Nilai',data: 'nilai_angka', name:'f.nilai_angka'},
                    {title: 'Rencana Selanjutnya',data: 'rencana_selanjutnya', name:'a.rencana_selanjutnya'},
                    
                ],
                ajax: {
                  url : '/api/report/detail-kegiatan/'+this.SiswaId+'?type=kegiatan&filter='+this.FilterMonth,
                  beforeSend: function (xhr){ 
                
                  },
                  error: data => {
                    // console.log(data);
                    // if(data.status == 401){
                    //     this.$emit('logout')
                    // }
                  },
                  headers: {
                      'Content-Type': 'application/json',
                      // 'Authorization': 'Bearer ' + localStorage.getItem('token')
                  },
                },
            };
        },
        methods: {
          loadData(){
            // axios.get("/api/detail-siswa/"+this.SiswaId,{params:{type:'kegiatan',date:this.DateKegiatan}}).then(({ data }) => (this.formdata = data.data.data))
            // .catch((data)=>{
            //       setTimeout(() => {                      
            //           Toast.fire({
            //               icon: 'error',
            //               title: 'Some error occured! Please try again'
            //           });
            //           if(data.response.status == 401){
            //               this.$emit('logout')
            //           }
            //       }, 300);
            //   });
          },
        }
    }
</script>