<template>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <!-- Widget: user widget style 2 -->
            <div class="card card-widget widget-user">
              <!-- Add the bg color to the header using any of the bg-* classes -->
              <div class="widget-user-header bg-success">
                <!-- /.widget-user-image -->
                <h3 class="widget-user-username">{{formdata.nama_lengkap}}</h3>
                <h5 class="widget-user-desc">{{formdata.nama_panggilan}}</h5>
              </div>
              <div class="widget-user-image">
                  <img class="img-circle elevation-2" :src="'/'+formdata.foto" alt="User Avatar">
              </div>
              <div class="card-footer ">
                <div class="row">
                  
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">{{formdata.usia}}</h5>
                      <span class="description-text">USIA</span>
                    </div>
                  </div>

                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">{{formdata.tgl_pendaftaran}}</h5>
                      <span class="description-text">DAFTAR</span>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">{{formdata.tgl_jatuh_tempo}}</h5>
                      <span class="description-text">SELESAI</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- /.widget-user -->
            <div class="callout callout-success">
              <h5>Rekap Laporan Siswa Trikaya Educare Periode ({{filterbulancaptionawal}} - {{filterbulancaptionakhir}})</h5>
              <p>Laporan ini secara otomatis menampilkan kegiatan 3 bulan terakhir, Anda dapat Memfilter bulan yang sesuai anda inginkan dan Anda dapat melihat laporan detail perkembangan anak anda dengan klik tombol lihat laporan detail</p>
              <div class="row">
                  <div class="form-group col-md-6"> 
                      <label>Filter Bulan</label>
                      <date-picker
                        v-model="filterbulan"
                        type="month"
                        valueType="format"
                        placeholder="Pilih Bulan"
                        range
                        :show-time-panel="showTimeRangePanel"
                        @close="handleRangeClose"
                        @change="filtermonth($event)"
                      >
                        <!-- <template v-slot:footer>
                          <button class="mx-btn mx-btn-text" @click="toggleTimeRangePanel">
                            {{ showTimeRangePanel ? 'select date' : 'select time' }}
                          </button>
                        </template> -->
                      </date-picker>
                  </div>
                  <div class="form-group col-md-6" align="right">
                      <router-link :to="link" class="btn btn-success text-white" style="text-decoration:none;"> Kembali Lihat Laporan Rekap </router-link>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-6">

          <div class="small-box bg-info">
          <div class="inner">
          <h3>{{formdetail.total_kegiatan}}</h3>
          <p>Total Kegiatan</p>
          </div>
          <div class="icon">
          <i class="ion ion-bag"></i>
          </div>
          <!-- <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
          </div>
          </div>

          <div class="col-lg-3 col-6">

          <div class="small-box bg-success">
          <div class="inner">
          <h3>{{formdetail.rata_rata_nilai}}<!-- <sup style="font-size: 20px">%</sup> --></h3>
          <p>Rata-Rata Nilai</p>
          </div>
          <div class="icon">
          <i class="ion ion-stats-bars"></i>
          </div>
          <!-- <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
          </div>
          </div>

          <div class="col-lg-3 col-6">

          <div class="small-box bg-warning">
          <div class="inner">
          <h3>{{formdetail.total_kesehatan}}</h3>
          <p>Total Pemeriksaan Kesehatan</p>
          </div>
          <div class="icon">
          <i class="ion ion-person-add"></i>
          </div>
          <!-- <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
          </div>
          </div>

          <div class="col-lg-3 col-6">

          <div class="small-box bg-danger">
          <div class="inner">
          <h3>{{formdetail.total_catatan_khusus}}</h3>
          <p>Total Catatan Khusus</p>
          </div>
          <div class="icon">
          <i class="ion ion-pie-graph"></i>
          </div>
          <!-- <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
          </div>
          </div>

          <div class="col-md-12">
            <div class="col-12 col-sm-12">
                  <div class="card card-primary card-outline card-outline-tabs">
                    <div class="card-header p-0 border-bottom-0">
                      <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="custom-tabs-four-laporanbulanan-tab" data-toggle="pill" href="#custom-tabs-four-laporanbulanan" role="tab" aria-controls="custom-tabs-four-laporanbulanan" aria-selected="true" @click="tabPane(0)">Laporan Bulanan</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="custom-tabs-four-kegiatan-tab" data-toggle="pill" href="#custom-tabs-four-kegiatan" role="tab" aria-controls="custom-tabs-four-kegiatan" aria-selected="false" @click="tabPane(1)">Laporan Kegiatan</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="custom-tabs-four-makmin-tab" data-toggle="pill" href="#custom-tabs-four-makmin" role="tab" aria-controls="custom-tabs-four-makmin" aria-selected="false" @click="tabPane(2)">Makan & Minum</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="custom-tabs-four-susu-tab" data-toggle="pill" href="#custom-tabs-four-susu" role="tab" aria-controls="custom-tabs-four-susu" aria-selected="false" @click="tabPane(3)">Susu</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="custom-tabs-four-obatvit-tab" data-toggle="pill" href="#custom-tabs-four-obatvit" role="tab" aria-controls="custom-tabs-four-obatvit" aria-selected="false" @click="tabPane(4)">Obat/Vitamin</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="custom-tabs-four-catatankhusus-tab" data-toggle="pill" href="#custom-tabs-four-catatankhusus" role="tab" aria-controls="custom-tabs-four-catatankhusus" aria-selected="false" @click="tabPane(5)">Catatan Khusus</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="custom-tabs-four-pemeriksaankesehatan-tab" data-toggle="pill" href="#custom-tabs-four-pemeriksaankesehatan" role="tab" aria-controls="custom-tabs-four-pemeriksaankesehatan" aria-selected="false" @click="tabPane(6)">Pemeriksaan Kesehatan</a>
                        </li>
                      </ul>
                    </div>
                    <div class="card-body">
                      <div class="tab-content" id="custom-tabs-four-tabContent">
                        <div class="tab-pane fade active show" id="custom-tabs-four-laporanbulanan" role="tabpanel" aria-labelledby="custom-tabs-four-laporanbulanan-tab">
                           <laporanbulanan :SiswaId="SiswaId" v-if="showTab[0]" :DateKegiatan="DateKegiatan" :FilterMonth="filterbulan"></laporanbulanan>
                        </div>
                        <div class="tab-pane fade active show" id="custom-tabs-four-kegiatan" role="tabpanel" aria-labelledby="custom-tabs-four-kegiatan-tab">
                           <kegiatan :SiswaId="SiswaId" v-if="showTab[1]" :DateKegiatan="DateKegiatan" :FilterMonth="filterbulan"></kegiatan>
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-makmin" role="tabpanel" aria-labelledby="custom-tabs-four-makmin-tab">
                           <makmin :SiswaId="SiswaId" v-if="showTab[2]" :DateKegiatan="DateKegiatan" :FilterMonth="filterbulan"></makmin>
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-susu" role="tabpanel" aria-labelledby="custom-tabs-four-susu-tab">
                           <susu :SiswaId="SiswaId" v-if="showTab[3]" :DateKegiatan="DateKegiatan" :FilterMonth="filterbulan"></susu>
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-obatvit" role="tabpanel" aria-labelledby="custom-tabs-four-obatvit-tab">
                           <obatvit :SiswaId="SiswaId" v-if="showTab[4]" :DateKegiatan="DateKegiatan" :FilterMonth="filterbulan"></obatvit>
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-catatankhusus" role="tabpanel" aria-labelledby="custom-tabs-four-catatankhusus-tab">
                           <catatankhusus :SiswaId="SiswaId" v-if="showTab[5]" :DateKegiatan="DateKegiatan" :FilterMonth="filterbulan"></catatankhusus>
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-pemeriksaankesehatan" role="tabpanel" aria-labelledby="custom-tabs-four-pemeriksaankesehatan-tab">
                           <pemeriksaankesehatan :SiswaId="SiswaId" v-if="showTab[6]" :DateKegiatan="DateKegiatan" :FilterMonth="filterbulan"></pemeriksaankesehatan>
                        </div>
                      </div>
                    </div>
                    <!-- /.card -->
                  </div>
              </div>
          </div>

        </div> <!-- end of row -->

      </div>
    </section>
</template>

<script>
  import Kegiatan from './report/kegiatan.vue';
  import Makmin from './report/makmin.vue';
  import Susu from './report/susu.vue';
  import Obatvit from './report/obatvit.vue';
  import Catatankhusus from './report/catatankhusus.vue';
  import Pemeriksaankesehatan from './report/pemeriksaankesehatan.vue';
  import Laporanbulanan from './report/laporanbulanan.vue';
  import DatePicker from 'vue2-datepicker';
  import moment from 'moment';

export default {
  components:{
    Kegiatan,
    Makmin,
    Susu,
    Obatvit,
    Catatankhusus,
    Pemeriksaankesehatan,  
    Laporanbulanan,  
    DatePicker,
  },
  data() {
      return {
        link:'/report/'+this.$route.params.id,
        filterbulan: [],
        filterbulancaptionawal: '',
        filterbulancaptionakhir: '',
        showTimePanel: false,
        showTimeRangePanel: false,
        formdata: {
          nama_lengkap:'',
          nama_panggilan:'',
          tgl_pendaftaran:'',
          foto:'',
          usia:'',
        },
        formdetail: {
          total_kegiatan:'',
          rata_rata_nilai:'',
          total_kesehatan:'',
          total_catatan_khusus:'',
        },
        SiswaId:0,
        tabActive:0,
        showTab:[
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ],
        DateKegiatan:'',
        showLaporanBulanan: false,
        showKegiatan: false,
        showMakMin: false,
        showSusu: false,
        showKObatVit: false,
        showKCatatanKhusus: false,
        showKPemeriksaanKesehatan: false,
      }
    },
    props:{
      title:'',
    },
    beforeMount(){
      
    },
    methods: {
      toggleTimePanel() {
        this.showTimePanel = !this.showTimePanel;
      },
      toggleTimeRangePanel() {
        this.showTimeRangePanel = !this.showTimeRangePanel;
      },
      handleOpenChange() {
        this.showTimePanel = false;
      },
      handleRangeClose() {
        this.showTimeRangePanel = false;
      },
      setDataSiswa(id){
        this.SiswaId = id;
        axios.get("/api/report/siswa/"+id).then(({ data }) => {
                this.formdata = data.data; 
                setTimeout(() => {

                }, 50);
            })
        .catch((data)=>{
              setTimeout(() => {                      
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured Siswa! Please try again'
                  });  
              }, 300);
        });
      },
      setDetailSiswa(id){
        axios.get("/api/report/detail/"+id).then(({ data }) => {
                this.formdetail = JSON.parse(data.data); 
                setTimeout(() => {

                }, 50);
            })
        .catch((data)=>{
              setTimeout(() => {                      
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured Detail! Please try again'
                  });  
              }, 300);
        });
      },
      async filtermonth(val){
        // console.log(val);
        await this.setDataSiswa(this.$route.params.id);
        await this.setDetailSiswa(this.$route.params.id);
        this.showTab.forEach(this.falseTab);
        
        this.filterbulancaptionawal = moment(val[0]+'-01').lang("id").format("MMM YYYY");
        this.filterbulancaptionakhir = moment(val[1]+'-31').lang("id").format("MMM YYYY");

        setTimeout(() => {   
          this.$set(this.showTab, this.tabActive, true)
        }, 300);
      },
      tabPane(id){
        this.showTab.forEach(this.falseTab);
        // this.showKegiatan = !this.showKegiatan;
        // this.showTab[id] = !this.showTab[id]
        // console.log(this.showKegiatan);
        this.$set(this.showTab, id, true)
        this.tabActive = id;
        // console.log(this.showTab);
      },
      falseTab: function (item,index) {
        this.$set(this.showTab, index, false)
      },
      getMonth: function() {
          const today = new Date();
          const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
          const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
          const dateTime = date +' '+ time;
          this.filterbulancaptionawal = moment(date).subtract(3, 'months').lang("id").format("MMM YYYY");
          this.filterbulancaptionakhir = moment(date).lang("id").format("MMM YYYY");
      }
    },
    async created() {     
      await this.setDataSiswa(this.$route.params.id);
      await this.setDetailSiswa(this.$route.params.id);
      await this.getMonth();
      // console.log(this.SiswaId);
    }
}
</script>
