<template>
  <section class="content">
      <div class="container-fluid">
        <div class="col-md-12">
        </div>
        <div class="card">
          <div class="overlay" id="cardloader"><div class="load-bar"><div class="bar"></div><div class="bar"></div><div class="bar"></div></div></div>

            <div class="card-header" style="display:none">
              <h3 class="card-title">List Data {{title}}</h3>
              <div class="card-tools">
                      <form @submit.prevent="FindSearch">
                        <div class="input-group input-group-sm">
                            <input v-model="search" type="text" class="form-control" placeholder="Search..." id="search">
                            <span class="input-group-append">
                              <button type="submit" class="btn btn-info btn-flat">Go!</button>
                            </span>
                        </div>
                      </form>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body" style="display:none">
              <ul class="users-list clearfix" >
                <li v-for="data in formdata.data" :key="data.id">
                  <img v-bind:src="data.foto" alt="User Image" v-if="data.foto" height="128" width="128">
                  <img src="/img/user.jpg" alt="User Image" v-if="!data.foto" height="128" width="128">
                  <a class="users-list-name" href="#" @click="editModal(data)">{{data.nama_lengkap}}</a>
                  <span class="users-list-date">{{data.tgl_lahir}}</span>
                </li>
              </ul>
            </div>

            <div class="card-footer text-center" >
              <pagination :data="formdata" @pagination-change-page="getResults" :align="'center'" :limit="-1" :show-disabled="true">
                <template #prev-nav>
                    <span> Prev</span>
                </template>
                <template #next-nav>
                    <span>Next </span>
                </template>
              </pagination>
            </div>
        </div>

      </div>

      <!-- Modal -->
        <div class="modal modal-full fade" id="addNew" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog modal-dialog-full " role="document">
                <div class="modal-content modal-content-full">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Aktifitas</h5>
                    <h5 class="modal-title" v-show="editmode">Aktifitas Siswa</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form class="form-horizontal">
                    <div class="modal-body modal-body-full">
                        <div class="col-12 col-sm-12">
                          <div class="row">
                              <div class="col-md-3">
                                <img v-bind:src="form.foto" width="100px" v-if="form.foto" height="100px">
                                <img src="/img/user.jpg" width="100px" v-if="!form.foto" height="100px">
                              </div>
                              <div class="col-md-8">
                                <div class="row">
                                  <div class="col-md-4">No. Pendaftaran</div>
                                  <div class="col-md-8">{{form.no_pendaftaran}}</div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Nama</div>
                                  <div class="col-md-8">{{form.nama_lengkap}} ({{form.nama_panggilan}}) </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Tgl Lahir</div>
                                  <div class="col-md-8">{{form.tgl_lahir}} </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Tinggi Badan</div>
                                  <div class="col-md-8">{{form.tinggi_badan}} Cm</div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Berat Badan</div>
                                  <div class="col-md-8">{{form.berat_badan}} Kg</div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="form-group row">
                                <!-- <label class="col-md-2">Tgl Kegiatan</label> -->
                                <div class="offset-md-8 col-md-4">
                                  <!-- <input v-model="form.tgl_kegiatan" type="text" name="tgl_kegiatan"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('tgl_kegiatan') }" placeholder="Date..."> -->
                                  <date-picker v-model="form.tgl_kegiatan" valueType="format" input-class="form-control" style="width: 100%;" placeholder="Date..." @change="changedate($event)"></date-picker>
                                </div>
                            </div>
                         </div>
                        <div class="col-12 col-sm-12">
                          <div class="card card-primary card-outline card-outline-tabs">
                            <div class="card-header p-0 border-bottom-0">
                              <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                <li class="nav-item">
                                  <a class="nav-link active" id="custom-tabs-four-kegiatan-tab" data-toggle="pill" href="#custom-tabs-four-kegiatan" role="tab" aria-controls="custom-tabs-four-kegiatan" aria-selected="true" @click="tabPane(0)">Laporan Kegiatan</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" id="custom-tabs-four-makmin-tab" data-toggle="pill" href="#custom-tabs-four-makmin" role="tab" aria-controls="custom-tabs-four-makmin" aria-selected="false" @click="tabPane(1)">Makan & Minum</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" id="custom-tabs-four-susu-tab" data-toggle="pill" href="#custom-tabs-four-susu" role="tab" aria-controls="custom-tabs-four-susu" aria-selected="false" @click="tabPane(2)">Susu</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" id="custom-tabs-four-obatvit-tab" data-toggle="pill" href="#custom-tabs-four-obatvit" role="tab" aria-controls="custom-tabs-four-obatvit" aria-selected="false" @click="tabPane(3)">Obat/Vitamin</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" id="custom-tabs-four-catatankhusus-tab" data-toggle="pill" href="#custom-tabs-four-catatankhusus" role="tab" aria-controls="custom-tabs-four-catatankhusus" aria-selected="false" @click="tabPane(4)">Catatan Khusus</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" id="custom-tabs-four-pemeriksaankesehatan-tab" data-toggle="pill" href="#custom-tabs-four-pemeriksaankesehatan" role="tab" aria-controls="custom-tabs-four-pemeriksaankesehatan" aria-selected="false" @click="tabPane(5)">Pemeriksaan Kesehatan</a>
                                </li>
                                <li class="nav-item">
                                  <a class="nav-link" id="custom-tabs-four-laporanbulanan-tab" data-toggle="pill" href="#custom-tabs-four-laporanbulanan" role="tab" aria-controls="custom-tabs-four-laporanbulanan" aria-selected="false" @click="tabPane(6)">Laporan Bulanan</a>
                                </li>
                              </ul>
                            </div>
                            <div class="card-body">
                              <div class="tab-content" id="custom-tabs-four-tabContent">
                                <div class="tab-pane fade active show" id="custom-tabs-four-kegiatan" role="tabpanel" aria-labelledby="custom-tabs-four-kegiatan-tab">
                                   <kegiatan :SiswaId="SiswaId" v-if="showTab[0]" :DateKegiatan="DateKegiatan"></kegiatan>
                                </div>
                                <div class="tab-pane fade" id="custom-tabs-four-makmin" role="tabpanel" aria-labelledby="custom-tabs-four-makmin-tab">
                                   <makmin :SiswaId="SiswaId" v-if="showTab[1]" :DateKegiatan="DateKegiatan"></makmin>
                                </div>
                                <div class="tab-pane fade" id="custom-tabs-four-susu" role="tabpanel" aria-labelledby="custom-tabs-four-susu-tab">
                                   <susu :SiswaId="SiswaId" v-if="showTab[2]" :DateKegiatan="DateKegiatan"></susu>
                                </div>
                                <div class="tab-pane fade" id="custom-tabs-four-obatvit" role="tabpanel" aria-labelledby="custom-tabs-four-obatvit-tab">
                                   <obatvit :SiswaId="SiswaId" v-if="showTab[3]" :DateKegiatan="DateKegiatan"></obatvit>
                                </div>
                                <div class="tab-pane fade" id="custom-tabs-four-catatankhusus" role="tabpanel" aria-labelledby="custom-tabs-four-catatankhusus-tab">
                                   <catatankhusus :SiswaId="SiswaId" v-if="showTab[4]" :DateKegiatan="DateKegiatan"></catatankhusus>
                                </div>
                                <div class="tab-pane fade" id="custom-tabs-four-pemeriksaankesehatan" role="tabpanel" aria-labelledby="custom-tabs-four-pemeriksaankesehatan-tab">
                                   <pemeriksaankesehatan :SiswaId="SiswaId" v-if="showTab[5]" :DateKegiatan="DateKegiatan"></pemeriksaankesehatan>
                                </div>
                                <div class="tab-pane fade" id="custom-tabs-four-laporanbulanan" role="tabpanel" aria-labelledby="custom-tabs-four-laporanbulanan-tab">
                                   <laporanbulanan :SiswaId="SiswaId" v-if="showTab[6]" :DateKegiatan="DateKegiatan"></laporanbulanan>
                                </div>
                              </div>
                            </div>
                            <!-- /.card -->
                          </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                        <!-- <button v-show="editmode" type="submit" class="btn btn-success">Update</button> -->
                        <!-- <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button> -->
                    </div>
                  </form>
                </div>
            </div>
        </div>



  </section>
</template>

<script>
    import DataTable from '../../../components/DataTable.vue';
    import Delete from '../../../components/DeleteButton.vue';
    import Edit from '../../../components/EditButton.vue';
    import Kegiatan from './kegiatan.vue';
    import Makmin from './makmin.vue';
    import Susu from './susu.vue';
    import Obatvit from './obatvit.vue';
    import Catatankhusus from './catatankhusus.vue';
    import Laporanbulanan from './laporanbulanan.vue';
    import Pemeriksaankesehatan from './pemeriksaankesehatan.vue';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import { EventBus } from '../../../eventBus.js';
    import moment from 'moment';

    export default {
      components: {
          DataTable,
          Kegiatan,
          Makmin,
          Susu,
          Obatvit,
          Catatankhusus,
          Pemeriksaankesehatan,
          Laporanbulanan,
          DatePicker,
        },
        props:{
          title:'',
        },
        data () {
            return {
                editmode: false,
                isActiveLoading:false,
                user:'',
                loginType:'',
                SiswaId:0,
                DateKegiatan:'',
                showKegiatan: false,
                showMakMin: false,
                showSusu: false,
                showKObatVit: false,
                showKCatatanKhusus: false,
                showKPemeriksaanKesehatan: false,
                showKLaporanBulanan: false,
                search:'',
                tabActive:0,
                showTab:[
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                        ],
                formdata : {},
                form: new Form({
                    id : '',
                    foto:'',
                    no_pendaftaran:'',
                    nama_lengkap:'',
                    nama_panggilan:'',
                    tgl_lahir:'',
                    tgl_kegiatan:'',
                    tinggi_badan:'',
                    berat_badan:'',
                }),
                tag:  '',
                autocompleteItems: [],
                contents: 0,
            }
        },
        methods: {
          loadData(){
            $('#cardloader').css('display','block');
            axios.get("api/siswa").then(({ data }) => {
                    this.formdata = data.data;
                    setTimeout(() => {
                      $('#cardloader').css('display','none');
                      $('.card-header').css('display','block');
                      $('.card-body').css('display','block');

                    }, 50);
                })
            .catch((data)=>{
                  setTimeout(() => {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                  }, 300);
            });
          },
          getResults(page = 1) {

              this.$Progress.start();
              $('#cardloader').css('display','block');
              $('.card-header').css('display','none');
              $('.card-body').css('display','none');
              axios.get('api/siswa?page=' + page + '&search='+ this.search).
              then(({ data }) => {
                                    this.formdata = data.data
                                    setTimeout(() => {
                                      $('#cardloader').css('display','none');
                                      $('.card-header').css('display','block');
                                      $('.card-body').css('display','block');
                                      $('#search').focus();
                                    }, 50);
                                  })
              .catch((data)=>{
                  setTimeout(() => {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                  }, 300);
            });

              this.$Progress.finish();
          },
          FindSearch(){
              this.getResults();
          },
          tabPane(id){
            this.showTab.forEach(this.falseTab);
            // this.showKegiatan = !this.showKegiatan;
            // this.showTab[id] = !this.showTab[id]
            // console.log(this.showKegiatan);
            // console.log(id);
            this.$set(this.showTab, id, true)
            this.tabActive = id;
          },
          falseTab: function (item,index) {
            this.$set(this.showTab, index, false)
          },
          editModal(data){
              this.showTab[0] = false;
              this.editmode = false;
              this.$Progress.start();
              $('#pageloader').css('display','block');
              setTimeout(() => {
                  this.editmode = true;
                  this.SiswaId = data.id;
                  this.showTab[0] = true;
                  this.form.reset();
                  this.form.fill(data);
                  setTimeout(() => {
                    $('#pageloader').css('display','none');
                    $('#addNew').modal('show');
                  }, 300);
                  this.$Progress.finish();
              }, 300);
          },
          newModal(){

          },
          createData(){

          },
          updateData(){

          },
          changedate(event){
            // console.log('Alert');
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.showTab.forEach(this.falseTab);
            // console.log(this.showTab);
            this.DateKegiatan = event;
            this.form.tgl_kegiatan = this.DateKegiatan;
            setTimeout(() => {
                  this.tabPane(this.tabActive);
                  // console.log(this.showTab);
                  this.$Progress.finish();
                  $('#pageloader').css('display','none');
            }, 300);
          },
          deleteData(id,name){

          },
          getDate: function() {
                const today = new Date();
                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date +' '+ time;
                this.DateKegiatan = moment(date).format("YYYY-MM-DD");
                this.form.tgl_kegiatan = this.DateKegiatan;
            }

        },
        beforeMount() {
          // if(this.$gate.isAdmin()){
          // }else{
            // this.$router.push({ path: '/dashboard' });
          // }
        },
        mounted() {
            setTimeout(() => {
              }, 500);
            // console.log(this.DateKegiatan);
        },
        created() {
            this.$Progress.start();
            this.$emit('setAxios')
            this.user = JSON.parse(localStorage.getItem('user'))
            this.loginType = this.user.roles[0].name
            this.getDate();
            this.loadData();
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {

        },
    }
</script>
<style type="text/css">
  .modal-lg{
    width: 90% !important;
  }

  .modal-full {
    padding: 0 !important; 
  }
  .modal-full .modal-dialog-full {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-full .modal-content-full {
    /* height: 100%; */
    border: 0;
    border-radius: 0;
  }
  .modal-full .modal-body-full {
    overflow-y: auto;
  }
 
</style>