<template>
<div class="col-md-12">
    <!-- <p>Kegiatan</p> -->
    <!-- <span v-for="datas in formdata" :key="datas.id">
        {{datas.id_siswa}} <br/>
    </span> -->
    <div class="col-md-12">
      <div v-for="(inputdata, index) in formkegiatan" :key="index">
        <div class="card mb-4 py-3 border-left-primary">
          <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Data Kegiatan Kurikulum {{index+1}}</h6>
          </div>
          <div class="card-body">  
              <div class="row">
                  <div class="form-group col-md-12">
                      <label>Kegiatan </label>
                        <!-- <Select2 name="id_kegiatan[]" v-model="form.id_kegiatan[index]" :options="listkegiatan" @change="myChangeEvent($event)" @select="mySelectEvent($event)" /></Select2> -->
                        <Select2Ajax name="id_kegiatan[]" v-model="form.id_kegiatan[index]" v-if="!form.id_kegiatan[index]" :ajaxurl="ajaxurl" @change="myChangeEvent($event)" @select="mySelectEvent($event)" /></Select2Ajax>
                        <Select2Ajax name="id_kegiatan[]" v-model="form.id_kegiatan[index]" v-if="form.id_kegiatan[index]" :options="listkegiatan" :ajaxurl="ajaxurl" @change="myChangeEvent($event)" @select="mySelectEvent($event)" /></Select2Ajax>
                        <has-error :form="form" field="id_kegiatan"></has-error>
                  </div>
                  <div class="form-group col-md-12">
                      <label>Output Kegiatan</label>
                          <div class="row">
                            <div class="form-check radio-inline" v-for="listoutput in listoutputkegiatan" >
                              <fieldset :id="'id_output_kegiatan'+index" class="col-md-2">
                                <label class="form-check-label badge badge-default" style="font-size:18px;">
                                <input class="form-check-input" type="radio" :name="'id_output_kegiatan'+index" :id="listoutput.id" :class="[{ 'is-invalid': form.errors.has('id_output_kegiatan')},listoutput.classname]" v-model="form.id_output_kegiatan[index]" v-bind:value="listoutput.id" :checked="listoutput.id == form.id_output_kegiatan[index]">
                                {{listoutput.text}}</label>
                              </fieldset>
                            </div>
                          </div>
                        <has-error :form="form" field="id_output_kegiatan"></has-error>
                  </div>
                  <div class="form-group col-md-12">
                      <label>Rencana Selanjutnya </label>
                        <textarea v-model="form.rencana_selanjutnya[index]" type="text" name="rencana_selanjutnya[]"
                                      class="form-control" :class="{ 'is-invalid': form.errors.has('rencana_selanjutnya') }" rows="2" placeholder="Contoh : Mengulang Proses" >
                        </textarea>
                        <has-error :form="form" field="rencana_selanjutnya"></has-error>
                  </div>
                  <div class="col-md-12 " align="left">
                      <button class="btn btn-danger" @click.prevent="deleteRow(index,inputdata)">Hapus Kegiatan {{index+1}}</button>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
        <div class="row" >
          <div class="col-md-3">
              <button class="btn btn-primary" @click.prevent="addRow">Tambah Kegiatan</button>
          </div>
          <div class="col-md-6">
              &nbsp;
          </div>
          <div class="col-md-3" align="right">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-success" @click.prevent="Save()">Simpan</button>
          </div>
        </div>
    </div>
</div>
</template>

<script>
    import Select2 from '../../../components/Select2.vue';
    import Select2Ajax from '../../../components/Select2Ajax.vue';
    export default {
        name:'kegiatan',
        components: {
          Select2,
          Select2Ajax,
        },
        props: ['SiswaId','DateKegiatan'],
        beforeMount() {

        },
        mounted() {
            // console.log('Component mounted.'+this.DateKegiatan)
        },
        async created(){
            this.$Progress.start();
            await this.loadData()
            await this.loadKegiatan()
            await this.loadNilai()
            this.user = JSON.parse(localStorage.getItem('user'))
            this.$Progress.finish();
        },
        data() {
            return {
              user : '',
              formkegiatan : [0],
              formdata : {},
              expression:[],
              listkegiatan: [],
              listoutputkegiatan: [],
              form: new Form({
                    id : [],
                    id_siswa : '',
                    id_guru : '',
                    id_kegiatan: [],
                    id_output_kegiatan: [],
                    tgl_kegiatan: '',
                    rencana_selanjutnya: [],
                    type:'kegiatan',
              }),
              ajaxurl : 'api/list/indikator-kegiatan'
            };
        },
        methods: {
          loadData(){
            axios.get("api/kegiatan-siswa/"+this.SiswaId,{params:{type:'kegiatan',date:this.DateKegiatan}}).then(( data ) => {
              this.formkegiatan = data.data.data
              // console.log(this.formkegiatan);
              if(this.formkegiatan.length < 1){
                this.formkegiatan.push([]);
              }else{
                for(var i in this.formkegiatan){
                  this.form.id[i] = this.formkegiatan[i].id
                  this.form.id_kegiatan[i] = this.formkegiatan[i].id_kegiatan
                  this.form.id_output_kegiatan[i] = this.formkegiatan[i].id_output_kegiatan
                  this.form.rencana_selanjutnya[i] = this.formkegiatan[i].rencana_selanjutnya
                }
              }
              this.form.id_siswa = this.SiswaId
              this.form.tgl_kegiatan = this.DateKegiatan
            })
            .catch((data)=>{
                  setTimeout(() => {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                  }, 300);
                  this.form.id_siswa = this.SiswaId
              });
              this.form.id_siswa = this.SiswaId
          },
          loadKegiatan(){
            axios.get("api/list/indikator-kegiatan").then(({ data }) => (this.listkegiatan = data.data));
          },
          loadNilai(){
            axios.get("api/list/nilai-output-kegiatan").then(({ data }) => (this.listoutputkegiatan = data.data));
          },
          Save(){
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            // let id = this.form.id
            let id = 0
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.form.id_guru = this.user.id
            this.form.put('api/kegiatan-siswa/'+id)
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        $('#pageloader').css('display','none');
                        this.$Progress.finish();
                        // this.form.id = data.data.data.id;
                        // this.$router.push('/siswa')
                        this.loadData();
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        this.$Progress.failed();
                        return false;
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          },
          myChangeEvent(){

          },
          mySelectEvent(){

          },
          addRow() {
            this.formkegiatan.push([]);
          },
          deleteRow(index,val) {
            if(this.formkegiatan.length >= 1){
              this.formkegiatan.splice(index,1);
              this.form.id.splice(index,1);
              this.form.id_kegiatan.splice(index,1);
              this.form.id_output_kegiatan.splice(index,1);
              this.form.rencana_selanjutnya.splice(index,1);
            }else{
              this.deleteFailed(index,val);
            }
          },
          deleteFailed(index,val) {
            Toast.fire({
                icon: 'error',
                title: 'Gagal Hapus, Minimal 0 Kegiatan'
            });
          },
        }
    }
</script>
<style type="text/css">
    .select2{
        width: 100% !important;
        /*z-index: 999999999 !important;*/
    }
    .outputkegiatan{
            -webkit-appearance: none;
            width: 60px;
            height: 60px;
            border: none;
            cursor: pointer;
            transition: border .2s ease;
            filter: grayscale(100%);
            margin: 0 5px;
            transition: all .2s ease;
    }
    .outputkegiatan:hover, .outputkegiatan:checked {
                filter: grayscale(0);
    }
    .outputkegiatan:focus {
                outline: 0;
    }

</style>