<template>
    <button class="btn btn-danger" @click="deleteData">
        <slot><i class="fas fa-trash"></i></slot>
    </button>
</template>

<script>
    import { EventBus } from '../eventBus.js';

    export default{
        //extends: ExampleComponent,
        props: ['id', 'name'],
        methods: {
            deleteData() {
                // alert(`I am ${this.name}!`)
                EventBus.$emit('deleteValue', this.id,this.name) 
            }
        },
    }
</script>