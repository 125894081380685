<template>
<div class="col-md-12">
    <!-- <span v-for="data in formdata" :key="data.id_siswa">
        {{data.id_siswa}} <br/>
    </span> -->
    <div class="col-md-12">
        <div class="row" >
            <div class="form-group col-md-3">
                <label>Asi 1 <small>(Waktu)</small></label>
                  <date-picker v-model="form.asi_waktu1" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="asi_waktu"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Asi 1 <small>(Quantity)</small></label>
                  <input v-model="form.asi_kuantiti1" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('asi_kuantiti1') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="asi_kuantiti"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Sufor 1 <small>(Waktu)</small></label>
                  <date-picker v-model="form.sufor_waktu1" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="sufor_waktu"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Sufor 1 <small>(Quantity)</small></label>
                  <input v-model="form.sufor_kuantiti1" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('sufor_kuantiti1') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="sufor_kuantiti"></has-error>
            </div>
        </div>
        <div class="row" >
            <div class="form-group col-md-3">
                <label>Asi 2 <small>(Waktu)</small></label>
                  <date-picker v-model="form.asi_waktu2" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="asi_waktu"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Asi 2 <small>(Quantity)</small></label>
                  <input v-model="form.asi_kuantiti2" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('asi_kuantiti2') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="asi_kuantiti"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Sufor 2 <small>(Waktu)</small></label>
                  <date-picker v-model="form.sufor_waktu2" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="sufor_waktu"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Sufor 2 <small>(Quantity)</small></label>
                  <input v-model="form.sufor_kuantiti2" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('sufor_kuantiti2') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="sufor_kuantiti"></has-error>
            </div>
        </div>
        <div class="row" >
            <div class="form-group col-md-3">
                <label>Asi 3 <small>(Waktu)</small></label>
                  <date-picker v-model="form.asi_waktu3" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="asi_waktu"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Asi 3 <small>(Quantity)</small></label>
                  <input v-model="form.asi_kuantiti3" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('asi_kuantiti3') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="asi_kuantiti"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Sufor 3 <small>(Waktu)</small></label>
                  <date-picker v-model="form.sufor_waktu3" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="sufor_waktu"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Sufor 3 <small>(Quantity)</small></label>
                  <input v-model="form.sufor_kuantiti3" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('sufor_kuantiti3') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="sufor_kuantiti"></has-error>
            </div>
        </div>
        <div class="row" >
            <div class="form-group col-md-3">
                <label>Asi 4 <small>(Waktu)</small></label>
                  <date-picker v-model="form.asi_waktu4" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="asi_waktu"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Asi 4 <small>(Quantity)</small></label>
                  <input v-model="form.asi_kuantiti4" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('asi_kuantiti4') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="asi_kuantiti"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Sufor 4 <small>(Waktu)</small></label>
                  <date-picker v-model="form.sufor_waktu4" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="sufor_waktu"></has-error>
            </div>
            <div class="form-group col-md-3">
                <label>Sufor 4 <small>(Quantity)</small></label>
                  <input v-model="form.sufor_kuantiti4" type="number"
                    class="form-control" :class="{ 'is-invalid': form.errors.has('sufor_kuantiti4') }" placeholder="Contoh : 1" >
                  <has-error :form="form" field="sufor_kuantiti"></has-error>
            </div>
        </div>
    </div>
    <div class="col-md-12" align="right">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-success" @click.prevent="Save()">Simpan</button>
    </div>
</div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    export default {
        components: {
          DatePicker,
        },
        name:'kegiatan',
        props: ['SiswaId','DateKegiatan'],
        async mounted() {
            // console.log('Component mounted.'+this.SiswaId)
            this.$Progress.start();
            await this.loadData()
            this.user = JSON.parse(localStorage.getItem('user'))
            this.$Progress.finish();
        },
        data() {
            return {
              user:'',
              formdata : {},
              form: new Form({
                    id : '',
                    id_siswa : '',
                    tgl_daily_susu : '',
                    asi_waktu1: '',
                    asi_kuantiti1: '',
                    asi_waktu2: '',
                    asi_kuantiti2: '',
                    asi_waktu3: '',
                    asi_kuantiti3: '',
                    asi_waktu4: '',
                    asi_kuantiti4: '',
                    sufor_waktu1: '',
                    sufor_kuantiti1: '',
                    sufor_waktu2: '',
                    sufor_kuantiti2: '',
                    sufor_waktu3: '',
                    sufor_kuantiti3: '',
                    sufor_waktu4: '',
                    sufor_kuantiti4: '',
                    type:'susu',
              }),
            };
        },
        methods: {
          loadData(){
            axios.get("api/kegiatan-siswa/"+this.SiswaId,{params:{type:'susu',date:this.DateKegiatan}}).then(( data ) => {
              this.formdata = data.data.data[0]
              // console.log(this.formdata);
              if(data.data.data.length > 0){
                  this.form.id = this.formdata.id;
                  this.form.asi_waktu1 = this.formdata.asi_waktu1;
                  this.form.asi_kuantiti1 = this.formdata.asi_kuantiti1;
                  this.form.asi_waktu2 = this.formdata.asi_waktu2;
                  this.form.asi_kuantiti2 = this.formdata.asi_kuantiti2;
                  this.form.asi_waktu3 = this.formdata.asi_waktu3;
                  this.form.asi_kuantiti3 = this.formdata.asi_kuantiti3;
                  this.form.asi_waktu4 = this.formdata.asi_waktu4;
                  this.form.asi_kuantiti4 = this.formdata.asi_kuantiti4;
                  this.form.sufor_waktu1 = this.formdata.sufor_waktu1;
                  this.form.sufor_kuantiti1 = this.formdata.sufor_kuantiti1;
                  this.form.sufor_waktu2 = this.formdata.sufor_waktu2;
                  this.form.sufor_kuantiti2 = this.formdata.sufor_kuantiti2;
                  this.form.sufor_waktu3 = this.formdata.sufor_waktu3;
                  this.form.sufor_kuantiti3 = this.formdata.sufor_kuantiti3;
                  this.form.sufor_waktu4 = this.formdata.sufor_waktu4;
                  this.form.sufor_kuantiti4 = this.formdata.sufor_kuantiti4;
              }

              this.form.id_siswa = this.SiswaId
              this.form.tgl_daily_susu = this.DateKegiatan
            })
            .catch((data)=>{
                  setTimeout(() => {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                  }, 300);
              });
          },
          Save(){
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            let id = this.form.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.form.put('api/kegiatan-siswa/'+id)
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        $('#pageloader').css('display','none');
                        this.form.id = data.data.data.id;
                        this.$Progress.finish();
                        // this.$router.push('/siswa')
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        this.$Progress.failed();
                        return false;
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          }
        }
    }
</script>
