<template>
  <div>
      <div class="overlay" id="pageloader"><div class="load-bar"><div class="bar"></div><div class="bar"></div><div class="bar"></div></div></div>
      <!-- Navbar -->
      <nav class="main-header navbar navbar-expand bg-white navbar-light border-bottom">
        <!-- Left navbar links -->
        <template v-if="isLoggedIn && isSideBar">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a>
          </li>
        </ul>
        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">
          <!-- Messages Dropdown Menu -->
          <li class="nav-item ">
            <a href="#modalChangePass" class="nav-link" data-toggle="modal" data-id=""><i class="nav-icon fas fa-user"></i> Password</a>
          </li>
          <!-- <li class="nav-item ">
            <a href="#" class="nav-link">Sign Out</a>
          </li> -->
          <li class="nav-item ">
            <a class="nav-link" href="#" @click.prevent="logout">
              <i class="nav-icon fas fa-sign-out-alt"></i>
                Sign-
                <span class="right badge badge-danger">Out</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
              <i class="fas fa-th-large"></i>
            </a>
          </li>
        </ul>
        </template>
        <template v-if="!isLoggedIn || !isSideBar">
          <div class="container">
              <a href="/" class="navbar-brand">
              <img src="/img/logo.png" :alt="appname + 'Logo'" class="brand-image img-circle elevation-3" style="opacity: .8; height:33px; margin-top:-0.5rem; margin-right:0.2rem ">
              <span class="brand-text font-weight-light">{{appname}}</span>
              </a>
              <button class="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
              </button>
            </div>
        </template>
      </nav>
      <!-- /.navbar -->
      <!-- Main Sidebar Container -->
      <template v-if="isLoggedIn && isSideBar">
      <aside class="main-sidebar sidebar-dark-success elevation-4">
          <!-- Brand Logo -->
          <router-link to="/dashboard" class="brand-link" style="text-decoration:none !important">
            <img src="/img/logo.png" alt="The Logo" class="brand-image img-circle elevation-3"
                 style="opacity: .8">
            <span class="brand-text font-weight-light">{{appname}}</span>
          </router-link>

          <!-- Sidebar -->
          <div class="sidebar">
            <!-- Sidebar user panel (optional) -->
              <router-link to="/profile" style="text-decoration:none !important">
                <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div class="image">
                      <img src="/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
                    </div>
                    <div class="info">

                        {{user.name}}
                        <span class="d-block text-muted">
                          {{user.roles[0].name}}
                        </span>
                    </div>
                </div>
              </router-link>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
              <tree :tree-data="navigation"></tree>
            </nav>
            <!-- /.sidebar-menu -->
          </div>
          <!-- /.sidebar -->
      </aside>
      </template>

      <div class="content-wrapper">
        <template v-if="isLoggedIn && isSideBar">
          <div class="overlay" id="contentloader"><div class="load-bar"><div class="bar"></div><div class="bar"></div><div class="bar"></div></div></div>
          <section class="content-header">
              <div class="container-fluid">
                  <div class="alert alert-success ">
                    <h5 style="font-weight:300"><i class="icon" :class="icon"></i> {{title + " " + appname}}</h5>
                    <p style="font-size:14px;">{{description}}</p>
                  </div>
              </div><!-- /.container-fluid -->
          </section>
        </template>
        <router-view @loggedIn="setUser" @setAxios="setAxios" :title="title" @logout="logout"></router-view>
      </div>
      <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import Tree from "./Navigation";

export default {
  components:{
    Tree
  },
  data() {
      return {
        user: null,
        isLoggedIn: false,
        isSideBar:true,
        navigation: {},
        title: '',
        description: '',
        icon: '',
        appname:'',
        // token:'',
      }
    },
    async mounted() {
      await this.setUser()
      // console.log(this.$route.meta.isSideBar);
    },
    created(){
      this.$Progress.start();
      this.setAppname()
      if(this.isLoggedIn){
        this.setAxios()
      }else{
        // console.log('guest');
      }
      // console.log('.env '+process.env.MIX_VUE_APP_NAME);
      this.$Progress.finish();
    },
    watch: {
        isLoggedIn:function (){
          if(this.isLoggedIn){ 
            setTimeout(() => {
                if(!localStorage.getItem('isTreeview')){ 
                    $('[data-widget="treeview"]').Treeview('init');
                    localStorage.setItem('isTreeview', true)
                    // console.log('navigation');
                }
            }, 1000);
          }
        },
        $route:function(){
          this.setHeader();
        }
    },
    computed: {
        currentRoutePath() {
            return this.$route.path;
        }
    },
    methods: {
        setUser() {
          this.user = JSON.parse(localStorage.getItem('user'))
          this.isLoggedIn = localStorage.getItem('token') != null
          this.navigation = JSON.parse(localStorage.getItem('navigation-menu'));
          // this.token = localStorage.getItem('token')
          this.setHeader();
          this.appname = localStorage.getItem('appname');
          this.isSideBar = this.$route.meta.isSideBar;

          // if(this.isLoggedIn){
          if(this.isSideBar){
            this.setLayoutLogin()
          }else{
            this.setLayoutGuest()
            // console.log('guest');
          }
        },
        setLayoutLogin(){
          $('body').addClass('hold-transition sidebar-mini layout-fixed sidebar-collapse').removeClass('layout-top-nav');
        },
        setLayoutGuest(){
          $('body').addClass('layout-top-nav').removeClass('hold-transition sidebar-mini layout-fixed sidebar-collapse');
        },
        setHeader(){
          if(this.isLoggedIn){
            let menu = (this.user.menus?this.user.menus:null);
            let route= this.currentRoutePath;
            let t = '';
            let d = '';
            let i = '';
            menu.map(function(v, k) {
                // console.log(v.link);
                if(v.link == route){
                    t = v.desc;
                    d = v.long_desc;
                    i = v.icon;
                }else{
                  // console.log(v.link);
                  if(!t){
                    t = 'Page Website';
                    d = 'Manage Your Website Application';
                    i = 'fa fa-star';
                  }
                }
            })

            this.title = t;
            this.description = d;
            this.icon = i;
          }
          
        },
        setAppname(){
          if(!localStorage.getItem('appname') || localStorage.getItem('appname') !== 'undefined' || localStorage.getItem('appname') !== null){
            localStorage.setItem('appname',process.env.MIX_VUE_APP_NAME)
          }
        },
        setAxios(){
            window.axios.defaults.headers.common['Content-Type'] = 'application/json'
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
        },
        logout() {
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          localStorage.removeItem('navigation-menu')
          localStorage.removeItem('isTreeview')
          // localStorage.removeItem('appname')
          this.setUser()

          this.$router.push('/login')
        }
    }
}
</script>
