<template>
<div class="col-md-12">
    <!-- <p>Catatan Khusus</p> -->
    <!-- <span v-for="data in formdata" :key="data.id_siswa">
        {{data.id_siswa}} <br/>
    </span> -->
    <div class="col-md-12">
        <div class="row" >
            <div class="col-md-12 callout callout-info">
              <h6 class="text-danger">Pemeriksaan Umum</h6>

              <div class="row">
                <div class="form-group col-md-4">
                      <label>Tinggi Badan (Cm)</label>
                      <input v-model="form.tinggi_badan" type="text" name="tinggi_badan"
                          class="form-control" :class="{ 'is-invalid': form.errors.has('tinggi_badan') }" placeholder="Contoh : 90">
                      <has-error :form="form" field="tinggi_badan"></has-error>
                </div>
                <div class="form-group col-md-4">
                      <label>Berat Badan (Kg)</label>
                      <input v-model="form.berat_badan" type="text" name="berat_badan"
                          class="form-control" :class="{ 'is-invalid': form.errors.has('berat_badan') }" placeholder="Contoh : 90">
                      <has-error :form="form" field="berat_badan"></has-error>
                </div>
                <div class="form-group col-md-4">
                      <label>Lingkar Kepala (Cm)</label>
                      <input v-model="form.lingkar_kepala" type="text" name="lingkar_kepala"
                          class="form-control" :class="{ 'is-invalid': form.errors.has('lingkar_kepala') }" placeholder="Contoh : 90">
                      <has-error :form="form" field="lingkar_kepala"></has-error>
                </div>
              </div>
            </div>

            <div class="col-md-12 callout callout-info">
              <h6 class="text-danger">Pemeriksaan Telinga</h6>

              <div class="row">
                <div class="form-group col-md-4">
                    <label>Kotoran </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="telinga_kotoran" :class="{ 'is-invalid': form.errors.has('telinga_kotoran') }" v-model="form.telinga_kotoran" v-bind:value="pilihan.id" :checked="pilihan.id == form.telinga_kotoran">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="telinga_kotoran"></has-error>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>Cairan </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="telinga_cairan" :class="{ 'is-invalid': form.errors.has('telinga_cairan') }" v-model="form.telinga_cairan" v-bind:value="pilihan.id" :checked="pilihan.id == form.telinga_cairan">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="telinga_cairan"></has-error>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>Daya Dengar </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans5">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="telinga_daya_dengar" :class="{ 'is-invalid': form.errors.has('telinga_daya_dengar') }" v-model="form.telinga_daya_dengar" v-bind:value="pilihan.id" :checked="pilihan.id == form.telinga_daya_dengar">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="telinga_daya_dengar"></has-error>
                    </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 callout callout-info">
              <h6 class="text-danger">Pemeriksaan Hidung</h6>

              <div class="row">
                <div class="form-group col-md-12">
                    <label>Cairan </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="hidung_cairan" :class="{ 'is-invalid': form.errors.has('hidung_cairan') }" v-model="form.hidung_cairan" v-bind:value="pilihan.id" :checked="pilihan.id == form.hidung_cairan">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="hidung_cairan"></has-error>
                    </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 callout callout-info">
              <h6 class="text-danger">Pemeriksaan Tenggorokan</h6>

              <div class="row">
                <div class="form-group col-md-6">
                    <label>Kemerahan </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans4">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="tenggorokan_kemerahan" :class="{ 'is-invalid': form.errors.has('tenggorokan_kemerahan') }" v-model="form.tenggorokan_kemerahan" v-bind:value="pilihan.id" :checked="pilihan.id == form.tenggorokan_kemerahan">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="tenggorokan_kemerahan"></has-error>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label>Amandel </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans6">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="tenggorokan_amandel" :class="{ 'is-invalid': form.errors.has('tenggorokan_amandel') }" v-model="form.tenggorokan_amandel" v-bind:value="pilihan.id" :checked="pilihan.id == form.tenggorokan_amandel">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="tenggorokan_amandel"></has-error>
                    </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 callout callout-info">
              <h6 class="text-danger">Pemeriksaan Gigi</h6>

              <div class="row">
                <div class="form-group col-md-6">
                    <label>Bengkak </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans6">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="gigi_bengkak" :class="{ 'is-invalid': form.errors.has('gigi_bengkak') }" v-model="form.gigi_bengkak" v-bind:value="pilihan.id" :checked="pilihan.id == form.gigi_bengkak">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="gigi_bengkak"></has-error>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label>Lubang </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans3">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="gigi_lobang" :class="{ 'is-invalid': form.errors.has('gigi_lobang') }" v-model="form.gigi_lobang" v-bind:value="pilihan.id" :checked="pilihan.id == form.gigi_lobang">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="gigi_lobang"></has-error>
                    </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 callout callout-info">
              <h6 class="text-danger">Pemeriksaan Mata</h6>

              <div class="row">
                <div class="form-group col-md-4">
                    <label>Merah </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans4">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="mata_merah" :class="{ 'is-invalid': form.errors.has('mata_merah') }" v-model="form.mata_merah" v-bind:value="pilihan.id" :checked="pilihan.id == form.mata_merah">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="mata_merah"></has-error>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>Kotoran </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="mata_kotoran" :class="{ 'is-invalid': form.errors.has('mata_kotoran') }" v-model="form.mata_kotoran" v-bind:value="pilihan.id" :checked="pilihan.id == form.mata_kotoran">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="mata_kotoran"></has-error>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>Daya Lihat </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans5">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="mata_daya_lihat" :class="{ 'is-invalid': form.errors.has('mata_daya_lihat') }" v-model="form.mata_daya_lihat" v-bind:value="pilihan.id" :checked="pilihan.id == form.mata_daya_lihat">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="mata_daya_lihat"></has-error>
                    </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 callout callout-info">
              <h6 class="text-danger">Pemeriksaan Pernafasan dan Jantung</h6>

              <div class="row">
                <div class="form-group col-md-4">
                    <label>Pernafasan </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans2">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="pernafasan" :class="{ 'is-invalid': form.errors.has('pernafasan') }" v-model="form.pernafasan" v-bind:value="pilihan.id" :checked="pilihan.id == form.pernafasan">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="pernafasan"></has-error>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label>Jantung </label>
                    <div>
                      <div class="form-check form-check-inline" v-for="pilihan in pilihans2">
                        <input class="form-check-input" :id="pilihan.id" type="radio" name="jantung" :class="{ 'is-invalid': form.errors.has('jantung') }" v-model="form.jantung" v-bind:value="pilihan.id" :checked="pilihan.id == form.jantung">
                        <label class="form-check-label" :for="pilihan.id">{{pilihan.name}}</label>
                      </div>
                      <has-error :form="form" field="jantung"></has-error>
                    </div>
                </div>
              </div>
            </div>

            <div class="form-group col-md-12">
                <label>Pemeriksaan Lainnya</label>
                  <textarea v-model="form.lain_lain" type="text" name="lain_lain"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('lain_lain') }" rows="2" placeholder="Contoh : Adanya sakit perut berlebihan, periksa ke dokter penyakit dalam" >
                  </textarea>
                  <has-error :form="form" field="lain_lain"></has-error>
            </div>

            <div class="col-md-12 callout callout-info">
              <h6 class="text-danger">Saran untuk Orang Tua</h6>

              <div class="row">
                <div class="form-group col-md-12">
                    <span class="text-success fa fa-check-square"></span> <label>Centang Yang perlu disarankan </label>
                    <div>
                      <div class="form-group">
                        <div class="form-check">
                          <input v-model="form.bersihkan_telinga" name="bersihkan_telinga" class="form-check-input" type="checkbox" :checked="form.bersihkan_telinga == 1">
                          <label class="form-check-label">Bersihkan Telinga dengan Cotton Bud</label>
                        </div>
                        <div class="form-check">
                          <input v-model="form.periksa_dokter_tht" name="periksa_dokter_tht" class="form-check-input" type="checkbox" :checked="form.periksa_dokter_tht == 1">
                          <label class="form-check-label">Periksa Ke dokter THT</label>
                        </div>
                        <div class="form-check">
                          <input v-model="form.periksa_dokter_mata" name="periksa_dokter_mata" class="form-check-input" type="checkbox" :checked="form.periksa_dokter_mata == 1">
                          <label class="form-check-label">Periksa Ke dokter Mata</label>
                        </div>
                        <div class="form-check">
                          <input v-model="form.periksa_dokter_anak" name="periksa_dokter_anak" class="form-check-input" type="checkbox" :checked="form.periksa_dokter_anak == 1">
                          <label class="form-check-label">Periksa Ke dokter Anak</label>
                        </div>
                        <div class="form-check">
                          <input v-model="form.periksa_gigi" name="periksa_gigi" class="form-check-input" type="checkbox" :checked="form.periksa_gigi == 1">
                          <label class="form-check-label">Periksa Ke dokter Gigi</label>
                        </div>
                        <div class="form-check">
                          <input v-model="form.periksa_dokter_lainnya" name="periksa_dokter_lainnya" class="form-check-input" type="checkbox" :checked="form.periksa_dokter_lainnya == 1">
                          <label class="form-check-label">Periksa Ke dokter lainnya (Tambahkan nama spesialis pada saran lainnya)</label>
                        </div>
                      </div>
                      <has-error :form="form" field="chekbox"></has-error>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <label>Saran Lainnya</label>
                      <textarea v-model="form.saran_lain" type="text" name="saran_lain"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('saran_lain') }" rows="2" placeholder="Contoh : Periksa Dokter Paru" >
                      </textarea>
                      <has-error :form="form" field="saran_lain"></has-error>
                </div>
              </div>
            </div>

        </div>
    </div>
    <div class="col-md-12" align="right">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-success" @click.prevent="Save()">Simpan</button>
    </div>
</div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    export default {
        components: {
          DatePicker,
        },
        name:'pemeriksaankesehatan',
        props: ['SiswaId','DateKegiatan'],
        mounted() {
            // console.log('Component mounted.'+this.SiswaId)
            this.$Progress.start();
            this.loadData()
            this.$Progress.finish();
        },
        data() {
            return {
              formdata : {},
              form: new Form({
                    id : '',
                    id_siswa : '',
                    id_guru : '',
                    tgl_pemeriksaan: '',
                    tinggi_badan: '',
                    tinggi_badan: '',
                    berat_badan: '',
                    lingkar_kepala: '',
                    telinga_cairan: '',
                    telinga_kotoran: '',
                    telinga_daya_dengar: '',
                    hidung_cairan: '',
                    tenggorokan_amandel: '',
                    tenggorokan_kemerahan: '',
                    mata_kotoran: '',
                    mata_merah: '',
                    mata_daya_lihat: '',
                    pernafasan: '',
                    jantung: '',
                    gigi_lobang: '',
                    gigi_bengkak: '',
                    gigi_bengkak: '',
                    lain_lain: '',
                    bersihkan_telinga: '',
                    periksa_dokter_tht: '',
                    periksa_dokter_mata: '',
                    periksa_gigi: '',
                    periksa_dokter_anak: '',
                    periksa_dokter_lainnya: '',
                    saran_lain: '',
                    type:'pemeriksaaankesehatan',
              }),
              pilihans:[
                          {name: 'Tidak Ada', id: 2},
                          {name: 'Ada', id: 1}
                        ],
              pilihans2:[
                          {name: 'Normal', id: 2},
                          {name: 'Tidak Normal', id: 1}
                        ],
              pilihans3:[
                          {name: 'Tidak', id: 2},
                          {name: 'Lubang', id: 1}
                        ],
              pilihans4:[
                          {name: 'Tidak', id: 2},
                          {name: 'Merah', id: 1}
                        ],
              pilihans5:[
                          {name: 'Normal', id: 2},
                          {name: 'Curiga Ada Gangguan', id: 1}
                        ],
              pilihans6:[
                          {name: 'Normal', id: 2},
                          {name: 'Bengkak', id: 1}
                        ],
            };
        },
        methods: {
          loadData(){
            axios.get("api/kegiatan-siswa/"+this.SiswaId,{params:{type:'pemeriksaankesehatan',date:this.DateKegiatan}}).then(( data ) => {
              this.formdata = data.data.data[0]
              if(data.data.data.length > 0){
                  this.form.id = this.formdata.id;
                  this.form.tinggi_badan = this.formdata.tinggi_badan;
                  this.form.berat_badan = this.formdata.berat_badan;
                  this.form.lingkar_kepala = this.formdata.lingkar_kepala;
                  this.form.telinga_cairan = this.formdata.telinga_cairan;
                  this.form.telinga_kotoran = this.formdata.telinga_kotoran;
                  this.form.telinga_daya_dengar = this.formdata.telinga_daya_dengar;
                  this.form.hidung_cairan = this.formdata.hidung_cairan;
                  this.form.tenggorokan_amandel = this.formdata.tenggorokan_amandel;
                  this.form.tenggorokan_kemerahan = this.formdata.tenggorokan_kemerahan;
                  this.form.mata_kotoran = this.formdata.mata_kotoran;
                  this.form.mata_merah = this.formdata.mata_merah;
                  this.form.mata_daya_lihat = this.formdata.mata_daya_lihat;
                  this.form.pernafasan = this.formdata.pernafasan;
                  this.form.jantung = this.formdata.jantung;
                  this.form.gigi_lobang = this.formdata.gigi_lobang;
                  this.form.gigi_bengkak = this.formdata.gigi_bengkak;
                  this.form.gigi_bengkak = this.formdata.gigi_bengkak;
                  this.form.lain_lain = this.formdata.lain_lain;
                  this.form.bersihkan_telinga = this.formdata.bersihkan_telinga;

                  let periksadokter = this.formdata.periksa_dokter;
                  // console.log(periksadokter);
                  if(periksadokter === null){
                    this.form.periksa_dokter_tht = 0;
                    this.form.periksa_dokter_mata = 0;
                    this.form.periksa_dokter_anak = 0;
                    this.form.periksa_dokter_lainnya = 0;
                  }else{
                    this.form.periksa_dokter_tht = (periksadokter.includes("THT")?1:0);
                    this.form.periksa_dokter_mata = (periksadokter.includes("Mata")?1:0);
                    this.form.periksa_dokter_anak = (periksadokter.includes("Anak")?1:0);
                    this.form.periksa_dokter_lainnya = (periksadokter.includes("Lainnya")?1:0);
                  }

                  this.form.periksa_gigi = this.formdata.periksa_gigi;
                  this.form.saran_lain = this.formdata.saran_lain;
              }
              this.form.id_siswa = this.SiswaId
              this.form.tgl_pemeriksaan = this.DateKegiatan
            })
            .catch((data)=>{
                  console.log(data);
                  setTimeout(() => {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                  }, 300);
              });
          },
          Save(){
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            let id = this.form.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.form.put('api/kegiatan-siswa/'+id)
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        $('#pageloader').css('display','none');
                        this.$Progress.finish();
                        this.form.id = data.data.data.id;
                        // this.$router.push('/siswa')
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        this.$Progress.failed();
                        return false;
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          }
        }
    }
</script>
