<template>
<div class="col-md-12">
    <p>Makmin</p>
    <data-table :columns="columns" class="display table table-bordered table-hover" :ajax="ajax" ></data-table>
</div>
</template>

<script>
    import DataTable from '../../components/DataTable.vue';
    import { EventBus } from '../../eventBus.js';
    export default {
        components: {
          DataTable,
        },
        name:'kegiatan',
        props: ['SiswaId','DateKegiatan','FilterMonth'],
        mounted() {
            // console.log('Component mounted.'+this.SiswaId)
            this.$Progress.start();
            // this.loadData()
            this.$Progress.finish();
        },
        data() {
            return {
              formdata : {},
              columns: [
                    {title: 'No.',data: 'no', name: 'no',width:"2%"},
                    {title: 'Tgl. Kegiatan',data: 'tgl_kegiatan', name:'a.tgl_daily_makmin'},
                    {title: 'Sarapan',data: 'breakfast', name:'sarapan'},
                    {title: 'Makan Siang',data: 'lunch', name:'lunch'},
                    {title: 'Snack',data: 'snack', name:'snack'},
                    {title: 'Makan Malam',data: 'dinner', name:'dinner'},
                    {title: 'Rata Nilai',data: 'rata_nilai', name:'a.id'},
                ],
                ajax: {
                  url : '/api/report/detail-kegiatan/'+this.SiswaId+'?type=makmin&filter='+this.FilterMonth,
                  beforeSend: function (xhr){ 
                
                  },
                  error: data => {
                    // console.log(data);
                    // if(data.status == 401){
                    //     this.$emit('logout')
                    // }
                  },
                  headers: {
                      'Content-Type': 'application/json',
                      // 'Authorization': 'Bearer ' + localStorage.getItem('token')
                  },
                },
            };
        },
        methods: {
          loadData(){
            // axios.get("api/detail-siswa/"+this.SiswaId,{params:{type:'makmin',date:this.DateKegiatan}}).then(({ data }) => (this.formdata = data.data.data))
            // .catch((data)=>{
            //       setTimeout(() => {                      
            //           Toast.fire({
            //               icon: 'error',
            //               title: 'Some error occured! Please try again'
            //           });
            //           if(data.response.status == 401){
            //               this.$emit('logout')
            //           }
            //       }, 300);
            //   });
          },
          
        }
    }
</script>

