import Vue from "vue";
import NotFound from './components/404.vue'
import Home from './views/Home.vue'
import Report from './views/Report.vue'
import Login from './views/auth/Login.vue'
import Register from './views/auth/Register.vue'
import Admin from './views/admin/Home.vue'
import User from './views/admin/master/User.vue'
import Kegiatan from './views/admin/master/Kegiatan.vue'
import Aspek from './views/admin/master/Aspek.vue'
import Indikator from './views/admin/master/Indikator.vue'
import IndikatorKegiatan from './views/admin/master/IndikatorKegiatan.vue'
import NilaiOutputKegiatan from './views/admin/master/NilaiOutputKegiatan.vue'
import Program from './views/admin/master/Program.vue'
import Produk from './views/admin/master/Produk.vue'
import Siswa from './views/admin/siswa/index.vue'
import AktifitasSiswa from './views/admin/aktifitas/index.vue'
import Invoice from './views/admin/invoice/index.vue'

import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
      {
          path: '/',
          name: 'home',
          component: Login,
          meta: {
             isSideBar: false,
           }
      },
      {
          path: '/report/:id',
          name: 'report',
          component: Home,
          meta: {
             isSideBar: false,
           }
      },
      {
          path: '/detail/:id',
          name: 'detail',
          component: Report,
          meta: {
             isSideBar: false,
           }
      },
      {
          path: '/login',
          name: 'login',
          component: Login
      },
      {
          path: '/register',
          name: 'register',
          component: Login,
          // component: Register,
          meta: {
             isSideBar: false,
           }
      },   
      {
          path: '/home',
          name: 'admin',
          component: Admin,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '/user',
          name: 'user',
          component: User,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '*',
          name: 'not-found',
          component: NotFound,
          meta: {
             isSideBar: false,
           }
      },
      {
          path: '/kegiatan',
          name: 'kegiatan',
          component: Kegiatan,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '/aspek',
          name: 'aspek',
          component: Aspek,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '/indikator',
          name: 'indikator',
          component: Indikator,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '/indikator-kegiatan',
          name: 'indikator-kegiatan',
          component: IndikatorKegiatan,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '/nilai-output-kegiatan',
          name: 'nilai-output-kegiatan',
          component: NilaiOutputKegiatan,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '/program',
          name: 'program',
          component: Program,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '/produk',
          name: 'produk',
          component: Produk,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '/siswa',
          name: 'siswa',
          component: Siswa,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '/aktifitas-siswa',
          name: 'aktifitas-siswa',
          component: AktifitasSiswa,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
      {
          path: '/invoice',
          name: 'invoice',
          component: Invoice,
          meta: {
             requiresAuth: true,
             isSideBar: true,
           }
      },
  ],
})

//app.js

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = localStorage.getItem('token') != null;
    if (!token) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      let user = JSON.parse(localStorage.getItem('user'))
      let roles = user.roles.map(role => role.name)
      let menu = user.menus.map(role => role.link)
      // console.log(menu);
      var exists = menu.includes(to.path);

      // console.log(exists);
      if (exists) {
        next()
      }else{
        next({
          name: 'home'
        })
      }

      // if (to.matched.some(record => record.meta.isUser)) {
      //   if (roles.includes('user')) next()
      //   else if (roles[0] === 'admin') {
      //     next({
      //       name: 'admin'
      //     })
      //   } else if (roles[0] === 'manager') {
      //     next({
      //       name: 'manager'
      //     })
      //   } else next({
      //     name: 'home'
      //   })
      // } else if (to.matched.some(record => record.meta.isAdmin)) {
      //   if (roles.includes('admin')) next()
      //   else if (roles[0] === 'manager') {
      //     next({
      //       name: 'manager'
      //     })
      //   } else if (roles[0] === 'user') {
      //     next({
      //       name: 'user'
      //     })
      //   } else next({
      //     name: 'home'
      //   })

      // } else if (to.matched.some(record => record.meta.isManager)) {
      //   if (roles.includes('manager')) next()
      //   else if (roles[0] === 'user') {
      //     next({
      //       name: 'user'
      //     })
      //   } else if (roles[0] === 'admin') {
      //     next({
      //       name: 'admin'
      //     })
      //   } else next({
      //     name: 'home'
      //   })

      // } else {
      //   next()
      // }
    }
  } else {
    next()
  }
});


export default router;
