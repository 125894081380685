<template>
<div class="col-md-12">
    <!-- <p>Catatan Khusus</p> -->
    <!-- <span v-for="data in formdata" :key="data.id_siswa">
        {{data.id_siswa}} <br/>
    </span> -->
    <div class="col-md-12">
        <div class="row" >
            <div class="form-group col-md-12">
                <label>Catatan Khusus</label>
                  <textarea v-model="form.catatan_khusus" type="text" name="catatan_khusus"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('catatan_khusus') }" rows="2" placeholder="Contoh : Mengulang Proses" >
                  </textarea>
                  <has-error :form="form" field="catatan_khusus"></has-error>
            </div>
            <div class="form-group col-md-6">
                <label>Tidur <small>(Waktu)</small></label>
                  <date-picker v-model="form.tidur" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="tidur"></has-error>
            </div>
            <div class="form-group col-md-6">
                <label>Bangun Tidur <small>(Waktu)</small></label>
                  <date-picker v-model="form.bangun_tidur" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="bangun_tidur"></has-error>
            </div>
            <div class="form-group col-md-12">
                <label>BAB <small>(Waktu)</small></label>
                  <date-picker v-model="form.bab" valueType="format" input-class="form-control" style="width: 100%;" type="time"></date-picker>
                  <has-error :form="form" field="bab"></has-error>
            </div>
        </div>
    </div>
    <div class="col-md-12" align="right">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-success" @click.prevent="Save()">Simpan</button>
    </div>
</div>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    export default {
        components: {
          DatePicker,
        },
        name:'catatankhusus',
        props: ['SiswaId','DateKegiatan'],
        async mounted() {
            // console.log('Component mounted.'+this.SiswaId)
            this.$Progress.start();
            await this.loadData()
            this.user = JSON.parse(localStorage.getItem('user'))
            this.$Progress.finish();
        },
        data() {
            return {
              user : '',
              formdata : {},
              form: new Form({
                    id : '',
                    id_siswa : '',
                    id_guru : '',
                    catatan_khusus: '',
                    tgl_catatan_khusus:'',
                    tidur: '',
                    bangun_tidur: '',
                    bab: '',
                    type:'catatankhusus',
              }),
            };
        },
        methods: {
          loadData(){
            axios.get("api/kegiatan-siswa/"+this.SiswaId,{params:{type:'catatankhusus',date:this.DateKegiatan}}).then(( data ) => {
              this.formdata = data.data.data[0]

              if(data.data.data.length > 0){
                  this.form.id = this.formdata.id;
                  this.form.id_guru = this.user.id;
                  this.form.catatan_khusus = this.formdata.catatan_khusus;
                  this.form.tidur = this.formdata.tidur;
                  this.form.bangun_tidur = this.formdata.bangun_tidur;
                  this.form.bab = this.formdata.bab;
              }

              this.form.id_siswa = this.SiswaId
              this.form.tgl_catatan_khusus = this.DateKegiatan
            })
            .catch((data)=>{
                  setTimeout(() => {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                  }, 300);
              });
          },
          Save(){
            this.$Progress.start();
            $('#pageloader').css('display','block');
            this.isActiveLoading = true;
            let id = this.form.id
            if(id){
              id=id;
            }else{
              id=0;
            }
            this.form.id_guru = this.user.id
            this.form.put('api/kegiatan-siswa/'+id)
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        $('#pageloader').css('display','none');
                        this.$Progress.finish();
                        this.form.id = data.data.data.id;
                        // this.$router.push('/siswa')
                  }, 300);
                } else {
                  setTimeout(() => {
                        this.isActiveLoading = false;
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');
                        this.$Progress.failed();
                        return false;
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {
                      this.isActiveLoading = false;
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');
                      return false;
                  }, 300);
              })
          }
        }
    }
</script>
