<template>
  <section class="content">
    <div class="container-fluid">

        <div class="row">
          <div class="col-12">

                  <!-- Main content -->
                  <div class="invoice p-3 mb-3">
                    <!-- title row -->
                    <div class="row">
                      <div class="col-12">
                        <h4>
                          <i class="fas fa-globe"></i> Trikaya Educare.
                          <small class="float-right">Date: <date-picker v-model="forminvoice.tgl_invoice" valueType="format" input-class="form-control" style="width: 100%;"></date-picker></small>
                        </h4>
                      </div>
                      <!-- /.col -->
                    </div>
                    <!-- info row -->
                    <div class="row invoice-info">
                      <div class="col-sm-4 invoice-col">
                        From
                        <address>
                          <strong>Trikaya Educare.</strong><br>
                          Jl. Prof. Dr. Ida Bagus Mantra No.909x<br>
                          Batubulan, Kec. Sukawati, Kabupaten Gianyar, Bali<br>
                          Phone: 0813-3945-4647<br>
                          Email: info@trikaya.id
                        </address>
                      </div>
                      <!-- /.col -->
                      <div class="col-sm-4 invoice-col">
                        To
                        <address>
                          <Select2 v-model="forminvoice.id_siswa" :options="listsiswa" @change="myChangeEvent($event)" @select="mySelectEvent($event)" placeholder="Nama Siswa"/></Select2><br>
                          Kedatangan Awal<br>
                          <date-picker v-model="forminvoice.kedatangan_awal" valueType="format" input-class="form-control" style="width: 100%;"></date-picker><br>
                          Kedatangan Akhir<br>
                          <date-picker v-model="forminvoice.kedatangan_akhir" valueType="format" input-class="form-control" style="width: 100%;"></date-picker><br>
                          
                        </address>
                      </div>
                      <!-- /.col -->
                      <div class="col-sm-4 invoice-col">
                        <b>Invoice #(Otomatis)</b><br>
                        <b>Order ID:</b> (Otomatis)<br><br><br><br>
                        <b>Payment Due:</b> {{forminvoice.kedatangan_awal}}<br><br><br>
                        <b>Account:</b> {{forminvoice.no_invoice}}
                      </div>
                      <!-- /.col -->
                    </div>
                    <!-- /.row -->

                    <!-- Table row -->
                    <div class="row">
                      <div class="col-12 table-responsive">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Qty</th>
                            <th>Program</th>
                            <th>Product</th>
                            <th>Subtotal</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item, index) in items" :key="index">
                            <td width="5%"><button class="btn btn-danger" @click.prevent="deleteRow(index,item)"><span class="fa fa-trash"></span> </button></td>
                            <td width="15%">
                                <input v-model="forminvoice.qty[index]" type="text" name="qty"
                                              class="form-control" :class="{ 'is-invalid': forminvoice.errors.has('qty') }" placeholder="Contoh : 1" @change="hitungjumlah(index)">
                            </td>
                            <td width="30%"><Select2 v-model="forminvoice.id_program[index]" :options="listprogram" @change="myChangeEventProgram($event,index)" @select="mySelectEventProgram($event,index)" placeholder="Nama Program"/></Select2></td>
                            <td width="30%"><Select2 v-model="forminvoice.id_produk[index]" :options="listproduk" @change="myChangeEventProduk($event,index)" @select="mySelectEventProduk($event,index)" placeholder="Nama Product"/></Select2></td>
                            <td align="right" width="20%">
                                <input v-model="forminvoice.harga_produk[index]" type="hidden">
                                <input v-model="forminvoice.harga_program[index]" type="hidden">
                                <input v-model="forminvoice.jumlah[index]" type="text" name="jumlah"
                                              class="form-control" :class="{ 'is-invalid': forminvoice.errors.has('jumlah') }" placeholder="IDR. 0" readonly="readonly">
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-12 table-responsive">
                        <button class="btn btn-primary" @click.prevent="addRow">Tambah Item</button>
                      </div>
                      <!-- /.col -->
                    </div>
                    <!-- /.row -->

                    <div class="row">
                      <!-- accepted payments column -->
                      <div class="col-6">
                        <p class="lead">Ketentuan Pembayaran:</p>
                        <!-- <p class="lead">Payment Methods:</p> -->
                        <!-- <img src="/img/credit/visa.png" alt="Visa">
                        <img src="/img/credit/mastercard.png" alt="Mastercard">
                        <img src="/img/credit/american-express.png" alt="American Express">
                        <img src="/img/credit/paypal2.png" alt="Paypal"> -->

                        <p class="text-muted well well-sm shadow-none" style="margin-top: 10px;">
                          anda dapat melakukan pembayaran melalui bank transfer,
                          lakukan pembayaran sebelum tanggal jatuh tempo.
                        </p>
                      </div>
                      <!-- /.col -->
                      <div class="col-6">
                        <p class="lead">Amount Due {{forminvoice.kedatangan_awal}}</p>

                        <div class="table-responsive">
                          <table class="table">
                            <tr>
                              <th style="width:50%">Subtotal:</th>
                              <td align="right"><input v-model="forminvoice.subtotal" type="text" name="subtotal"
                                              class="form-control" :class="{ 'is-invalid': forminvoice.errors.has('subtotal') }" placeholder="IDR. 0" readonly="readonly"></td>
                            </tr>
                            <tr>
                              <th>Potongan</th>
                              <td align="right"><input v-model="forminvoice.potongan_invoice" type="text" name="potongan_invoice"
                                              class="form-control" :class="{ 'is-invalid': forminvoice.errors.has('potongan_invoice') }" placeholder="IDR. 0" @change="hitunggrandtotal"></td>
                            </tr>
                            <tr>
                              <th>Total:</th>
                              <td align="right">
                              <input v-model="forminvoice.grand_total" type="text" name="grand_total"
                                              class="form-control" :class="{ 'is-invalid': forminvoice.errors.has('grand_total') }" placeholder="IDR. 0" readonly="readonly"></td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <!-- /.col -->
                    </div>
                    <!-- /.row -->

                    <!-- this row will not appear when printing -->
                    <div class="row no-print">
                      <div class="col-12">
                        <!-- <a href="invoice-print.html" rel="noopener" target="_blank" class="btn btn-default"><i class="fas fa-print"></i> Print</a> -->
                        <button type="button" class="btn btn-warning float-left" @click.prevent="cancelClicked"><i class="fa fa-fast-backward"></i>
                          Cancel
                        </button>
                        <button type="button" class="btn btn-success float-right" @click.prevent="saveInvoice"><i class="far fa-credit-card" @click.prevent="saveInvoice()"></i> Save
                          Payment
                        </button>
                        <button v-if="editmode" type="button" class="btn btn-primary float-right" style="margin-right: 5px;" @click.prevent="InvoiceModal">
                          <i class="fas fa-download"></i> Generate PDF
                        </button>
                      </div>
                    </div>
                  </div>
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="InvoiceModal" role="dialog" aria-labelledby="InvoiceModal" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Invoice Siswa</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                    <div class="modal-body" >
                        <!-- Main content -->
                        <div class="invoice p-3 mb-3">
                          <!-- title row -->
                          <div class="row">
                            <div class="col-12">
                              <h4>
                                <i class="fas fa-globe"></i> Trikaya Educare.
                                <small class="float-right">Date: {{forminvoice.tgl_invoice}}</small>
                              </h4>
                            </div>
                            <!-- /.col -->
                          </div>
                          <!-- info row -->
                          <div class="row invoice-info">
                            <div class="col-sm-4 invoice-col">
                              From
                              <address>
                                <strong>Trikaya Educare.</strong><br>
                                Jl. Prof. Dr. Ida Bagus Mantra No.909x<br>
                                Batubulan, Kec. Sukawati, Kabupaten Gianyar, Bali<br>
                                Phone: 0813-3945-4647<br>
                                Email: info@trikaya.id
                              </address>
                            </div>
                            <!-- /.col -->
                            <div class="col-sm-4 invoice-col">
                              To
                              <address>
                                <strong>{{forminvoice.nama_lengkap}}</strong><br>
                                Kedatangan Awal<br>
                                {{forminvoice.kedatangan_awal}}<br>
                                Kedatangan Akhir<br>
                                {{forminvoice.kedatangan_akhir}}
                              </address>
                            </div>
                            <!-- /.col -->
                            <div class="col-sm-4 invoice-col">
                              <b>Invoice #{{forminvoice.no_invoice}}</b><br>
                              <br>
                              <b>Order ID:</b> {{forminvoice.no_pendaftaran}}{{forminvoice.no_invoice}}<br>
                              <b>Payment Due:</b> {{forminvoice.kedatangan_awal}}<br>
                              <b>Account:</b> {{forminvoice.no_invoice}}
                            </div>
                            <!-- /.col -->
                          </div>
                          <!-- /.row -->

                          <!-- Table row -->
                          <div class="row">
                            <div class="col-12 table-responsive">
                              <table class="table table-striped">
                                <thead>
                                <tr>
                                  <th>Qty</th>
                                  <th>Product</th>
                                  <th>Program</th>
                                  <th>Subtotal</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in items" :key="index">
                                  <td>{{forminvoice.qty[index]}}</td>
                                  <td>{{forminvoice.nama_produk[index]}}</td>
                                  <td>{{forminvoice.nama_program[index]}}</td>
                                  <td align="right">IDR. {{forminvoice.jumlah[index]}}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                            <!-- /.col -->
                          </div>
                          <!-- /.row -->

                          <div class="row">
                            <!-- accepted payments column -->
                            <div class="col-6">
                              <p class="lead">Ketentuan Pembayaran:</p>
                              <!-- <p class="lead">Payment Methods:</p> -->
                              <!-- <img src="/img/credit/visa.png" alt="Visa">
                              <img src="/img/credit/mastercard.png" alt="Mastercard">
                              <img src="/img/credit/american-express.png" alt="American Express">
                              <img src="/img/credit/paypal2.png" alt="Paypal"> -->

                              <p class="text-muted well well-sm shadow-none" style="margin-top: 10px;">
                                anda dapat melakukan pembayaran melalui bank transfer,
                                lakukan pembayaran sebelum tanggal jatuh tempo.
                              </p>
                            </div>
                            <!-- /.col -->
                            <div class="col-6">
                              <p class="lead">Amount Due {{forminvoice.kedatangan_awal}}</p>

                              <div class="table-responsive">
                                <table class="table">
                                  <tr>
                                    <th style="width:50%">Subtotal:</th>
                                    <td align="right">IDR. {{forminvoice.subtotal}}</td>
                                  </tr>
                                  <tr>
                                    <th>Potongan</th>
                                    <td align="right">IDR. {{forminvoice.potongan_invoice}}</td>
                                  </tr>
                                  <tr>
                                    <th>Total:</th>
                                    <td align="right">IDR. {{forminvoice.grand_total}}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <!-- /.col -->
                          </div>
                          <!-- /.row -->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-secondary btn-danger" @click="generateReport" >Print PDF</button>
                    </div>
                </div>
            </div>
        </div>
        <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="'Export Invoice '+forminvoice.nama_lengkap"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            
            ref="html2Pdf"
        >
        <section slot="pdf-content" style="padding:0px !important; margin: 50px 0px 0px 0px !important; background:#fff!important">
          <div class="invoice p-3 mb-3">
              <!-- title row -->
              <div class="row">
                <div class="col-12">
                  <h4>
                    <i class="fas fa-globe"></i> Trikaya Educare.
                    <small class="float-right">Date: {{forminvoice.tgl_invoice}}</small>
                  </h4>
                </div>
                <!-- /.col -->
              </div>
              <!-- info row -->
              <div class="row invoice-info">
                <div class="col-sm-4 invoice-col">
                  From
                  <address>
                    <strong>Trikaya Educare.</strong><br>
                    Jl. Prof. Dr. Ida Bagus Mantra No.909x<br>
                    Batubulan, Kec. Sukawati, Kabupaten Gianyar, Bali<br>
                    Phone: 0813-3945-4647<br>
                    Email: info@trikaya.id
                  </address>
                </div>
                <!-- /.col -->
                <div class="col-sm-4 invoice-col">
                  To
                  <address>
                    <strong>{{forminvoice.nama_lengkap}}</strong><br>
                    Kedatangan Awal<br>
                    {{forminvoice.kedatangan_awal}}<br>
                    Kedatangan Akhir<br>
                    {{forminvoice.kedatangan_akhir}}
                  </address>
                </div>
                <!-- /.col -->
                <div class="col-sm-4 invoice-col">
                  <b>Invoice #{{forminvoice.no_invoice}}</b><br>
                  <br>
                  <b>Order ID:</b> {{forminvoice.no_pendaftaran}}{{forminvoice.no_invoice}}<br>
                  <b>Payment Due:</b> {{forminvoice.kedatangan_awal}}<br>
                  <b>Account:</b> {{forminvoice.no_invoice}}
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->

              <!-- Table row -->
              <div class="row">
                <div class="col-12 table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Qty</th>
                      <th>Product</th>
                      <th>Program</th>
                      <th>Subtotal</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{forminvoice.qty[index]}}</td>
                      <td>{{forminvoice.nama_produk[index]}}</td>
                      <td>{{forminvoice.nama_program[index]}}</td>
                      <td align="right">IDR. {{forminvoice.jumlah[index]}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->

              <div class="row">
                <!-- accepted payments column -->
                <div class="col-6">
                  <p class="lead">Ketentuan Pembayaran:</p>
                  <!-- <p class="lead">Payment Methods:</p> -->
                  <!-- <img src="/img/credit/visa.png" alt="Visa">
                  <img src="/img/credit/mastercard.png" alt="Mastercard">
                  <img src="/img/credit/american-express.png" alt="American Express">
                  <img src="/img/credit/paypal2.png" alt="Paypal"> -->

                  <p class="text-muted well well-sm shadow-none" style="margin-top: 10px;">
                    anda dapat melakukan pembayaran melalui bank transfer,
                    lakukan pembayaran sebelum tanggal jatuh tempo.
                  </p>
                </div>
                <!-- /.col -->
                <div class="col-6">
                  <p class="lead">Amount Due {{forminvoice.kedatangan_awal}}</p>

                  <div class="table-responsive">
                    <table class="table">
                      <tr>
                        <th style="width:50%">Subtotal:</th>
                        <td align="right">IDR. {{forminvoice.subtotal}}</td>
                      </tr>
                      <tr>
                        <th>Potongan</th>
                        <td align="right">IDR. {{forminvoice.potongan_invoice}}</td>
                      </tr>
                      <tr>
                        <th>Total:</th>
                        <td align="right">IDR. {{forminvoice.grand_total}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->


            </div>
      </section>
        </VueHtml2pdf>

    </div>
  </section>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import StepWizard from '../../../components/Wizard.vue';
    import 'vue2-datepicker/index.css';
    import { EventBus } from '../../../eventBus.js';
    import Select2 from '../../../components/Select2.vue';
    import VueHtml2pdf from 'vue-html2pdf'

    export default {
      name:'FormSiswa',
      components: {
          DatePicker,
          StepWizard,
          Select2,
          VueHtml2pdf
        },
        data () {
            return {
                isActiveLoading:false,
                listprogram: [],
                items: [0],
                listsiswa:[],
                listprogram:[],
                listproduk:[],
                liststatus:[
                          {text: 'Aktif', id: 1},
                          {text: 'Tidak Aktif', id: 0}
                        ],
                forminvoice : new Form ({
                  id:'',
                  no_pendaftaran:'',
                  no_invoice:'',
                  id_siswa:'',
                  tgl_invoice:'',
                  nama_lengkap:'',
                  subtotal:0,
                  grand_total:0,
                  potongan_invoice:0,
                  no_invoice:'',
                  kedatangan_awal:'',
                  kedatangan_akhir:'',
                  id_produk:[],
                  id_program:[],
                  harga_produk:[],
                  nama_produk:[],
                  harga_program:[],
                  nama_program:[],
                  qty:[],
                  jumlah:[],
                }),
                
            }
        },
        props: ['Id','editmode'],
        methods: {
          generateReport(){
              this.$refs.html2Pdf.generatePdf();
          },
          InvoiceModal(id){
              this.$Progress.start();              
              $('#pageloader').css('display','block');
              setTimeout(() => {       
                  $('#pageloader').css('display','none');
                  $('#InvoiceModal').modal('show');
              }, 300);
              
              this.$Progress.finish();
          },
          cancelClicked(currentPage) {
            // this.$router.push('/siswa')
            this.$emit('cancelForm')
          },
          loadProgram(){
            axios.get("api/list/program").then(({ data }) => (this.listprogram = data.data));
          },
          loadProduk(){
            axios.get("api/list/produk").then(({ data }) => (this.listproduk = data.data));
          },
          loadSiswa(){
            axios.get("api/list/siswa").then(({ data }) => (this.listsiswa = data.data));
          },
          loadData(){
            if(this.editmode){
              axios.get("api/invoice/"+this.Id).then(({ data }) => {
                // this.formData = data.data
                this.forminvoice.reset();
                if(data.data){
                  this.forminvoice.id = data.data.id
                  this.forminvoice.no_pendaftaran = data.data.no_pendaftaran
                  this.forminvoice.no_invoice = data.data.no_invoice
                  this.forminvoice.id_siswa = data.data.id_siswa
                  this.forminvoice.nama_lengkap = data.data.nama_lengkap
                  this.forminvoice.kedatangan_awal = data.data.kedatangan_awal
                  this.forminvoice.kedatangan_akhir = data.data.kedatangan_akhir
                  this.forminvoice.tgl_invoice = data.data.tgl_invoice
                  this.forminvoice.grand_total = this.formatPrice(parseInt(data.data.grand_total))
                  this.forminvoice.potongan_invoice = this.formatPrice(parseInt(data.data.potongan_invoice))
                  axios.get("api/invoice/detail/"+data.data.id).then(( detail ) => {
                    this.items = detail.data.data;
                    if(this.items.length < 1){
                        this.items.push([]);
                    }else{
                        for(let index in this.items){
                          // console.log(detail.data.data[index].jumlah);
                          this.forminvoice.qty[index] = parseInt(this.items[index].qty)
                          this.forminvoice.id_program[index] = this.items[index].id_program
                          this.forminvoice.harga_program[index] = parseInt(this.items[index].harga_program)
                          this.forminvoice.nama_program[index] = this.items[index].nama_program
                          this.forminvoice.id_produk[index] = this.items[index].id_produk
                          this.forminvoice.harga_produk[index] = parseInt(this.items[index].harga_produk)
                          this.forminvoice.nama_produk[index] = this.items[index].nama_produk
                          this.forminvoice.jumlah[index] = this.formatPrice(parseInt(this.items[index].jumlah))
                       }
                  //     console.log(this.forminvoice);
                    }
                      this.hitungtotal();
                  });
                }
              });
            }else{
              
            }
          },
          saveInvoice(){
            if(this.editmode){
              this.updateData()
            }else{
              this.createData()
            }
          },
          createData(){
              this.$Progress.start();              
              this.forminvoice.post('api/invoice')
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {
                        this.forminvoice.id = data.data.data.id                        
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        this.cancelClicked();
                        this.$Progress.finish();
                  }, 300);

                } else {
                  setTimeout(() => {                        
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        this.$Progress.failed();
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {                      
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                  }, 300);
              })
          },
          updateData(){
              this.$Progress.start();              
              this.forminvoice.put('api/invoice/'+this.forminvoice.id)
              .then((response) => {
                  // success
                  setTimeout(() => {  
                      this.forminvoice.id = response.data.data.id                    
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.cancelClicked();
                      this.$Progress.finish();
                  }, 300);
                      //  Fire.$emit('AfterCreate');
              })
              .catch((data) => {
                  setTimeout(() => {                      
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      this.$Progress.fail();
                  }, 300);
              });

          },
          formatPrice(value) {
              let val = (value/1).toFixed(0).replace('.', ',')
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          },
          myChangeEvent(event){

          },
          myChangeEventProduk(event,index){
            

          },
          myChangeEventProgram(event,index){
            

          },
          mySelectEvent(event){

          },
          mySelectEventProduk(event,index){
            //console.log(event);
            this.forminvoice.qty[index] = parseInt(this.forminvoice.qty[index]) || 1
            this.forminvoice.harga_produk[index] = parseInt(event.harga_jual)
            this.forminvoice.harga_program[index] = parseInt(this.forminvoice.harga_program[index]) || 0
            this.formatPrice(this.hitungjumlah(index))
          },
          mySelectEventProgram(event,index){
            // console.log(event);
            this.forminvoice.qty[index] = parseInt(this.forminvoice.qty[index]) || 1
            this.forminvoice.harga_produk[index] = parseInt(this.forminvoice.harga_produk[index]) || 0
            this.forminvoice.harga_program[index] = parseInt(event.biaya) - parseInt(event.potongan)
            this.formatPrice(this.hitungjumlah(index))
          },
          hitungjumlah(index){
            this.forminvoice.harga_program[index] = parseInt(this.forminvoice.harga_program[index]) || 0
            this.forminvoice.harga_produk[index] = parseInt(this.forminvoice.harga_produk[index]) || 0
            let jumlah = parseInt(this.forminvoice.qty[index]) * (parseInt(this.forminvoice.harga_program[index]) + parseInt(this.forminvoice.harga_produk[index]));
            this.forminvoice.jumlah[index] = this.formatPrice(parseInt(jumlah))
            this.hitungtotal()
          },
          hitungtotal(){
            // console.log(this.forminvoice.jumlah);
            this.forminvoice.subtotal = 0
            let total = 0;
            for(var index in this.forminvoice.jumlah){
              // console.log(index);
              total = parseInt(total) + parseInt(this.forminvoice.jumlah[index].replace(/\./g, ""))
            }
            this.forminvoice.subtotal = this.formatPrice(parseInt(total));
            this.hitunggrandtotal()
          },
          hitunggrandtotal(){
            let grandtotal;
            // console.log(this.forminvoice.potongan_invoice);
            let potongan = 0;
            if(this.forminvoice.potongan_invoice > 0){
              potongan = parseInt(this.forminvoice.potongan_invoice.replace(/\./g, "") ) || 0;
            }
            grandtotal = parseInt(this.forminvoice.subtotal.replace(/\./g, "")) - parseInt(potongan);
            if(parseInt(grandtotal) > 0){
              this.forminvoice.grand_total = this.formatPrice(parseInt(grandtotal));
            }
          },
          addRow() {
            this.items.push([]);
          },
          deleteRow(index,val) {
            if(this.items.length > 1){
              this.items.splice(index,1);
              this.forminvoice.jumlah.splice(index,1);
              this.forminvoice.id_produk.splice(index,1);
              this.forminvoice.id_program.splice(index,1);
              this.forminvoice.qty.splice(index,1);
            }else{
              this.deleteFailed(index,val);
            }
            this.hitungtotal()
          },
          deleteFailed(index,val) {
            Toast.fire({
                icon: 'error',
                title: 'Gagal Hapus, Minimal 1 Kegiatan'
            });
          },

        },
        beforeMount() {
          // if(this.$gate.isAdmin()){
            this.isActiveLoading = true;
          // }else{
            // this.$router.push({ path: '/dashboard' });
          // }
        },
        mounted() {
            // console.log('coba');
            setTimeout(() => {
                    this.isActiveLoading = false;
              }, 500);
        },
        async created() {
            this.$Progress.start();
            this.isActiveLoading = true;
            await this.loadData()
            await this.loadProduk()
            await this.loadProgram()
            await this.loadSiswa()
            this.$Progress.finish();
        },
        watch: {

        },
        computed: {

        }
    }
</script>
