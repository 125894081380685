<template>
  <section class="content">
    <div class="container-fluid">  
            <FormInvoice v-if="formmode" :editmode="editmode" :Id="Id" @cancelForm="cancelForm"></FormInvoice>
            <div class="card" v-if="!formmode">
            <div class="overlay" id="cardloader"><div class="load-bar"><div class="bar"></div><div class="bar"></div><div class="bar"></div></div></div>
              <div class="card-header">
                <h3 class="card-title">List Data {{title}}</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>

                  <!-- <vue-excel-xlsx
                      :data="exportdata"
                      :columns="exportcolumn"
                      :file-name="'Export-Master-Data-Kegiatan'"
                      :file-type="'xlsx'"
                      :sheet-name="'Sheet1'"
                      class="btn btn-sm btn-warning"
                      >
                      <i class="fa fa-file-excel"></i> Download
                  </vue-excel-xlsx> -->
                </div>
              </div>
              <!-- /.card-header -->
              <!-- <div class="card-body table-responsive p-0"> -->
              <div class="card-body">
                <data-table :columns="columns" class="display table table-bordered table-hover" :ajax="ajax" ></data-table>
              </div>
              <!-- /.card-body -->
            </div>
      </div>
            <!-- /.card -->
            

  </section>
</template>

<script>
    import DataTable from '../../../components/DataTable.vue';
    import Delete from '../../../components/DeleteButton.vue';
    import Edit from '../../../components/EditButton.vue';
    import Invoice from '../../../components/InvoiceButton.vue';
    import { EventBus } from '../../../eventBus.js';
    import Select2 from '../../../components/Select2.vue';
    import FormInvoice from './invoice.vue';

    export default {
      components: {
          DataTable,
          Select2,
          FormInvoice,
        },
        props:{
          title:'',
        },
        data () {
            return {
                formmode: false,
                editmode: false,
                Id: 0,
                user:'',
                loginType:'',
                formdata : {},
                form: new Form({
                    id : '',
                    nama_kegiatan : '',
                    ket_kegiatan: '',
                    status_kegiatan: '',
                }),              
                tag:  '',
                autocompleteItems: [],
                contents: 0,
                liststatus:[
                          {text: 'Aktif', id: 1},
                          {text: 'Tidak Aktif', id: 0}
                        ],
                columns: [
                    {title: 'No.',data: 'no', name: 'no',width:"2%"},
                    {title: 'Tgl',data: 'tgl_invoice', name:'a.tgl_invoice'},
                    {title: 'Nama Siswa',data: 'name', name:'b.nama_lengkap'},
                    {title: 'Kedatangan Awal',data: 'kedatangan_awal', name:'a.kedatangan_awal'},
                    {title: 'Kedatangan Akhir',data: 'kedatangan_akhir', name:'a.kedatangan_akhir'},
                    {title: 'Potongan',data: 'potongan_invoice', name:'a.potongan_invoice'},
                    {title: 'Total',data: 'grand_total', name:'a.grand_total'},
                    {
                        data: 'action',
                        orderable: false,
                        searchable: false,
                        createdCell(cell, cellData, rowData) {
                            let DeleteButton = Vue.extend(Delete);
                            let instance = new DeleteButton({
                                propsData: rowData
                            });
                            instance.$mount();

                            let EditButton = Vue.extend(Edit);
                            let instanceEdit = new EditButton({
                                propsData: rowData
                            });
                            instanceEdit.$mount();

                            // let InvoiceButton = Vue.extend(Invoice);
                            // let instanceInvoice = new InvoiceButton({
                            //     propsData: rowData
                            // });
                            // instanceInvoice.$mount();

                            // $(cell).empty().append(instanceInvoice.$el).append(" ").append(instanceEdit.$el).append(" ").append(instance.$el);
                            $(cell).empty().append(instanceEdit.$el).append(" ").append(instance.$el);
                        }
                    },

                ],
                ajax: {
                  url : '/api/invoice',
                  beforeSend: function (xhr){ 
                
                  },
                  error: data => {
                    // console.log(data);
                    if(data.status == 401){
                        this.$emit('logout')
                    }
                  },
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + localStorage.getItem('token')
                  },
                },
                exportcolumn:[
                          {label: '#',field: 'no', },
                          {label: 'Tgl',field: 'tgl_invoice', },
                          {label: 'Nama Siswa',field: 'name', },
                          {label: 'Kedatangan Awal',field: 'kedatangan_awal', },
                          {label: 'Kedatangan Akhir',field: 'kedatangan_akhir', },
                          {label: 'Potongan',field: 'potongan_invoice', },
                          {label: 'Total',field: 'grand_total', },
                        ],
                exportdata:[],
            }
        },
        methods: {
          cancelForm(){
            this.formmode = false
            // console.log('coba');
          },
          loadDataExport(){
            axios.get("/api/export/invoice").then(({ data }) => {
                this.exportdata = data.data
              });
          },
          newModal(){
              this.$Progress.start();  
              this.editmode = false;
              $('#pageloader').css('display','block');
              setTimeout(() => {       
                this.editmode = false;
                this.formmode = true;
                $('#pageloader').css('display','none');                 
              }, 300);
              this.$Progress.finish();
          },
          createData(){
              this.$Progress.start();              
              $('#pageloader').css('display','block');
              this.form.post('/api/invoice')
              .then((data)=>{
                if(data.data.success){
                  setTimeout(() => {                        
                        $('#addNew').modal('hide');
                        Toast.fire({
                              icon: 'success',
                              title: data.data.message
                          });
                        EventBus.$emit('dataTableReload', null) 
                        $('#pageloader').css('display','none');    
                        this.$Progress.finish();
                  }, 300);

                } else {
                  setTimeout(() => {                        
                        Toast.fire({
                            icon: 'error',
                            title: 'Some error occured! Please try again'
                        });
                        $('#pageloader').css('display','none');    
                        this.$Progress.failed();
                  }, 300);

                }
              })
              .catch((data)=>{
                  setTimeout(() => {                      
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');    
                  }, 300);
              })
          },
          updateData(){
              this.$Progress.start();             
              $('#pageloader').css('display','block'); 
              this.form.put('/api/invoice/'+this.form.id)
              .then((response) => {
                  // success
                  setTimeout(() => {
                  $('#addNew').modal('hide');                      
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      EventBus.$emit('dataTableReload', null) 
                      $('#pageloader').css('display','none');   
                      this.$Progress.finish();
                  }, 300);
                      //  Fire.$emit('AfterCreate');
              })
              .catch((data) => {
                  setTimeout(() => {                      
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                      $('#pageloader').css('display','none');   
                      this.$Progress.fail();
                  }, 300);
              });

          },
          deleteData(id,name){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this "+name+"!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                      // Send request to the server
                      this.$Progress.start();  
                      $('#pageloader').css('display','block');                     
                      if (result.value) {
                            this.form.delete('/api/invoice/'+id).then(()=>{
                                setTimeout(() => {                                    
                                    Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                    );
                                    EventBus.$emit('dataTableReload', null) 
                                    $('#pageloader').css('display','none');   
                                    this.$Progress.finish();
                                }, 300);
                                // Fire.$emit('AfterCreate');
                            }).catch((data)=> {
                                setTimeout(() => {                                    
                                    Swal.fire("Failed!", data.message, "warning");
                                    $('#pageloader').css('display','none');   
                                    if(data.response.status == 401){
                                        this.$emit('logout')
                                    }
                                    this.$Progress.fail();
                                }, 300);
                            });
                      }else{
                          setTimeout(() => {                                
                                $('#pageloader').css('display','none');   
                                this.$Progress.fail();
                            }, 300);
                      }
                  })
          },
          myChangeEvent(event){

          },
          mySelectEvent(event){

          },

        },
        beforeMount() {
          // if(this.$gate.isAdmin()){            
          // }else{
            // this.$router.push({ path: '/dashboard' });
          // }
        },
        mounted() {
            EventBus.$on('editValue', (id,name) => {
              // console.log('triggered '+ id + ' contents ' + name)
              this.editmode = true;
              this.formmode = true;
              this.Id = id;
            })
            EventBus.$on('deleteValue', (id,name) => {
              // console.log('triggered '+ id + ' contents ' + name)
              this.deleteData(id,name);
            })
            // EventBus.$on('InvoiceValue', (id,name) => {
            //   // console.log('triggered '+ id + ' contents ' + name)
            //   this.InvoiceModal(id);
            //   this.Id = id;
            // })
        },
        async created() {
            this.$Progress.start();
            this.$emit('setAxios')            
            this.user = JSON.parse(localStorage.getItem('user'))
            this.loginType = this.user.roles[0].name
            await this.loadDataExport();
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          
        },
        beforeDestroy(){
          EventBus.$off('editValue')
          EventBus.$off('deleteValue')
        }
    }
</script>
