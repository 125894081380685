<template>
  <section class="content">
    <div class="container-fluid">
        
        <FormSiswa v-if="formmode" :editmode="editmode" :siswaId="siswaId" @cancelForm="cancelForm"></FormSiswa>
        <div class="card" v-if="!formmode">
        <div class="overlay" id="cardloader"><div class="load-bar"><div class="bar"></div><div class="bar"></div><div class="bar"></div></div></div>
          <div class="card-header">
            <h3 class="card-title">Siswa List</h3>

            <div class="card-tools">
              
              <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                  <i class="fa fa-plus-square"></i>
                  Add New
              </button>
            </div>
          </div>
          <!-- /.card-header -->
          <!-- <div class="card-body table-responsive p-0"> -->
          <div class="card-body">
            <data-table :columns="columns" class="display table table-bordered table-hover" :ajax="ajax" ></data-table>

          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->

        <!-- Modal -->
        <div class="modal fade" id="QrCodeModal" role="dialog" aria-labelledby="QrCodeModal" aria-hidden="true">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">QRCode Siswa</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                    <div class="modal-body" align="center">
                        <h6>Student Name : {{this.siswaName}}</h6>
                        <small>Use The QrCode for report activity of Student</small>
                        <qrcode :value="this.siswaQrcode" :options="{ width: 280 }"></qrcode>
                        <br/><small><a :href="siswaQrcode" target="_blank">{{this.siswaQrcode}}</a></small>
                        <h6>Support by Trikaya Educare</h6>
                        <small>Scan the QrCode from parent access</small>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        

    </div>
  </section>
</template>

<script>
    import DataTable from '../../../components/DataTable.vue';
    import Delete from '../../../components/DeleteButton.vue';
    import Edit from '../../../components/EditButton.vue';
    import QR from '../../../components/QRButton.vue';
    import Invoice from '../../../components/InvoiceButton.vue';
    import FormSiswa from './form.vue';
    import { EventBus } from '../../../eventBus.js';
    import moment from 'moment';

    export default {
      components: {
          DataTable,
          FormSiswa
        },
        props:{
          title:'',
        },
        data () {
            return {
                formmode: false,
                editmode: false,
                user:'',
                loginType:'',
                siswaId: 0,
                siswaName: '',
                siswaQrcode:'0',
                isActiveLoading:false,
                DateInvoice:'',
                formdata : {},
                forminvoice : {
                  id:'',
                  nama_lengkap:'',
                  nama_ayah:'',
                  no_hp_ayah:'',
                  email_ayah:'',
                  alamat_ayah:'',
                  nama_bunda:'',
                  no_hp_bunda:'',
                  email_bunda:'',
                  alamat_bunda:'',
                  produk:'',
                  grand_total:'',
                  potongan_invoice:'',
                  harga:'',
                  no_invoice:'',
                  kedatangan_awal:'',
                  qty:'',
                  deskripsi:'',
                },
                form: new Form({
                    id : '',
                    nama_kegiatan : '',
                    ket_kegiatan: '',
                    status_kegiatan: '',
                }),              
                tag:  '',
                autocompleteItems: [],
                contents: 0,
                columns: [
                    {title: 'No.',data: 'no', name: 'no',width:"2%"},
                    {title: 'Foto',data: 'foto', name:'a.foto'},
                    {title: 'Nama',data: 'name', name:'a.nama_lengkap'},
                    {title: 'Umur',data: 'umur', name:'a.id'},
                    {title: 'Tgl Masuk',data: 'kedatangan_awal', name:'a.kedatangan_awal'},
                    {title: 'Tgl Selesai',data: 'kedatangan_akhir', name:'a.kedatangan_akhir'},
                    {
                        data: 'action',
                        orderable: false,
                        searchable: false,
                        createdCell(cell, cellData, rowData) {
                            let DeleteButton = Vue.extend(Delete);
                            let instance = new DeleteButton({
                                propsData: rowData
                            });
                            instance.$mount();

                            let EditButton = Vue.extend(Edit);
                            let instanceEdit = new EditButton({
                                propsData: rowData
                            });
                            instanceEdit.$mount();

                            let QRButton = Vue.extend(QR);
                            let instanceQR = new QRButton({
                                propsData: rowData
                            });
                            instanceQR.$mount();

                            // let InvoiceButton = Vue.extend(Invoice);
                            // let instanceInvoice = new InvoiceButton({
                            //     propsData: rowData
                            // });
                            // instanceInvoice.$mount();

                            // $(cell).empty().append(instanceQR.$el).append(" ").append(instanceInvoice.$el).append(" ").append(instanceEdit.$el).append(" ").append(instance.$el);
                            $(cell).empty().append(instanceQR.$el).append(" ").append(instanceEdit.$el).append(" ").append(instance.$el);
                        }
                    },

                ],
                ajax: {
                  url : 'api/getdata/siswa',
                  beforeSend: function (xhr){ 
                
                  },
                  error: data => {
                    // console.log(data);
                    if(data.status == 401){
                        this.$emit('logout')
                    }
                  },
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + localStorage.getItem('token')
                  },
                },
            }
        },
        methods: {
          cancelForm(){
            this.formmode = false
            console.log('coba');
          },
          QRModal(id){
              this.$Progress.start();              
              $('#pageloader').css('display','block');
              setTimeout(() => {       
                $('#pageloader').css('display','none');                 
              }, 300);
              $('#QrCodeModal').modal('show');
              this.$Progress.finish();

          },
          InvoiceModal(id){
              this.$Progress.start();              
              $('#pageloader').css('display','block');
              axios.get("api/invoice-siswa/"+id).then(({ data }) => {
                  this.forminvoice = data.data;
                  console.log(data.data);
                  setTimeout(() => {       
                    $('#pageloader').css('display','none');                 
                    $('#InvoiceModal').modal('show');
                  }, 300);
              })
              .catch((data)=>{
                  setTimeout(() => {
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                      if(data.response.status == 401){
                          this.$emit('logout')
                      }
                  }, 300);
                  $('#pageloader').css('display','none');                 
              });
              
              this.$Progress.finish();
          },
          newModal(){
              $('#pageloader').css('display','block');
              setTimeout(() => {       
                    this.editmode = false;
                    this.formmode = true;
                    $('#pageloader').css('display','none');                 
                  }, 300);
              // this.form.reset();
              // $('#addNew').modal('show');
              // this.$router.push('/siswa/list')
          },
          createData(){
            //   this.$Progress.start();              
            //   this.form.post('api/kegiatan')
            //   .then((data)=>{
            //     if(data.data.success){
            //       setTimeout(() => {                        
            //             $('#addNew').modal('hide');
            //             Toast.fire({
            //                   icon: 'success',
            //                   title: data.data.message
            //               });
            //             EventBus.$emit('dataTableReload', null) 
            //             this.$Progress.finish();
            //       }, 300);

            //     } else {
            //       setTimeout(() => {                        
            //             Toast.fire({
            //                 icon: 'error',
            //                 title: 'Some error occured! Please try again'
            //             });
            //             this.$Progress.failed();
            //       }, 300);

            //     }
            //   })
            //   .catch((data)=>{
            //       setTimeout(() => {                      
            //           Toast.fire({
            //               icon: 'error',
            //               title: 'Some error occured! Please try again'
            //           });
            //           if(data.response.status == 401){
            //               this.$emit('logout')
            //           }
            //       }, 300);
            //   })
          },
          updateData(){
            //   this.$Progress.start();              
            //   this.form.put('api/kegiatan/'+this.form.id)
            //   .then((response) => {
            //       // success
            //       setTimeout(() => {
            //       $('#addNew').modal('hide');                      
            //           Toast.fire({
            //             icon: 'success',
            //             title: response.data.message
            //           });
            //           EventBus.$emit('dataTableReload', null) 
            //           this.$Progress.finish();
            //       }, 300);
            //           //  Fire.$emit('AfterCreate');
            //   })
            //   .catch((data) => {
            //       setTimeout(() => {                      
            //           Toast.fire({
            //               icon: 'error',
            //               title: 'Some error occured! Please try again'
            //           });
            //           if(data.response.status == 401){
            //               this.$emit('logout')
            //           }
            //           this.$Progress.fail();
            //       }, 300);
            //   });

          },
          deleteData(id,name){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this "+name+"!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                      // Send request to the server
                      this.$Progress.start();                      
                      if (result.value) {
                            this.form.delete('api/siswa/'+id).then(()=>{
                                setTimeout(() => {                                    
                                    Swal.fire(
                                    'Deleted!',
                                    'Your file has been deleted.',
                                    'success'
                                    );
                                    EventBus.$emit('dataTableReload', null) 
                                    this.$Progress.finish();
                                }, 300);
                                // Fire.$emit('AfterCreate');
                            }).catch((data)=> {
                                setTimeout(() => {                                    
                                    Swal.fire("Failed!", data.message, "warning");
                                    this.$Progress.fail();
                                    if(data.response.status == 401){
                                        this.$emit('logout')
                                    }
                                }, 300);
                            });
                      }else{
                          setTimeout(() => {                                
                                this.$Progress.fail();
                            }, 300);
                      }
                  })
          },
          getDate: function() {
                const today = new Date();
                const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date +' '+ time;
                this.DateInvoice = moment(date).format("YYYY-MM-DD");
            }

        },
        beforeMount() {
          // if(this.$gate.isAdmin()){            
          // }else{
            // this.$router.push({ path: '/dashboard' });
          // }
        },
        mounted() {
            setTimeout(() => {                    
              }, 500);
        },
        created() {
            this.$Progress.start();            
            this.$emit('setAxios')
            this.user = JSON.parse(localStorage.getItem('user'))
            this.loginType = this.user.roles[0].name         
            EventBus.$on('deleteValue', (id,name) => {
              // console.log('triggered '+ id + ' contents ' + name)
              this.deleteData(id,name);
            })
            EventBus.$on('editValue', (id,name) => {
              // console.log('triggered '+ id + ' contents ' + name)
              // this.editModal(id);
              this.editmode = true;
              this.formmode = true;
              this.siswaId = id;
            })
            EventBus.$on('QRValue', (id,name,qrcode) => {
              // console.log('triggered '+ id + ' contents ' + name)
              this.QRModal(id);
              this.siswaId = id;
              this.siswaName = name;
              this.siswaQrcode = qrcode;
            })
            // EventBus.$on('InvoiceValue', (id,name) => {
            //   // console.log('triggered '+ id + ' contents ' + name)
            //   this.InvoiceModal(id);
            //   this.siswaId = id;
            //   this.siswaName = name;
            // })
            this.getDate();
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          
        },
    }
</script>